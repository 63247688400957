import React, { Component } from 'react';
import SiteHeader from './SiteHeader';
import { Link } from 'react-router-dom';
import { userService } from './../services';
import { toast } from 'react-toastify';
import StorageService from './../storage/StorageService';
import MUIDataTable from "mui-datatables";
import Loader from 'react-loader-spinner'

class TermsConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            rows: [],
            errors: {},
            options: {},
            userData: [],
            loading: true,
        }
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = "Terms & Conditions | SetlinkLive";
        
        this.setState({
            columns: [
                {
                    name: "sno",
                    label: "S No.",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
                {
                    name: "title",
                    label: "Title",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "description",
                    label: "Description",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "action",
                    label: "Action",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
            ]
        });
        this.loader();
        this.getTermsConditions();
    }

    /**
    * @loader - data loading process
    */
    loader = () => {
        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 4000);
    }

    // Get Terms & Conditions
    getTermsConditions = async () => {
        try {
            const data = await userService.httpApi('GET', 'admin/terms', null);
            this.drawRows(data);
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else if (error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
            }
        }
    }

    // Create table rows
    drawRows(data) {
        let rows = [];
        data.map((x, index) =>
            rows.push({
                sno: index + 1,
                id: x._id,
                title: x.title,
                description: x.description,
                action: <> <Link to={`${process.env.PUBLIC_URL}/edit-terms/${x._id}`} className="btn-sm btn btn-success m-1"><i className="fa fa-edit"></i></Link></>
            })
        )
        this.setState({ rows: rows });
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {

        const options = {
            rowsPerPage: 10,
            rowsPerPageOptions: [10,15,20],
            filter: true,
            filterType: 'dropdown',
            serverSide: false,
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: "none",
            textLabels: {
                body: {
                    noMatch: this.state.loading ?
                        <Loader
                            type="Oval"
                            color="#00BFFF"
                            height={30}
                            width={30}
                        />
                    :
                        'Sorry, there is no matching data to display',
                },
            }
        }
        return (
            <>
                <SiteHeader />
                <div className="main my-4 my-md-5 common-table container">
                    <div className="page_wrapper mui-dataTables">
                        <MUIDataTable
                            title={"Terms & Conditions"}
                            data={this.state.rows}
                            columns={this.state.columns}
                            options={options}
                            />  
                    </div>
                </div>
            </>
        );
    }
}

export default TermsConditions;


