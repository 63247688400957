import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SiteHeader from './SiteHeader';
import StorageService from './../storage/StorageService';
import { userService } from '../services';
import { toast } from 'react-toastify';
import MUIDataTable from "mui-datatables";
import Loader from 'react-loader-spinner'

class LoggedInUsers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [],
            rows: [],
            options: {},
            userData: [],
            teamName: '',
            loading: true
        }
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = "Logged In Users | SetlinkLive";
        this.setState({
            columns: [
                {
                    name: "sno",
                    label: "S No.",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
                {
                    name: "name",
                    label: "Name",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
                {
                    name: "email",
                    label: "Email",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "phone",
                    label: "Phone",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "userType",
                    label: "User Type",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
            ],
        });
        this.loader();
        this.getUserData();
    }

    /**
    * @loader - data loading process
    */
    loader = () => {
        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 4000);
    }

    // Get User's details
    getUserData = async () => {
        try {
            let rows = []
            const data = await userService.httpApi('GET', `loggedIn/${this.props.match.params.id}`, null);
            this.setState({
                teamName: data.teamName[0].teamName
            })
            data.data.map((x, index) =>
                rows.push({
                    sno: index + 1,
                    _id: x._id,
                    name: <span style={{ color: 'green' }}><b>{x.firstName + ' ' + x.lastName}</b></span>,
                    email: x.email,
                    phone: x.phone,
                    userType: x.userType,
                })
            )
            this.setState({ rows: rows });
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                StorageService.clearLogin();
            } else if (error === 'Login session expired') {
                toast.warn(error);
                StorageService.clearLogin();
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
            } else {
                toast.warn(error);
            }
        }
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        const options = {
            rowsPerPage: 10,
            rowsPerPageOptions: [10,15,20],
            filter: true,
            filterType: 'dropdown',
            serverSide: false,
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: "none",
            textLabels: {
                body: {
                    noMatch: this.state.loading ?
                        <Loader
                            type="Oval"
                            color="#00BFFF"
                            height={30}
                            width={30}
                        />
                    :
                        'Sorry, there is no matching data to display',
                },
            }
        }
        return (
            <>
                <SiteHeader />
                <div className="main container my-4 my-md-5 common-table">
                    <div className="page_wrapper mui-dataTables">
                        <Row className="justify-content">
                            <Col xs lg="12 mb-4"><Link to={`${process.env.PUBLIC_URL}/teams`} className={'back_btn'}><i className="fa fa-long-arrow-left"></i> Back</Link></Col>
                        </Row>
                        <MUIDataTable
                            title={this.state.teamName+" : Logged In Users"}
                            data={this.state.rows}
                            columns={this.state.columns}
                            options={options}
                            />
                    </div>
                </div>
            </>
        );
    }
}

export default LoggedInUsers;