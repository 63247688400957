import React, { Component } from 'react';
import { Modal, Col, Row, Form, Button } from 'reactstrap';
import { userService } from './../services';
import { toast } from 'react-toastify';
import StorageService from './../storage/StorageService';
import icon from './../assets/images/send-button.svg';
import Loader from 'react-loader-spinner'
import Picker from 'emoji-picker-react';
import axios from 'axios';
import apiUrl from './../constants';
import openSocket from 'socket.io-client';
import PrivateChat from './PrivateChat';
const query = StorageService.getLogin() ? `name=${StorageService.getLogin().profile.firstName}&userId=${StorageService.getLogin().profile.userId}` : '';
const socket = openSocket('https://api.setlink.io', {
    query: query,
    transports: ['polling'],
    upgrade: false
});

class Chat extends Component {
    constructor(props) {
        super(props);
        this.focusRef = React.createRef();
        this.myRef = React.createRef();
        this.newLine = React.createRef();
        this.handleFile = this.handleFile.bind(this);
        this.videoRefs = [];
        this.zIndex = [];
        this.state = {
            fields: {
                message: ''
            },
            color: '',
            rows: [],
            errors: {},
            userData: [],
            loading: true,
            close: true,
            output: '',
            connection: false,
            chosenEmoji: false,
            newMessage: false,
            selectedMembersValue: [],
            showModal: false,
            userList: [],
            selectedUser: '',
            selectedFile: null,
            showFile: null,
            modal: false,
            modalData: null,
            pinPopUp: false,
            optPopUp: null,
            socketData: [],
            scrollData: null,
            pinData: [],
            userId: [],
            num: 50,
            right: '',
            teamId: '',
            dropBox: false,
            offset: 10,
            limit: 10,
            totalRecords: 0,
            scrollChat: 0,
            showScrollButton: false,
            checkNewMessage: false,
            sentMessage: false,
            messageCount: 0,
            hitPin: false,
            newMessageId: null,
            firstScroll: false
        }
    }

    /**
    *@componentDidMount
    *This method is the least used lifecycle method 
    * and called before any HTML element is rendered
    */
    componentDidMount() {

        socket.emit('join', this.props.streamId);

        socket.on('connect', this.connectionUpdateC);
        
        socket.on('disconnect', this.connectionUpdateD);

        socket.on("new", this.updateChat);

        socket.on("new_private_message", (data) => {
            if(data) {
                this.newPrivateChat(data);
                if(this.state.close === true) {
                    this.setState({
                        close: false
                    },() => {
                        setTimeout(() => {
                            this.scrollToBottom();
                        }, 500);
                    })
                } 
            }
        });

        // socket.on("offline", (data) => {
        //     if(this.props.streamId === data.streamId && StorageService.getLogin().profile.userId !== data.userId)
        //         toast.error(data.username+' Offline');
        // });

        socket.on("online", (data) => {
            if(this.state.connection === false) {
                this.setState({
                    output: ''
                })
                this.getMessage();
            }
            
            this.setState({
                connection: true
            });
            // if(this.props.streamId === data.streamId && StorageService.getLogin().profile.userId !== data.userId)
            //     toast.success(data.username+' Online');
        });
        
        this.getTopPins();
        if(this.props.tokenId !== ''){
            this.getTokenDetails();
        } else {
            this.setState({
                teamId: this.props.teamId
            },()=>(this.getTeam()))
        }
    }

    /**
    *@getTokenDetails - get teamId by tokenId.
    */
    getTokenDetails = async () => {
        try {
            await userService.httpApi('GET', `token/details/${this.props.tokenId}`, null)
            .then(res => {
                this.setState({ 
                    teamId: res[0].teamId
                },()=>(this.getTeam()));
            });
        } catch (error) {
        toast.warn(error.message);
        }
    }
    
    /**
    *@updateChat - using for update chat messages.
    */
    updateChat = data => {
        if(data.userId === StorageService.getLogin().profile.userId) {
            this.setState({
                color: data.color
            })
        }

        this.connectionUpdateC();

        if(this.state.close === true) {
            this.setState({
                newMessage: true
            })
        } 
       
        this.setState(prevState => ({
            rows: [...(prevState.rows || []), data]
        }),() => {
            setTimeout(() => {
                if(this.state.scrollChat === 0 || this.state.sentMessage === true) {
                    this.scrollToBottom();
                    this.setState({
                        sentMessage: false,
                        newMessageId: null
                    });
                } else {
                    this.setState({
                        checkNewMessage: true,
                        newMessageId: this.state.newMessageId === null ? data._id : this.state.newMessageId,
                        messageCount: this.state.messageCount + 1,
                        firstScroll: true
                    })
                }
            }, 1000);
        });
    }

    /**
    *@connectionUpdateC - using for connection open.
    */
    connectionUpdateC = () => {
        if(this.state.connection === false) {
            this.setState({
                output: ''
            })
            this.getMessage();
        }
        
        this.setState({
            connection: true
        });
    }

    /**
    *@connectionUpdateD - using for connection close.
    */
    connectionUpdateD = () => {
        toast.error(`Server disconnected`);

        this.setState({
            connection: false
        });
    }


    /**
    *@scrollToBottom - using for chat scrolling.
    */
    scrollToBottom = () => {
        if(this.state.newMessageId !== null && this.state.firstScroll === true) {
            this.newLine.current.scrollIntoView({ behavior: "smooth", block: "center" });
            this.setState({
                firstScroll: false,
                checkNewMessage: false
            })
        } else {
            this.myRef.current.scrollIntoView({ behavior: "smooth" });
            this.setState({
                checkNewMessage: false,
                messageCount: 0,
                newMessageId: null
            })
        }
    }

    /**
    *@closePopup - using for chat close and open.
    */
    closePopup = () => {
        this.setState(prevState => ({
            close: !prevState.close
          }), function() {
            if(this.state.close === false) {
                this.focusRef.current.focus();
                this.setState({
                    right: '330px'
                })
                setTimeout(() => {
                    this.scrollToBottom();
                }, 2000);
            } else {
                this.setState({
                    newMessage: false,
                    right: '170px',
                    showModal: false
                })
            }
        });
        
    }

    /**
    *@getMessage - using for get chat messages.
    */
    // getMessage = async () => {
    //     try {
    //         const data = await userService.httpApi('GET', `chat/${this.props.streamId}`, null);
    //         let filtered = data.filter(t=>t.userId === StorageService.getLogin().profile.userId);
    //         if(filtered.length > 0) {
    //             this.setState({
    //                     color: filtered[0].color
    //             })
    //         }
    //         this.drawRows(data);
            
    //     } catch (error) {
    //         console.log('error',error);
    //     }
    // }
    
    /**
    *@drawRows - using for draw rows to chat messages.
    */
    drawRows(data) {
        let rows = [];
        data.map((x, index) =>
            rows.push({
                _id: x._id,
                userId: x.userId,
                firstName: x.firstName,
                lastName: x.lastName,
                message: x.message,
                color: x.color,
                chatType: x.chatType,
                file: x.file,
                created: x.created
            })
        )
        this.setState({ rows: rows });
        if(this.state.close === false && this.state.scrollChat === 0 && this.state.hitPin === false){
            setTimeout(() => {
                this.scrollToBottom();
            }, 2000);
        }
    }

    /**
    *@handleValidation - set validations for message input.
    */
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (fields["message"].trim().length === 0) {
            formIsValid = false;
            errors["message"] = "";
        }
        if (fields["message"].trim().length > 500) {
            formIsValid = false;
            errors["message"] = "Please enter maximum 500 characters";
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    /**
    *@addData - save chat message on database.
    */
    addData = async (e) => {
        e.preventDefault();
        this.setState({sentMessage: true});
        try {

            if (this.state.selectedFile !== null && this.state.fields['message'].length < 500) {
                const extn = this.state.selectedFile.name.split('.').pop().toLowerCase();
                if (extn === 'png' || extn === 'jpg' || extn === 'jpeg' || extn === 'gif') {
                    const room = this.props.streamId;
                    const randomColor = Math.floor(Math.random()*16777215).toString(16);
                    
                    var myStr = this.state.fields['message'];
                    var str = '';
                    const output = this.state.selectedMembersValue.map(item => {
                        if(str.length === 0){
                            str = myStr.replace(new RegExp('@'+item.name, 'g'),'@<b>'+item.name+' </b>');
                        } else {
                            str = str.replace(new RegExp('@'+item.name, 'g'),'@<b>'+item.name+' </b>');
                        }
                        return str;
                    });
    
                    const inputMessage = output[output.length - 1];
                    
                    const data = new FormData();
                    data.append('userId',StorageService.getLogin().profile.userId);
                    data.append('firstName',StorageService.getLogin().profile.firstName);
                    data.append('lastName',StorageService.getLogin().profile.lastName);
                    data.append('streamId', this.props.streamId);
                    data.append('message', inputMessage);
                    data.append('color', this.state.color ? this.state.color : '#'+randomColor);
                    data.append('chatType', 'Group');
                    data.append('file', this.state.selectedFile);
                    data.append('status', true);
                    
                    const headers = {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${StorageService.getLogin().token}`
                    }
                    axios.post(`${apiUrl}chat/file`, data, {
                        headers: headers
                    }).then(res => {
                        socket.emit("send", { 'message':res.data ,'room': room });
                        this.setState({
                            output: ''
                        })
                        
                    }).catch (error => {
                        toast.error(error);
                    });
                    this.setState({
                        fields :{
                            message:""
                        },
                        chosenEmoji : false,
                        showModal: false,
                        selectedFile: null,
                        showFile: null,
                        optPopUp: false
                    })
                    
                } else {
                    toast.warn('File type "'+extn+'" not valid!');
                }
            } else {
                if (this.handleValidation()) {

                    const randomColor = Math.floor(Math.random()*16777215).toString(16);
                    
                    const room = this.props.streamId;
    
                    var myStr2 = this.state.fields['message'];
                    var str2 = '';
                    const output = this.state.selectedMembersValue.map(item => {
                        if(str2.length === 0){
                            str2 = myStr2.replace(new RegExp('@'+item.name, 'g'),'@<b>'+item.name+' </b>');
                        } else {
                            str2 = str2.replace(new RegExp('@'+item.name, 'g'),'@<b>'+item.name+' </b>');
                        }
                        return str2;
                    });
                    
                    const inputMessage = output[output.length - 1];
                    
                    const rows = {
                                    'userId':StorageService.getLogin().profile.userId,
                                    'firstName':StorageService.getLogin().profile.firstName,
                                    'lastName':StorageService.getLogin().profile.lastName,
                                    'streamId': this.props.streamId,
                                    'message': inputMessage,
                                    'color': this.state.color ? this.state.color : '#'+randomColor,
                                    'chatType': 'Group',
                                    'file': '',
                                    'status': true
                                }
                    socket.emit("send", { 'message':rows ,'room': room });
    
                    this.setState({
                        fields :{
                            message:""
                        },
                        chosenEmoji : false,
                        showModal: false,
                        optPopUp: false
                    })
                    
                }
            }
        } catch (error) {
            toast.warn(error);
        }
    }

    /**
    *@getUser - get mention users list
    */
    getUser = (textMsg) => {
        //const msg = textMsg.replace(/ /g, '');
        const msg = textMsg;
        const singleletter = msg.slice(-1);
        if(msg === '@' || singleletter === '@') {
            this.setState({
                userList: this.state.selectedMembersValue,
                showModal: true
            })
            return;
        } else {
            this.setState({
                userList: '',
                showModal: false
            })
        }
        const string =  msg.includes('@') && msg.substr(msg.lastIndexOf('@') + 1).split(' ')[0];
        if(string) {
            if (string !== this.state.string) {
                this.setState({
                    string: string
                })

                const filteredData = this.state.selectedMembersValue.filter(item => {
                    return item.name.toString().toLowerCase().includes(string.toLowerCase())
                      
                });
                   
                if (filteredData.length !== 0) {
                    this.setState({
                        userList: filteredData
                    })
                    if(string === this.state.selectedUser){
                        this.setState({
                            showModal: false
                        })
                    } else {
                        this.setState({
                            showModal: true
                        })
                    }
                } else {
                    this.setState({
                        showModal: false
                    })
                }
            }else {
                this.setState({
                    showModal: false
                })
            }
        
       } else {
        this.setState({
            showModal: false
        })
       }
       
    }

    /**
    *@handleChange - bind data on event.
    */
    handleChange = (field, e) => {
        
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });

        this.getUser(e.target.value);
    }

    /**
    *@onEmojiClick - imoji picker.
    */
    onEmojiClick = (event, data) => {
        this.setState({
            fields: {
                message: this.state.fields['message'] ? this.state.fields['message'] + data.emoji : data.emoji
            }
        })
        this.focusRef.current.focus();
    }

    /**
    *@emojiPopUp - imoji popup hide show.
    */
    emojiPopUp = () => {
       
        this.setState(prevState => ({
            chosenEmoji: !prevState.chosenEmoji,
            optPopUp: false
        }));          
    }

    /**
    *@getTeam - Get team members.
    */
    getTeam = async () => {
        try {
            let selectedMembersArr = [];
            const data = await userService.httpApi('GET', `admin/teams/${this.state.teamId}`, null);
            
            data.teamMembers.map(x => (
                x.members.map(y => selectedMembersArr.push({
                    id: y._id,
                    name: y.firstName + ' ' + y.lastName,
                }))
            ));

            data.teamAdmins.map(x => (
                x.admins.map(y => selectedMembersArr.push({
                    id: y._id,
                    name: y.firstName + ' ' + y.lastName + ' : Admin',
                }))
            ));
            this.setState({
                selectedMembersValue: selectedMembersArr
            })
        } catch (error) {
            toast.warn(error.message);
        }
    }

    /**
    *@selectUser - using for selection of mention user
    */
    selectUser = (value, e) => {
        const string =  this.state.fields['message'].includes('@') && this.state.fields['message'].substr(this.state.fields['message'].lastIndexOf('@') + 1).split(' ')[0];
        
        if(string !== '') {
            const newValue = this.state.fields['message'].replace('@'+string,'@');
            this.setState({
                fields: {
                    message: newValue ? newValue + value+' ' : value+' '
                },
                selectedUser: value,
                showModal: false 
            });
           
        } else {
            const newValue = this.state.fields['message'];
            this.setState({
                fields: {
                    message: newValue ? newValue + value+' ' : value+' '
                },
                selectedUser: value,
                showModal: false 
            });
        }
        this.focusRef.current.focus();
    }

    /**
    *@handleFile - using on file selection
    */
    handleFile = e => {
        this.setState({
            selectedFile: e.target.files[0],
            showFile: URL.createObjectURL(e.target.files[0]),
            optPopUp: false
           });
    }

    /**
    *@closeImagePopup - using for image popup close
    */
    closeImagePopup = () => {
        this.setState({
            selectedFile: null,
            showFile: null
           });
    }

    /**
    *@modalOpen - using for image preview on modal
    */
    modalOpen = (data, e) => {
        this.setState({
            modal: true,
            modalData: data
           });
    }

    /**
    *@modalClose - using for modal close
    */
    modelClose() {
        this.setState({ 
            modal: false,
            modalData: null
        });
    }

    /**
    *@pinHandler - using for message pin on top
    */
    pinHandler = () => {
        this.setState(prevState => ({
            pinPopUp: !prevState.pinPopUp,
            optPopUp: null
        }));   
    }
    timer = () => setTimeout(() => {
        var nextState = this.state;
        nextState.pinPopUp = false;
        nextState.optPopUp = null;
        this.setState(nextState);
    }, 6000);
    /**
    *@optHandler - using for options on messages
    */
    optHandler = (_id,e) => {
        var nextState = this.state;
        nextState.pinPopUp = false;
        this.timer();
        const timerId = this.timer();
        if(_id === nextState.optPopUp) {
            clearTimeout(timerId);
            nextState.optPopUp = null;
            this.setState(nextState);
        } else {
            nextState.optPopUp = null;
            this.setState(nextState);
            nextState.optPopUp = _id;
            this.setState(nextState);
        }
    }

    /**
    *@topPinHandler - using for post pin message data
    */
    topPinHandler = async (_id,e) => {
        var state = this.state;
        if(state.pinData.length < 3) {
            const bodyData = {
                messageId: _id,
                userId: StorageService.getLogin().profile.userId,
                streamId: this.props.streamId,
                chatUserId: StorageService.getLogin().profile.userId
            }
            await userService.httpApi('POST', `chat/pin`,  JSON.stringify(bodyData))
            .then(res => {
                if(res.message !== void(0)){
                    toast.warn(res.message)
                }
            });
            this.getTopPins();
            state.optPopUp = false;
            state.pinPopUp = true;
            this.setState(state);
        } else {
            toast.error('Max Limit 3!');
            state.optPopUp = false;
            this.setState(state);
        }
    }

    /**
    *@getTopPins - get top pin messages
    */
    getTopPins = async () => {
        this.setState({
            pinData: []
        })
        const data = await userService.httpApi('GET', `chat/pin/${this.props.streamId}/${StorageService.getLogin().profile.userId}`, null);
        let filtered = data.filter(res=>res.chatUserId === StorageService.getLogin().profile.userId);
        this.setState({
            pinData: filtered
        })
    }

    /**
    *@removeTopPins - remove message from top pins
    */
    removeTopPins = async (_id, e) => {
        await userService.httpApi('DELETE', `chat/pin/${_id}`, null);
        this.getTopPins();
    }

    /**
    *@scrollTo - scroll chat and highlight message on click pin message
    */
    scrollTo = (_id, e) => {
        var state = this.state;
        state.pinPopUp = false;
        state.hitPin = true;
        this.setState(state);
        this.getMessage(_id);
        setTimeout(()=>{
            this.videoRefs[_id].scrollIntoView({ behavior: "smooth", block: "center" });
            this.videoRefs[_id].style.backgroundColor = "#e0e0e0";
            this.videoRefs[_id].style.transition = "background-color 2s";
            setTimeout(()=>{
                this.videoRefs[_id].style.backgroundColor = "";
            },3000);
        },1000);
    }

    /**
    *@newPrivateChat - open new private chat tab
    */
    newPrivateChat = (userId, e) => {
        let checkUserId = this.state.userId.filter(id => (id === userId));
        if(checkUserId.length === 0) {
            this.setState(prevState => ({
                userId: [...(prevState.userId || []), userId],
                optPopUp: false
            }), () => {
                var state = this.state;
                state.num = state.userId.length > 1 ? state.num : (state.userId.length > 2 ? parseInt(state.num) + parseInt(50) : state.num );
                this.setState(state);
            });
        } else {
            var state = this.state;
                state.optPopUp = false;
                this.setState(state);
        }
    }

    /**
    *@changeZindex - using for selection of tab from multi chat tabs
    */
    changeZindex = (id, e) => {

        this.zIndex.forEach(re => {
            if(re !== null){
                re.classList.remove('top_tab');
            }
        })
       
        this.zIndex[id].classList.add('top_tab')
    }

    /**
    *@childClick - using for remove private chat user from array
    */
    childClick = (_id, e) => {
        setTimeout(()=>{
            const resData = this.state.userId.filter(t => t !== _id);
            this.setState({
                userId: []
            },()=>{
                this.setState({
                    userId: resData,
                });
            });
        },1000)
    }

    /**
     * 
     * @handleDragEnter - Drag & Drop image
     */
    handleDragEnter = e => {
        e.preventDefault();
        this.setState({
            dropBox: true
        }, () => {
            setTimeout(()=>{
                this.setState({
                    dropBox: false
                })
            },3000)
        })
    };
    handleDragLeave = e => {
        e.preventDefault();
    };
    handleDragOver = e => {
        e.preventDefault();
    };
    handleDrop = e => {
        e.preventDefault();
        this.setState({
            dropBox: false,
            selectedFile: e.dataTransfer.files[0],
            showFile: URL.createObjectURL(e.dataTransfer.files[0]),
            optPopUp: false
        });
    };

    /**
     * 
     * @handleScroll - chat on scroll
     */
     handleScroll = async e => {
        let element = e.target;
        try {
            if (element.scrollTop===0) {
                
                this.getMessage(element);
            }
            if(element.scrollTop + element.clientHeight < element.scrollHeight) {
                this.setState({showScrollButton: true})
                this.setState({scrollChat: 1});
            }
            if(element.scrollTop + element.clientHeight === element.scrollHeight) {
                this.setState({showScrollButton: false})
                this.setState({scrollChat: 0});
            }
         } catch (error) {
            console.log('error',error);
         }
    }

     /**
      * @getMessage - get chat messages
      * @param {*} element 
      */
     getMessage = async (element) => {
        try {
            const body = {
                streamId: this.props.streamId,
                limit: this.state.limit,
                offset: this.state.offset
            }
            if(this.state.offset !== this.state.totalRecords) {
                if(this.state.hitPin === false) {
                    const data = await userService.httpApi('PUT', `chat/pagi`, JSON.stringify(body));
            
                    if(data.data){
                        const offset = this.state.offset + 9;
                        const limit = this.state.limit + 9
                        this.setState({
                            offset: offset < data.count ? offset : data.count,
                            totalRecords: data.count,
                            limit: limit
                        })
                        let filtered = data.data.filter(t=>t.userId === StorageService.getLogin().profile.userId);
                        if(filtered.length > 0) {
                            this.setState({
                                    color: filtered[0].color
                            })
                        }
                        this.drawRows(data.data);
                        element.scrollTop = 222;
                    }
                } else {
                    this.getPinChatMsg(element).then(res => {
                        if(res){
                            let filtered = res.filter(t=>t.userId === StorageService.getLogin().profile.userId);
                            if(filtered.length > 0) {
                                this.setState({
                                        color: filtered[0].color
                                })
                            }
                            this.drawRows(res);
                            this.setState({
                                hitPin: false,
                            })
                        }
                    })
                }
            } else {
                console.log('records end')
            }
            
        } catch (error) {
            console.log('error',error);
        }
    }

    /**
     * @getPinChatMsg - get pin messages on click pin
     * @param {*} _id 
     * @returns 
     */
    getPinChatMsg = async (_id) => {
        try {
            const body = {
                messageId: _id,
                limit: 4
            }
            const data = await userService.httpApi('PUT', `chat/pin`, JSON.stringify(body));
            return data;
                    
        } catch (error) {
            console.log('error',error);

        }
    }

    /**
    *@render - bind data on event.
    * Returns a JSX
    * Because that component needs to display the HTML markup or we can say JSX syntax
    */
    render() {
        const { 
                showModal,
                userList,
                pinPopUp, 
                optPopUp, 
                rows,
                pinData,
                userId,
                num,
                right,
                dropBox,
                showScrollButton,
                checkNewMessage,
                messageCount,
                newMessageId
            } = this.state;
        return (
            <> 
                <div className="container">
                    <Row className="justify-content-md-center">
                        <Col xs="12" md="8" lg="8" className="all-popup-wrapper">
                            <div className={this.state.close === true ? 'pop-up pop-up-closed' : 'pop-up'}>
                                {this.state.chosenEmoji ? <Picker onEmojiClick={this.onEmojiClick.bind(this)}/> : ''}
                                {showModal ? <div className="chat_userlist">
                                                <ul>{userList.map((res, index) => {
                                                    return <li key={index} onClick={this.selectUser.bind(this,res.name)}>{res.name}</li>
                                                    })}
                                                </ul>
                                            </div> : '' }
                                {this.state.showFile !== null ? <div className="chat_file_box"><i className={'fa fa-times'} aria-hidden="true" onClick={this.closeImagePopup}></i><img className={'show-image'} src={this.state.showFile} alt="File" /></div>: ''}                
                                <div className="chat_close_icon" onClick={this.closePopup}><span><i className={ this.state.newMessage ? 'fa fa-comments-o bg-green' : 'fa fa-comments-o' } aria-hidden="true"></i> Chat</span> <i className={this.state.close === false ? 'fa fa-minus' : 'fa fa-plus'} aria-hidden="true"></i></div>
                                
                                <div className={this.state.close === true ? 'hidden' : 'popup_outer'}>
                               
                                    <div onScroll={e => this.handleScroll(e)} className="chatBox" onDrop={e => this.handleDrop(e)}
                                                            onDragOver={e => this.handleDragOver(e)}
                                                            onDragEnter={e => this.handleDragEnter(e)}
                                                            onDragLeave={e => this.handleDragLeave(e)}>
                                        {dropBox ? <div className="drag-here"><span>Drop Here</span></div> : '' }
                                        
                                        <div className="pin-msg-outer">
                                            <div className="pin-msg">
                                                <div className={ (pinPopUp && pinData.length !== 0) ? 'pin-msg-text Active' : 'pin-msg-text' }>
                                                    {pinData.map((res, index) => {
                                                        return <p key={index}>
                                                            <i className="fa fa-thumb-tack" aria-hidden="true"></i>
                                                            <i onClick={this.removeTopPins.bind(this,res._id)} title="Remove" className="fa fa-times"></i>
                                                            <span onClick={this.scrollTo.bind(this, res.messageId)}><b>{res.messageUserId === StorageService.getLogin().profile.userId ? 'You' : res.firstName+' '+res.lastName}: </b> <span dangerouslySetInnerHTML={{__html: res.message ? res.message.length > 25 ? res.message.substring(0,25)+"..." : res.message : '' }} /></span>
                                                        </p>
                                                    })}
                                                </div>
                                                { pinData.length !== 0 ? <div className="pin-msg-expend" onClick={this.pinHandler} >
                                                    <i title={ pinPopUp ? 'Close' : 'Open' } className={ pinPopUp ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down' } ></i>
                                                    </div> : '' }
                                            </div>
                                        </div>
                                        
                                        {rows.map((chatData, index) => (
                                            <>
                                                {chatData._id === newMessageId ? <div ref={this.newLine} className="new-line"><span>New Message</span></div> : ''}
                                                <div key={index} ref={ref => this.videoRefs[chatData._id] = ref} className={'single-msg-outer'}>
                                                    <h6 style={{background: chatData.color}} title={chatData.userId === StorageService.getLogin().profile.userId ? 'You' : chatData.firstName+' '+chatData.lastName}>{chatData.firstName.charAt(0)}</h6>
                                                    <p>
                                                        <b>{chatData.userId === StorageService.getLogin().profile.userId ? 'You' : chatData.firstName+' '+chatData.lastName}: </b>
                                                        <span className="text_msg" dangerouslySetInnerHTML={{__html:chatData.message }}/>

                                                        <i title={'Options'} className="fa fa-ellipsis-v dot_icon" onClick={this.optHandler.bind(this, chatData._id)}></i>
                                                        { (optPopUp !== null && optPopUp === chatData._id ) ? <span className="opt_dropdown">
                                                        <li onClick={this.topPinHandler.bind(this,chatData._id)}>
                                                            <i className="fa fa-thumb-tack" aria-hidden="true"></i> 
                                                            {'Pin message'}
                                                        </li>
                                                        {chatData.userId !== StorageService.getLogin().profile.userId ?
                                                        <li onClick={this.newPrivateChat.bind(this,chatData.userId)}>
                                                            <i className="fa fa-comments-o"></i> 
                                                            {'Message'}
                                                            
                                                        </li> : '' }</span> : '' }
                                                    </p>
                                                    {chatData.file !== '' ? <div className="chat-img"><img onClick={this.modalOpen.bind(this,apiUrl+chatData.file)} src={apiUrl+chatData.file} className={'chat-image'} alt={'File'} /></div> : ''}
                                                </div>
                                            </>
                                        ))}
                                        
                                        <div ref={this.myRef}></div>
                                    </div>
                                    <form id='uploadForm'
                                        action=''
                                        method='post'
                                        encType="multipart/form-data">
                                            <div className="image-upload">
                                                <label htmlFor="file-input">
                                                <i className="fa fa-paperclip"></i>
                                                </label>
                                                <input id="file-input" type="file" className="chat-file" name="chatFile" onChange={this.handleFile}/>
                                            </div>
                                    </form>
                                    {showScrollButton === true ? <div className="bottom-notification">{checkNewMessage === true ? <span className="scroll-to-bottom-first" onClick={e => {this.scrollToBottom()}}>{ messageCount > 0 ? messageCount : '' } New Message</span>: "" }<i className={checkNewMessage === true ? "fa fa-angle-double-down scroll-to-bottom" : "fa fa-angle-double-down scroll-to-bottom"} onClick={e => {this.scrollToBottom()}}></i> </div>: ''}
                                    
                                    <Form onSubmit={this.addData.bind(this)} className="form-horizontal">
                                        <Row>
                                            <Col className="FormGroup mb-0">
                                               
                                                <input className="inputMsg" ref={this.focusRef} autoComplete={'off'} type="text" id="text-input" name="message" placeholder={this.state.connection ? 'Message' : 'Connecting..'} onChange={this.handleChange.bind(this, "message")} value={this.state.fields['message']  || ''} disabled={this.state.connection ? false : true}/>
                                                <div className={(this.state.errors['message'] !== void 0 && this.state.errors['message'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["message"]}</div>
                                            </Col>
                                        </Row>
                                        { this.state.connection ? <div><i className="mr-2 btn fa fa-smile-o emoji_icon" onClick={this.emojiPopUp} aria-hidden="true"></i><Button type="submit" className={'mr-2 msg_send'}><img src={icon} alt="Send" /></Button></div> : <Button type="submit" className={'mr-2 msg_send'} disabled><Loader type="Oval" color="#00BFFF" height={25} width={25} /></Button>} 
                                        
                                    </Form>
                                </div>
                            </div>
                            { userId.length !== 0 ? 
                                userId.map((id, index) => {
                                    return <div key={index} ref={ref => this.zIndex[index] = ref} onClick={this.changeZindex.bind(this,index)}>
                                                <PrivateChat eventClick={this.childClick} right={right} num={index > 0 ? num : ''} index={index} socket={socket} streamId={this.props.streamId} teamId={this.state.teamId} userId={id}/>
                                            </div>
                                }) : '' }
                        </Col>
                    </Row>

                    <Modal className='modal-lg mr_10 popup-img' isOpen={this.state.modal} backdrop={'static'}>
                        
                        <div className="modal-close-btn">
                            <i className={'fa fa-times'} aria-hidden="true" onClick={() => this.modelClose()}></i>
                        </div>
                    
                        <div className={'modal-image'}>
                            <img src={this.state.modalData} alt="File" />
                        </div>
                        
                    </Modal>
                </div>
            </>
        );
    }
}

export default Chat;


