import React, { Component } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Alert,
  Container,
} from 'reactstrap';
import { Modal, ModalHeader, ModalBody, Label, Form } from 'reactstrap';
import { userService } from './../services';
import { toast, cssTransition } from 'react-toastify';
import logger from './logger';
import StorageService from './../storage/StorageService';
import apiUrl, { millicastPath, millicastTurnUrl } from './../constants';
import { FormGroup } from 'react-bootstrap';
import Chat from './Chat';
let _this;
let ws;
let isPageEnable = true;
let afterPageEnable = false;
const Zoom = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
});

class Publisher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredCameras: [],
      filteredAudio: [],
      iceServers: [],
      token: '',
      isStart: false,
      url: undefined,
      jwt: undefined,
      stream: undefined,
      streamName: '',
      isEnabled: true,
      data: {},
      selectedCamra: '',
      selectedAudio: '',
      loading: false,
      modal: false,
      mediaData: {},
      toggleStatus: true,
      mute: false,
      fields: {
        format: 'h264',
        bitrate: '250',
        width: '1280',
        height: '720',
        fps: '24',
      },
      errors: {},
      echo: false,
      mono: 'stereo',
      channelCount: 2,
      echoCancellation: false,
      bitrate: '250',
      width: '1280',
      height: '720',
      aspectRatio: '1.7777777777',
      frameRate: '24',
      videoFormat: 'h264',
      aspect: '16/9',
      project: '',
      team: '',
      expand: false,
      activeUsers: [],
      teamId: '',
      audioEnabled: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // This method is the least used lifecycle method 
  // and called before any HTML element is rendered
  componentDidMount() {
    document.title = "Publisher | SetlinkLive";
    this.getUser();
    this.getTokenDetails();
    this.getParticularData();
    this.getAudioVideoPermission();
    this.getConnectedCamerasAudio();
    _this = this;
  }

  // Get User's details
  getUser = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${StorageService.getLogin().token}`
      },
    };
    return fetch(`${apiUrl}admin/users/${StorageService.getLogin().profile.userId}`, requestOptions)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          StorageService.clearLogin();
          this.props.history.push(`${process.env.PUBLIC_URL}/`);
          return;
        }
      })
      .then(data => {
        const storageData = StorageService.getLogin();
        if (storageData !== null) {
          if (storageData.profile.userType !== data.userType) {
            storageData.profile.userType = data.userType;
          }
          StorageService.setLogin(storageData);
        }
      });
  }

  // Get Data by token
  getParticularData = async () => {
    try {
      const data = await userService.httpApi('GET', `publish/token/${this.props.match.params.id}`, null);
      this.setState({ token: data.data[0].data.token, streamName: this.props.match.params.stream });
      (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") ? this.ready() : document.addEventListener('DOMContentLoaded', this.ready);
    } catch (error) {
      if (error === 'Token Expired') {
        toast.warn(error);
        StorageService.clearLogin();
      } else if (error === 'Login session expired') {
        toast.warn(error);
        this.props.history.push(`${process.env.PUBLIC_URL}/login`);
        StorageService.clearLogin();
      } else {
        toast.warn(error);
        this.props.history.push(`${process.env.PUBLIC_URL}/tokens`);
      }
    }
  }

  // Get Token's details
  getTokenDetails = async () => {
    try {
        await userService.httpApi('GET', `token/details/${this.props.match.params.id}`, null)
        .then(res => {
          this.setState({ 
            teamId: res[0].teamId, 
            team: res[0].teamName,
            project: res[0].projectName
          });
        });
      
    } catch (error) {
      this.props.history.push(`${process.env.PUBLIC_URL}/tokens`);
      toast.warn(error.message);
    }
  }

  // Get User Media devices
  getMedia() {
    let mediaD ;
    if(this.state.selectedAudio){
      this.setState({
        audioEnabled: false
      })
      mediaD = {
        audio: {
          deviceId: { exact: this.state.selectedAudio },
          echoCancellation: this.state.echoCancellation,
          sampleRate: (this.state.bitrate !== '250') ? parseInt(this.state.bitrate) : this.state.bitrate,
          channelCount: parseInt(this.state.channelCount),
        },
        video: {
          deviceId: { exact: this.state.selectedCamra },
          width: { min: 50, max: parseInt(this.state.width), ideal: parseInt(this.state.width) },
          height: { min: 50, max: parseInt(this.state.height), ideal: parseInt(this.state.height) },
          // frameRate: { min: 10, max: parseInt(this.state.frameRate), ideal: parseInt(this.state.frameRate) },
          aspectRatio: parseInt(this.state.aspectRatio),
        }
      }
    } else {
      this.setState({
        audioEnabled: true
      })
      mediaD = {
        video: {
          deviceId: { exact: this.state.selectedCamra },
          width: { min: 50, max: parseInt(this.state.width), ideal: parseInt(this.state.width) },
          height: { min: 50, max: parseInt(this.state.height), ideal: parseInt(this.state.height) },
          // frameRate: { min: 10, max: parseInt(this.state.frameRate), ideal: parseInt(this.state.frameRate) },
          aspectRatio: parseInt(this.state.aspectRatio),
        }
      }
    }
    
    return new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia(mediaD)
        .then(str => {
          resolve(str);
        }).catch(err => {
          reject(err);
        })
    });
  }

  // Enable Audio Video Permissions on browser
  getAudioVideoPermission() {
    navigator.mediaDevices.getUserMedia({
      audio: true, video: {
        width: { min: 640, max: 1280, ideal: 1280 },
        height: { min: 480, max: 720, ideal: 720 },
      },
    }).then(str => {
      console.log(str)
      return true;
    }).catch(err => {
      alert('Allow permissions for media')
      console.log(err)
      return false;
    });
  }

  // Get connect camera device audio
  getConnectedCamerasAudio() {
    try {
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          const filteredd = devices.filter(device => device.kind === 'videoinput' && device.deviceId !== 'default');
          if (filteredd.length) {
            this.setState({ filteredCameras: filteredd, selectedCamra: filteredd[0].deviceId });
          }
          const filtered = devices.filter(device => device.kind === 'audioinput' && device.deviceId !== 'default');
          if (filtered.length) {
            this.setState({ filteredAudio: filtered, selectedAudio: filtered[0].deviceId });
          }
          if (filteredd.length === 0 || filtered === 0) {
            setTimeout(() => {
              this.getConnectedCamerasAudio();
            }, 500);
          } else {
            setTimeout(() => {
              if (isPageEnable) {
                afterPageEnable = true;
                this.getConnectedCamerasAudioAfter()
              }
            }, 500);
          }
        });
    } catch (e) {
      setTimeout(() => {
        if (isPageEnable) {
          this.getConnectedCamerasAudio()
        }
      }, 500);
    }
  }

  // Get connect camera device mic audio
  getConnectedCamerasAudioConnet() {
    try {
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          const filteredd = devices.filter(device => device.kind === 'videoinput' && device.deviceId !== 'default');
          if (filteredd.length) {
            this.setState({ filteredCameras: filteredd, selectedCamra: filteredd[0].deviceId });
          }
          const filtered = devices.filter(device => device.kind === 'audioinput' && device.deviceId !== 'default');
          if (filtered.length) {
            this.setState({ filteredAudio: filtered, selectedAudio: filtered[0].deviceId });
          }
          this.ready();
        });
    } catch (e) {
    }
  }

  getConnectedCamerasAudioAfter() {
    try {
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          const filteredd = devices.filter(device => device.kind === 'videoinput' && device.deviceId !== 'default');
          if (filteredd.length) {
            this.setState({ filteredCameras: filteredd, selectedCamra: filteredd[0].deviceId });
          }
          const filtered = devices.filter(device => device.kind === 'audioinput' && device.deviceId !== 'default');
          if (filtered.length) {
            this.setState({ filteredAudio: filtered, selectedAudio: filtered[0].deviceId });
          }
          const filteredCamraCheck = devices.filter(device => device.kind === 'videoinput' && device.deviceId !== this.selectedCamra);
          const filteredAudioCheck = devices.filter(device => device.kind === 'audioinput' && device.deviceId !== this.selectedAudio);
          if (filteredd.length === 0 || filtered.length === 0) {
            if (this.state.isStart && (filteredCamraCheck.length === 0 || filteredAudioCheck.length === 0)) {
              this.setState({ filteredCameras: filteredd, filteredAudio: filtered, isEnabled: true });
              this.stopBroadcast();
              toast.warning('Device disconnected');
              afterPageEnable = false;
              this.getConnectedCamerasAudio();
            } else {
              this.setState({ filteredCameras: filteredd, filteredAudio: filtered, isEnabled: true });
              afterPageEnable = false;
              this.getConnectedCamerasAudio();
            }
          } else {
            if (filteredd.length !== this.state.filteredCameras && !this.state.isStart) {
              this.setState({ filteredCameras: filteredd });
            }
            if (filtered.length !== this.state.filteredAudio && !this.state.isStart) {
              this.setState({ filteredAudio: filtered });
            }
            if (this.state.isStart && (filteredCamraCheck.length === 0 || filteredAudioCheck.length === 0)) {
              this.setState({ filteredCameras: filteredd, filteredAudio: filtered, isEnabled: true });
              this.stopBroadcast();
              toast.warning('Device disconnected');
              afterPageEnable = false;
              this.getConnectedCamerasAudio();
            } else if (filteredCamraCheck.length === 0 || filteredAudioCheck.length === 0) {
              this.setState({ filteredCameras: filteredd, filteredAudio: filtered, isEnabled: true });
              afterPageEnable = false;
              this.getConnectedCamerasAudio();
            }
          }
          setTimeout(() => {
            if (isPageEnable && afterPageEnable) {
              this.getConnectedCamerasAudioAfter();
            }
          }, 500);
        });
    } catch (e) {
      setTimeout(() => {
        if (isPageEnable && afterPageEnable) {
          this.getConnectedCamerasAudioAfter();
        }
      }, 500);
    }
  }

  changeLoading(value) {
    this.setState({ loading: value })
  }

  updateMillicastAuth() {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function (evt) {
        if (xhr.readyState === 4) {
          let res = JSON.parse(xhr.responseText);
          switch (xhr.status) {
            case 200:
              let d = res.data;
              resolve(d);
              break;
            default:
              reject(res);
          }
        }
      }
      xhr.open("POST", millicastPath, true);
      xhr.setRequestHeader("Authorization", `Bearer ${this.state.token}`);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(JSON.stringify({ streamName: this.state.streamName }));
    });
  }

  getICEServers() {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function (evt) {
        if (xhr.readyState === 4) {
          let res = JSON.parse(xhr.responseText), a;
          switch (xhr.status) {
            case 200:
              if (res.s !== 'ok') {
                a = [];
                resolve(a);
                return
              }
              let list = res.v.iceServers;
              a = [];
              list.forEach(cred => {
                let v = cred.url;
                if (!!v) {
                  cred.urls = v;
                  delete cred.url;
                }
                a.push(cred);
              });
              resolve(a);
              break;
            default:
              a = [];
              resolve(a);
              break;
          }
        }
      }
      xhr.open("PUT", millicastTurnUrl, true);
      xhr.send();
    });
  }

  connected(start) {
    this.setState({ isStart: start, loading: false })
  }

  connect() {
    if ((this.state.token && this.state.url === void 0) || (this.state.token && this.state.jwt === void 0)) {
      this.updateMillicastAuth()
        .then(d => {
          this.setState({ url: d.urls[0], jwt: d.jwt });
          this.connect();
        })
        .catch(e => {
        });
      return;
    }

    let pc = new RTCPeerConnection({ iceServers: this.state.iceServers, bundlePolicy: "max-bundle" });
    //add media to connection
    this.state.stream.getTracks()
      .forEach(track => {
        pc.addTrack(track, this.state.stream)
      });
    let streamName = this.state.streamName;
    let videoFormat = this.state.videoFormat;
    //connect with Websockets for handshake to media server.
    ws = new WebSocket(this.state.url + '?token=' + this.state.jwt);//token
    ws.onopen = function () {
      //Connect to our media server via WebRTC
      //create a WebRTC offer to send to the media server
      // let offer = c.createOffe
      pc.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true
      }).then(desc => {
        //set local description and send offer to media server via ws.
        pc.setLocalDescription(desc)
          .then(() => {
            _this.connected(true);
            //set required information for media server.
            let data = {
              name: streamName,
              sdp: desc.sdp,
              codec: videoFormat,
            }
            //create payload
            let payload = {
              type: "cmd",
              transId: Math.random() * 10000,
              name: 'publish',
              data: data
            }
            ws.send(JSON.stringify(payload));
          })
          .catch(e => {
            _this.connected(false);
          })
      }).catch(e => {
        _this.connected(false);
      });
    }

    ws.addEventListener('message', evt => {
      let msg = JSON.parse(evt.data);
      //Handle counter response coming from the Media Server.
      /*  new RTCSessionDescription({
            type: 'answer',
            sdp: data.sdp + "a=x-google-flag:conference\r\n"
          }) */
      switch (msg.type) {
        case "response":
          let data = msg.data;
          let answer = {
            type: 'answer',
            sdp: data.sdp + "a=x-google-flag:conference\r\n"
          };

          pc.setRemoteDescription(answer)
            .then(d => {
            })
            .catch(e => {
            });
          break;
        default:
          break;
      }
    })
  }

  ready() {
    this.getMedia()
      .then(str => {
        this.setState({ stream: str, isEnabled: false });
        let vidWin = document.getElementsByTagName('video')[0];
        if (vidWin) {
          vidWin.srcObject = this.state.stream;
        }
      })
      .catch(e => {
        this.setState({ isEnabled: true, isStart: false, jwt: undefined });
        this.getConnectedCamerasAudioConnet();
      });
  }

  stopBroadcast = async () => {
    try {
      this.setState({ isStart: false, jwt: undefined });
      const data1 = {
        broadcasting: false,
      }
      await userService.httpApi('PATCH', `token/update/${this.props.match.params.id}`, JSON.stringify(data1));
      if (ws !== void 0 && ws.readyState !== void 0 && ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    } catch (e) {

    }
  }

  goToback = async () => {
    try {
      this.state.stream.getTracks().forEach(function (track) {
        track.stop();
      });
      if (ws !== void 0 && ws.readyState !== void 0 && ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
      const data1 = {
        broadcasting: false,
      }
      await userService.httpApi('PATCH', `token/update/${this.props.match.params.id}`, JSON.stringify(data1));
      this.setState({ isStart: false, jwt: undefined });
      this.props.history.push(`${process.env.PUBLIC_URL}/teams`);
    } catch (e) {
      this.setState({ isStart: false, jwt: undefined });
      this.props.history.push(`${process.env.PUBLIC_URL}/tokens`);
    }
  }

  selectAudio(value) {
    this.setState({ selectedAudio: value });
    setTimeout(() => {
      this.ready();
    }, 50);
  }

  selectVideo(value) {
    this.setState({ selectedCamra: value });
    setTimeout(() => {
      this.ready();
    }, 50)
  }

  startBroadcast = async () => {
    const data = {
      eventName: "Broadcasting",
      userId: StorageService.getLogin().profile.userId,
      ip: "192.168.43.18",
      description: StorageService.getLogin().profile.firstName + ' ' + StorageService.getLogin().profile.lastName + " has started broadcasting stream " + this.state.streamName,
      info: [{
        type: "Broadcasting",
        team: this.state.team,
        project: this.state.project,
        user: StorageService.getLogin().profile.firstName + ' ' + StorageService.getLogin().profile.lastName,
        stream: this.state.streamName,
      }]
    };
    await userService.httpApi('POST', `publish/logs`, JSON.stringify(data));

    const data1 = {
      broadcasting: true,
    }
    await userService.httpApi('PATCH', `token/update/${this.props.match.params.id}`, JSON.stringify(data1));

    let info = {
      type: "Broadcasting",
      team: this.state.team,
      project: this.state.project,
      user: StorageService.getLogin().profile.firstName + ' ' + StorageService.getLogin().profile.lastName,
      stream: this.state.streamName,
    }
    logger.push({ info });
    this.setState({ loading: true });
    this.getICEServers()
      .then(list => {
        this.setState({ iceServers: list, loading: true });
        this.connect();
      })
      .catch(e => {
        this.connect();
      });
  }

  componentWillUnmount() {
    if (ws !== void 0 && ws !== null && ws.readyState !== void 0 && ws.readyState !== null && ws.readyState === WebSocket.OPEN) {
      ws.close();
    }
    isPageEnable = false;
    afterPageEnable = false;
    _this = null;
    ws = null;
  }

  copyToClipboard(e, txt) {
    e.preventDefault();
    const textField = document.createElement('textarea');
    textField.innerText = txt;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toast.success('Share Link Copied', {
      transition: Zoom,
      autoClose: 1000,
      hideProgressBar: false,
      delay: 100,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  handleChange = (field, e) => {
    let fields = this.state.fields;

    fields[field] = e.target.value;
    this.setState({ fields });
  }

  /**
  *
  *@handleValidation - used for form validation
  */
  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (fields["format"].trim().length === 0) {
      formIsValid = false;
      errors["format"] = 'Format is required';
    }
    if (fields["bitrate"].trim().length === 0) {
      formIsValid = false;
      errors["bitrate"] = 'Bitrate is required';
    }
    if (fields["width"].trim().length === 0) {
      formIsValid = false;
      errors["width"] = 'Width is required';
    }
    if (fields["width"].trim().length > 0 && fields["width"] < 50) {
      formIsValid = false;
      errors["width"] = 'Width should be greater than or equal to 50';
    }
    if (fields["height"].trim().length === 0) {
      formIsValid = false;
      errors["height"] = 'Height is required';
    }
    if (fields["height"].trim().length > 0 && fields["height"] < 50) {
      formIsValid = false;
      errors["height"] = 'Height should be greater than or equal to 50';
    }
    if (fields["fps"].trim().length === 0) {
      formIsValid = false;
      errors["fps"] = 'FPS is required';
    }
    if (fields["fps"].trim().length > 0 && fields["fps"] < 10) {
      formIsValid = false;
      errors["fps"] = 'FPS should be greater than or equal to 10';
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  /**
  *
  *@handleSubmit - its call when you submit the form
  */
  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (this.handleValidation()) {
        const aspectratio = (parseInt(this.state.fields.width) / parseInt(this.state.fields.height));
        this.setState({
          echoCancellation: this.state.echo,
          bitrate: this.state.fields.bitrate,
          channelCount: this.state.channelCount,
          videoFormat: this.state.fields.format,
          width: this.state.fields.width,
          height: this.state.fields.height,
          frameRate: this.state.fields.fps,
          aspectRatio: aspectratio,
        }, () => {
          this.ready();
          toast.success("Updated successfully!");
          this.modelClose();
        });
      }
    } catch (error) {
      toast.warn(error);
    }
  }

  mediaSettings = () => {
    let fields = this.state.fields;
    this.setState({ fields, modal: true });
  }

  calculateHeight() {
    let fields = this.state.fields;
    if (this.state.aspect === '16/9') {
      const height = (parseInt(this.state.fields.width) / (16 / 9));
      fields['height'] = isNaN(Math.round(height)) ? '0' : Math.round(height).toString();
      this.setState({ fields });
      // this.setState({height: isNaN(Math.round(height)) ? '0' : Math.round(height).toString()});
    } else if (this.state.aspect === '4/3') {
      const height = (parseInt(this.state.fields.width) / (4 / 3));
      fields['height'] = isNaN(Math.round(height)) ? '0' : Math.round(height).toString();
      this.setState({ fields });
      // this.setState({height: isNaN(Math.round(height)) ? '0' : Math.round(height).toString()});
    } else {
      this.setState({ fields });
    }
  }

  calculateWidth() {
    let fields = this.state.fields;
    if (this.state.aspect === '16/9') {
      const width = (parseInt(this.state.fields.height) * (16 / 9));
      fields['width'] = isNaN(Math.round(width)) ? '0' : Math.round(width).toString();
      this.setState({ fields });
      // this.setState({width: isNaN(Math.round(width)) ? '0' : Math.round(width).toString()});
    } else if (this.state.aspect === '4/3') {
      const width = (parseInt(this.state.fields.height) * (4 / 3));
      fields['width'] = isNaN(Math.round(width)) ? 0 : Math.round(width).toString();
      this.setState({ fields });
      // this.setState({width: isNaN(Math.round(width)) ? 0 : Math.round(width).toString()});
    } else {
      this.setState({ fields });
    }
  }

  setAspectRatio(event) {
    let fields = this.state.fields;
    if (event.target.value === '16/9') {
      const width = (parseInt(this.state.fields.height) * (16 / 9));
      fields['width'] = isNaN(Math.round(width)) ? '0' : Math.round(width).toString();
      this.setState({ fields });
      // this.setState({width: isNaN(Math.round(width)) ? '0' : Math.round(width).toString()}); 
      this.setState({ aspect: '16/9' });
    } else if (event.target.value === '4/3') {
      const width = (parseInt(this.state.fields.height) * (4 / 3));
      fields['width'] = isNaN(Math.round(width)) ? '0' : Math.round(width).toString();
      this.setState({ fields });
      // this.setState({width: isNaN(Math.round(width)) ? '0' : Math.round(width).toString()});
      this.setState({ aspect: '4/3' });
    } else {
      this.setState({ fields });
      this.setState({ aspect: 'custom' });
    }
  }

  setEcho() {
    if (this.state.echo === false) {
      this.setState({ echo: true, mono: 'mono', channelCount: 1 });
    } else if (this.state.echo === true) {
      this.setState({ echo: false, mono: 'stereo', channelCount: 2 });
    }
  }

  setStereo() {
    if (this.state.mono === 'stereo') {
      this.setState({ echo: true, mono: 'mono', channelCount: 1 });
    } else if (this.state.mono === 'mono') {
      this.setState({ echo: false, mono: 'stereo', channelCount: 2 });
    }
  }

  modelClose() {
    this.setState({ modal: false });
  }

  muteMic() {
    if (this.state.mute === false) {
      this.setState({ mute: true });
      navigator.mediaDevices.getUserMedia({
        audio: true, video: {
          width: { min: 640, max: 1280, ideal: 1280 },
          height: { min: 480, max: 720, ideal: 720 },
        },
      }).then(str => {
        return true;
      }).catch(err => {
        return false;
      });
    } else if (this.state.mute === true) {
      this.setState({ mute: false });
      navigator.mediaDevices.getUserMedia({
        audio: false, video: {
          width: { min: 640, max: 1280, ideal: 1280 },
          height: { min: 480, max: 720, ideal: 720 },
        },
      }).then(str => {
        return true;
      }).catch(err => {
        return false;
      });
    }
  }

  expandVideo() {
    this.setState({ expand: !this.state.expand });
  }

  render() {
    return (
      <>
        <section id="vidView">
          <div id="myVideo"> <video autoPlay muted playsInline className={(this.state.expand === true) ? 'video-expand' : 'video-normal'}></video></div>
        </section>
        <div id="readyLive">
          <span className={(!this.state.isEnabled) ? ((!this.state.isStart) ? 'readyStreamStart' : 'liveStream') : 'readyStream'}>{(!this.state.isEnabled) ? ((!this.state.isStart) ? 'Ready' : 'Live') : 'Device not found'}</span>
          <span className="streamName">{this.state.streamName}</span>
        </div>
        <div id="close">
          <Button className="btn-information" onClick={this.expandVideo.bind(this)}><i className={(this.state.expand === true) ? "fa fa-compress" : "fa fa-expand"} aria-hidden="true"></i></Button>
          <Button className="btn-information" onClick={(e) => this.copyToClipboard(e, `https://${window.location.host}/viewer/${this.props.match.params.id}/${this.state.streamName}`)}><i className="fa fa-share" aria-hidden="true"></i></Button>
          <Button className="btn-information" onClick={() => this.mediaSettings()}><i className="fa fa-cog" aria-hidden="true"></i></Button>
          <Button className="btn-danger" onClick={() => this.goToback()}><i className="fa fa-close" aria-hidden="true"></i></Button>
        </div>
        <div className="clearfix"></div>
        <section>
          <div className="mic-camera">
            <Container>
              <Row className="mediaOption">
                <Col className="mediaOption_block" xs="4">
                  {(!this.state.isStart) ? <FormGroup className="input-group">
                    <div className="input-group-prepend"><span className={this.state.mute === true ? 'input-group-text strike-through' : 'input-group-text'} onClick={() => this.state.audioEnabled ? alert('mic not found') : this.muteMic() }><i className={this.state.audioEnabled ? "fa fa-microphone-slash" : "fa fa-microphone" } aria-hidden="true"></i></span></div>

                    <Input type="select" id="select-mic" name="mic" placeholder="Mic" defaultValue={this.state.selectedAudio} onChange={(e) => this.selectAudio(e.target.value)}>
                      {this.state.filteredAudio.map((x, index) =>
                        <option key={index} value={x.deviceId}>{(x.label === '') ? ((index === 0) ? 'Default' : `Default ${index}`) : x.label}</option>)
                      }
                      {
                        (this.state.filteredAudio.length === 0) ? <option >---</option> : ''
                      }
                    </Input>
                  </FormGroup> : ''}
                </Col>
                <Col className="mediaOption_block" xs="4">
                  <FormGroup>
                    {
                      (this.state.loading) ? <Button className="loading" >Loading...</Button> : ((this.state.isStart) ?
                        <Button className="stop" onClick={() => this.stopBroadcast()}>Stop</Button> :
                        <Button className="start" disabled={this.state.isEnabled} onClick={(e) => this.startBroadcast()}>Start</Button>)
                    }
                  </FormGroup>
                </Col>
                <Col className="mediaOption_block" xs="4">
                  {(!this.state.isStart) ? <FormGroup className="input-group">
                    <div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-video-camera" aria-hidden="true"></i></span></div>
                    <Input type="select" id="select-camera" name="camera" placeholder="Camera" defaultValue={this.state.selectedCamra} onChange={(e) => this.selectVideo(e.target.value)} >
                      {this.state.filteredCameras.map((x, index) =>
                        <option key={index} value={x.deviceId} >{(x.label === '') ? ((index === 0) ? 'Default' : `Default ${index}`) : x.label}</option>)
                      }
                      {
                        (this.state.filteredCameras.length === 0) ? <option >---</option> : ''
                      }
                    </Input>
                  </FormGroup> : ''}
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        {/* <section>
          <div className="active-users">
            <Row className="users-active">
              <Col xs="4">
              <div className="users-outer">
              {this.state.activeUsers.map((item, index) => (
                <div className="users-name" key={item}>
                  {item.firstName + ' ' + item.lastName}
                </div>
              ))}
              </div>
              </Col>
            </Row>
          </div>
        </section> */}

        <Modal className='modal-lg' isOpen={this.state.modal} backdrop={'static'}>
          <ModalHeader>
            Media Settings
            {(this.state.isStart === true) ?
              <Alert color="primary">
                Some constraints can not be updated while broadcasting.
            </Alert> : ''}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={this.handleSubmit}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="label">Format</Label>
                    <Input type="select" id="format" name="format" placeholder="Format" value={this.state.fields["format"]} onChange={this.handleChange.bind(this, "format")} disabled={(this.state.isStart) === true ? true : false}>
                      <option value="h264">h264</option>
                      <option value="vp8">vp8</option>
                      <option value="vp9">vp9</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="streamName">Bitrate</Label>
                    <Input type="select" id="bitrate" name="bitrate" placeholder="Bitrate" value={this.state.fields["bitrate"]} onChange={this.handleChange.bind(this, "bitrate")}>
                      <option value="unlimited">Unlimited</option>
                      <option value="2000">2000</option>
                      <option value="1000">1000</option>
                      <option value="500">500</option>
                      <option value="250">250</option>
                      <option value="125">125</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={4}>
                  <FormGroup>
                    <Label for="width">Width</Label>
                    <Input type="text" name="width" id="width" value={this.state.fields["width"]} onChange={this.handleChange.bind(this, "width")} onBlur={this.calculateHeight.bind(this)} disabled={(this.state.isStart) === true ? true : false} />
                    <div className={(this.state.errors['width'] !== void 0 && this.state.errors['width'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors["width"]}</div>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="height">Height</Label>
                    <Input type="text" name="height" id="height" value={this.state.fields["height"]} onChange={this.handleChange.bind(this, "height")} onBlur={this.calculateWidth.bind(this)} disabled={(this.state.isStart) === true ? true : false} />
                    <div className={(this.state.errors['height'] !== void 0 && this.state.errors['height'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors["height"]}</div>
                  </FormGroup>
                </Col>
                {/* <Col md={4}>
                    <FormGroup>
                    <Label for="fps">FPS</Label>
                      <Input type="text" name="fps" id="fps" value={this.state.fields["fps"]} onChange={this.handleChange.bind(this, "fps")} disabled={(this.state.isStart) === true ? true : false}/>
                      <div className={(this.state.errors['fps'] !== void 0 && this.state.errors['fps'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors["fps"]}</div>
                    </FormGroup>
                  </Col> */}
              </Row>
              <Row form onChange={this.setAspectRatio.bind(this)}>
                <Col md={4}>
                  <FormGroup>
                    <Label className="radio">
                      <Input type="radio" name="radio1" value="16/9" defaultChecked={this.state.aspect === '16/9' ? true : false} disabled={(this.state.isStart) === true ? true : false} /><span className="aspectRatio">16/9</span>
                    </Label>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label className="radio">
                      <Input type="radio" name="radio1" value="4/3" defaultChecked={this.state.aspect === '4/3' ? true : false} disabled={(this.state.isStart) === true ? true : false} /><span className="aspectRatio">4/3</span>
                    </Label>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label className="radio">
                      <Input type="radio" name="radio1" value="custom" defaultChecked={this.state.aspect === 'custom' ? true : false} disabled={(this.state.isStart) === true ? true : false} /><span className="aspectRatio">Custom</span>
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={4}>
                  <FormGroup className='submitbtn'>
                    <Label>Echo Cancellation</Label>
                    <Button className={this.state.echo === true ? 'togglebtn' : 'togglebtn diasble'} color="primary" onClick={this.setEcho.bind(this)}>{this.state.echo === true || this.state.mono === 'mono' ? 'Enabled' : 'Disabled'}</Button>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className='submitbtn'>
                    <Label>MONO / STEREO</Label>
                    <Button className={this.state.echo === true ? 'togglebtn diasble' : 'togglebtn'} color="primary" onClick={this.setStereo.bind(this)}>{this.state.echo === true || this.state.mono === 'mono' ? 'MONO' : 'STEREO'}</Button>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className='submitbtn'>
                <Button color="primary" type='submit'>Submit</Button>
                <Button className="btn-danger" variant="danger" onClick={() => this.modelClose()}>Cancel</Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        
        <Chat streamId={this.props.match.params.stream} teamId={''} tokenId={this.props.match.params.id}/>
      </>
    );
  }
}
export default Publisher;