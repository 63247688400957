import React, { Component } from 'react';
import { Modal, Col, Row, Form, Button } from 'reactstrap';
import { userService } from '../services';
import { toast } from 'react-toastify';
import StorageService from '../storage/StorageService';
import icon from './../assets/images/send-button.svg';
import Loader from 'react-loader-spinner'
import Picker from 'emoji-picker-react';
import axios from 'axios';
import apiUrl from '../constants';

class PrivateChat extends Component {
    constructor(props) {
        super(props);
        this.focusRef = React.createRef();
        this.myRef = React.createRef();
        this.handleFilePrivate = this.handleFilePrivate.bind(this);
        this.videoRefs = [];
        this.state = {
            fields: {
                message: ''
            },
            color: '',
            rows1: [],
            errors: {},
            userData: [],
            loading: true,
            close: false,
            output: '',
            connection: true,
            chosenEmoji: false,
            newMessage: false,
            selectedMembersValue: [],
            showModal: false,
            userList: [],
            selectedUser: '',
            selectedFilePrivate: null,
            showFilePrivate: null,
            modal: false,
            modalData: null,
            pinPopUp: false,
            optPopUp: null,
            socketData: [],
            scrollData: null,
            pinData: [],
            userDetails: [],
            dropBox: false,
            offset: 10,
            limit: 10,
            totalRecords: 0,
            scrollChat: 0,
            showScrollButton: false,
            checkNewMessage: false,
            sentMessage: false,
            messageCount: 0,
            hitPin: false,
            newMessageId: null,
            firstScroll: false
        }
    }

    /**
    *@componentDidMount
    *This method is the least used lifecycle method 
    * and called before any HTML element is rendered
    */
    componentDidMount() {
        
        //this.props.socket.emit('register', StorageService.getLogin().profile.userId);

        this.props.socket.on('private_chat',this.updateChat);
        this.getMessage();
        this.getTeam();
        this.getTopPins();
        this.getUserDetails(this.props.userId);
    }
    
    /**
    *@updateChat - using for update chat messages.
    */
    updateChat = data => {
        if(this.state.close === true) {
            toast.success(`${data.firstName} ${data.lastName}: ${data.message}`);
            this.setState({
                newMessage: true
            })
        } 
        if((this.props.userId === data.to || StorageService.getLogin().profile.userId === data.to) && (this.props.userId === data.userId || StorageService.getLogin().profile.userId === data.userId)){
            if(data.userId === StorageService.getLogin().profile.userId) {
                this.setState({
                    color: data.color
                })
            }
            this.setState(prevState => ({
                    rows1: [...(prevState.rows1 || []), data]
                }),() => {
                    setTimeout(() => {
                        this.scrollToBottom();
                    }, 500);
            });
        }
       
    }

    /**
    *@scrollToBottom - using for chat scrolling.
    */
    scrollToBottom = () => {
        this.myRef.current.scrollIntoView({ behavior: "smooth" });
    }

    /**
    *@closePopup - using for chat close and open.
    */
   closePopup = () => {
        this.setState(prevState => ({
            close: !prevState.close
        }), function() {
            if(this.state.close === false) {
                this.focusRef.current.focus();
                setTimeout(() => {
                    this.scrollToBottom();
                }, 2000);
            } else {
                this.setState({
                    newMessage: false,
                    showModal: false
                })
            }
        });
        
    }

    /**
    *@getMessage - using for get chat messages.
    */
    getMessage = async () => {
        try {
            const data = await userService.httpApi('GET', `private/chat/${StorageService.getLogin().profile.userId}/${this.props.userId}/${this.props.streamId}`, null);
            let filtered = data.filter(t=>t.userId === StorageService.getLogin().profile.userId);
            if(filtered.length > 0) {
                this.setState({
                        color: filtered[0].color
                })
            }
            this.drawRows(data);
        } catch (error) {
            console.log('error',error);
        }
    }

    /**
    *@drawRows - using for draw rows to chat messages.
    */
    drawRows(data) {
        let rows = [];
        data.map((x, index) =>
            rows.push({
                _id: x._id,
                userId: x.userId,
                firstName: x.firstName,
                lastName: x.lastName,
                message: x.message,
                color: x.color,
                chatType: x.chatType,
                file: x.file,
                created: x.created
            })
        )
        this.setState({ rows1: rows });
        if(this.state.close === false){
            setTimeout(() => {
                this.scrollToBottom();
            }, 2000);
        }
    }

    /**
    *@handleValidation - set validations for message input.
    */
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (fields["message"].trim().length === 0) {
            formIsValid = false;
            errors["message"] = "";
        }
        if (fields["message"].trim().length > 500) {
            formIsValid = false;
            errors["message"] = "Please enter maximum 500 characters";
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    /**
    *@addData - save chat message on database.
    */
    addData = async (e) => {
        e.preventDefault();
        try {

            if (this.state.selectedFilePrivate !== null && this.state.fields['message'].length < 500) {
                const extn = this.state.selectedFilePrivate.name.split('.').pop().toLowerCase();
                if (extn === 'png' || extn === 'jpg' || extn === 'jpeg' || extn === 'gif') {
                    
                    const randomColor = Math.floor(Math.random()*16777215).toString(16);
                    
                    var myStr = this.state.fields['message'];
                    var str = '';
                    const output = this.state.selectedMembersValue.map(item => {
                        if(str.length === 0){
                            str = myStr.replace(new RegExp('@'+item.name, 'g'),'@<b>'+item.name+' </b>');
                        } else {
                            str = str.replace(new RegExp('@'+item.name, 'g'),'@<b>'+item.name+' </b>');
                        }
                        return str;
                    });
    
                    const inputMessage = output[output.length - 1];
                    
                    const data = new FormData();
                    data.append('userId',StorageService.getLogin().profile.userId);
                    data.append('firstName',StorageService.getLogin().profile.firstName);
                    data.append('lastName',StorageService.getLogin().profile.lastName);
                    data.append('streamId', this.props.streamId);
                    data.append('message', inputMessage);
                    data.append('color', this.state.color ? this.state.color : '#'+randomColor);
                    data.append('chatType', 'Private');
                    data.append('to', this.props.userId,)
                    data.append('file', this.state.selectedFilePrivate);
                    data.append('status', true);
                    
                    const headers = {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${StorageService.getLogin().token}`
                    }
                    axios.post(`${apiUrl}chat/file`, data, {
                        headers: headers
                    }).then(res => {
                        this.props.socket.emit("private_chat", { 'message':res.data ,'to': this.props.userId, 'sender': StorageService.getLogin().profile.userId });
                        this.setState({
                            output: ''
                        })
                        
                    }).catch (error => {
                        toast.error(error);
                    });
                    this.setState({
                        fields :{
                            message:""
                        },
                        chosenEmoji : false,
                        showModal: false,
                        selectedFilePrivate: null,
                        showFilePrivate: null,
                        optPopUp: false
                    })
                    
                } else {
                    toast.warn('File type "'+extn+'" not valid!');
                }
            } else {
                if (this.handleValidation()) {

                    const randomColor = Math.floor(Math.random()*16777215).toString(16);
                    
                    var myStr2 = this.state.fields['message'];
                    var str2 = '';
                    const output = this.state.selectedMembersValue.map(item => {
                        if(str2.length === 0){
                            str2 = myStr2.replace(new RegExp('@'+item.name, 'g'),'@<b>'+item.name+' </b>');
                        } else {
                            str2 = str2.replace(new RegExp('@'+item.name, 'g'),'@<b>'+item.name+' </b>');
                        }
                        return str2;
                    });
    
                    const inputMessage = output[output.length - 1];
    
                    const rows = {
                                    'userId':StorageService.getLogin().profile.userId,
                                    'firstName':StorageService.getLogin().profile.firstName,
                                    'lastName':StorageService.getLogin().profile.lastName,
                                    'streamId': this.props.streamId,
                                    'to': this.props.userId,
                                    'message': inputMessage,
                                    'color': this.state.color ? this.state.color : '#'+randomColor,
                                    'chatType': 'Private',
                                    'file': '',
                                    'status': true
                                }
                                
                    this.props.socket.emit("private_chat", { 'message':rows ,'to': this.props.userId, 'sender': StorageService.getLogin().profile.userId });
    
                    this.setState({
                        fields :{
                            message:""
                        },
                        chosenEmoji : false,
                        showModal: false,
                        optPopUp: false
                    })
                    
                }
            }
        } catch (error) {
            toast.warn(error);
        }
    }

    /**
    *@getUser - get mention users list
    */
    getUser = (textMsg) => {
        const msg = textMsg.replace(/ /g, '');
        const singleletter = msg.slice(-1);
        if(msg === '@' || singleletter === '@') {
            this.setState({
                userList: this.state.selectedMembersValue,
                showModal: true
            })
            return;
        } else {
            this.setState({
                userList: '',
                showModal: false
            })
        }
        const string =  msg.includes('@') && msg.substr(msg.lastIndexOf('@') + 1).split(' ')[0];
        if(string) {
            if (string !== this.state.string) {
                this.setState({
                    string: string
                })

                const filteredData = this.state.selectedMembersValue.filter(item => {
                    return item.name.toString().toLowerCase().includes(string.toLowerCase())
                      
                });
                   
                if (filteredData.length !== 0) {
                    this.setState({
                        userList: filteredData
                    })
                    if(string === this.state.selectedUser){
                        this.setState({
                            showModal: false
                        })
                    } else {
                        this.setState({
                            showModal: true
                        })
                    }
                } else {
                    this.setState({
                        showModal: false
                    })
                }
            }else {
                this.setState({
                    showModal: false
                })
            }
        
       } else {
        this.setState({
            showModal: false
        })
       }
       
    }

    /**
    *@handleChange - bind data on event.
    */
    handleChange = (field, e) => {
        
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });

        
        this.getUser(e.target.value);
        
    }

    /**
    *@onEmojiClick - imoji picker.
    */
    onEmojiClick = (event, data) => {
        this.setState({
            fields: {
                message: this.state.fields['message'] ? this.state.fields['message'] + data.emoji : data.emoji
            }
        })
        this.focusRef.current.focus();
    }

    /**
    *@emojiPopUp - imoji popup hide show.
    */
    emojiPopUp = () => {
       
        this.setState(prevState => ({
            chosenEmoji: !prevState.chosenEmoji,
            optPopUp: false
        }));          
    }

    /**
    *@getTeam - Get team members.
    */
    getTeam = async () => {
        try {
            let selectedMembersArr = [];
            const data = await userService.httpApi('GET', `admin/teams/${this.props.teamId}`, null);
            
            data.teamMembers.map(x => (
                x.members.map(y => selectedMembersArr.push({
                    id: y._id,
                    name: y.firstName + ' ' + y.lastName,
                }))
            ));
            
            data.teamAdmins.map(x => (
                x.admins.map(y => selectedMembersArr.push({
                    id: y._id,
                    name: y.firstName + ' ' + y.lastName + ' : Admin',
                }))
            ));
            this.setState({
                selectedMembersValue: selectedMembersArr
            })
        } catch (error) {
            toast.warn(error.message);
        }
    }

    /**
    *@selectUser - user selection from mention user list
    */
    selectUser = (value, e) => {
        const string =  this.state.fields['message'].includes('@') && this.state.fields['message'].substr(this.state.fields['message'].lastIndexOf('@') + 1).split(' ')[0];
        
        if(string !== '') {
            const newValue = this.state.fields['message'].replace('@'+string,'@');
            this.setState({
                fields: {
                    message: newValue ? newValue + value+' ' : value+' '
                },
                selectedUser: value,
                showModal: false 
            });
           
        } else {
            const newValue = this.state.fields['message'];
            this.setState({
                fields: {
                    message: newValue ? newValue + value+' ' : value+' '
                },
                selectedUser: value,
                showModal: false 
            });
        }
        this.focusRef.current.focus();
    }

    /**
    *@handleFilePrivate - using for file selection
    */
    handleFilePrivate = e => {
        this.setState({
            selectedFilePrivate: e.target.files[0],
            showFilePrivate: URL.createObjectURL(e.target.files[0]),
            optPopUp: false
           });
    }
    
    /**
    *@closeImagePopup - using for image popup close
    */
    closeImagePopup = () => {
        this.setState({
            selectedFilePrivate: null,
            showFilePrivate: null
           });
    }

    /**
    *@modalOpen - using for open image preview modal
    */
    modalOpen = (data, e) => {
        this.setState({
            modal: true,
            modalData: data
           });
    }

    /**
    *@modelClose - using for close image preview modal 
    */
    modelClose() {
        this.setState({ 
            modal: false,
            modalData: null
         });
    }

    /**
    *@pinHandler - using for top pin message
    */
    pinHandler = () => {
        this.setState(prevState => ({
            pinPopUp: !prevState.pinPopUp,
            optPopUp: null
        }));   
    }

    /**
    *@optHandler - using for options on messages
    */
    optHandler = (_id,e) => {
        var nextState = this.state;
        nextState.pinPopUp = false;
        if(_id === nextState.optPopUp) {
            nextState.optPopUp = null;
            this.setState(nextState);
        } else {
            nextState.optPopUp = null;
            this.setState(nextState);
            nextState.optPopUp = _id;
            this.setState(nextState);
        }
    }

    /**
    *@topPinsHandler - using for post top pins data
    */
    topPinHandler = async (_id,e) => {
        var state = this.state;
        if(state.pinData.length < 3) {
            const bodyData = {
                messageId: _id,
                userId: StorageService.getLogin().profile.userId,
                chatUserId: this.props.userId,
                streamId: this.props.streamId
            }
            await userService.httpApi('POST', `chat/pin`,  JSON.stringify(bodyData))
            .then(res => {
                if(res.message !== void(0)){
                    toast.warn(res.message)
                }
            });
            this.getTopPins();
            state.optPopUp = false;
            state.pinPopUp = true;
            this.setState(state);
        } else {
            toast.error('Max Limit 3!');
            state.optPopUp = false;
            this.setState(state);
        }
        
    }

    /**
    *@getTopPins - using for get list of top pins messages 
    */
    getTopPins = async () => {
        this.setState({
            pinData: []
        })
        const data = await userService.httpApi('GET', `chat/pin/${this.props.streamId}/${StorageService.getLogin().profile.userId}`, null);
        let filtered = data.filter(res=>res.chatUserId === this.props.userId);
        this.setState({
            pinData: filtered
        })
    }

    /**
    *@removeTopPins - using for remove top pins messages
    */
    removeTopPins = async (_id, e) => {
        
            await userService.httpApi('DELETE', `chat/pin/${_id}`, null);
            this.getTopPins();
    }

    /**
    *@scrollTo - using for scroll chat on click top pin message
    */
    scrollTo = (_id, e) => {
        var state = this.state;
        state.pinPopUp = false;
        this.setState(state);
        this.videoRefs[_id].scrollIntoView({ behavior: "smooth", block: "center" });
        this.videoRefs[_id].style.backgroundColor = "#e0e0e0";
        this.videoRefs[_id].style.transition = "background-color 2s";
        setTimeout(()=>{
            this.videoRefs[_id].style.backgroundColor = "";
        },3000)
    }

    /**
    *@getUserDetails - using for get details of current chat user
    */
    getUserDetails = async (userId) => {
        const data = await userService.httpApi('GET', `admin/users/${userId}`, null);
       
        if(data) {
            this.setState({userDetails: data})
        }
        
    }

    handleDragEnter = e => {
        e.preventDefault();
        this.setState({
            dropBox: true
        }, () => {
            setTimeout(()=>{
                this.setState({
                    dropBox: false
                })
            },3000)
        })
    };
    handleDragLeave = e => {
        e.preventDefault();
    };
    handleDragOver = e => {
        e.preventDefault();
    };
    handleDrop = e => {
        e.preventDefault();
        this.setState({
            dropBox: false,
            selectedFilePrivate: e.dataTransfer.files[0],
            showFilePrivate: URL.createObjectURL(e.dataTransfer.files[0]),
            optPopUp: false
           });
        
    };
    /**
    *@render - bind data on event.
    * Returns a JSX
    * Because that component needs to display the HTML markup or we can say JSX syntax
    */
    render() {
        const { 
                showModal,
                userList,
                pinPopUp, 
                optPopUp, 
                rows1,
                pinData,
                userDetails,
                dropBox
            } = this.state;
        const right = this.props.right ? this.props.right : '';
        return (
            <>
                <div style={{marginRight: this.props.num, right: right}} className={this.state.close === true ? 'pop-up pop-up-closed multi-tab-closed' : 'pop-up multi-tab'}>
                    {this.state.chosenEmoji ? <Picker onEmojiClick={this.onEmojiClick.bind(this)}/> : ''}
                    {showModal ? <div className="chat_userlist">
                                    <ul>{userList.map((res, index) => {
                                        return <li key={index} onClick={this.selectUser.bind(this,res.name)}>{res.name}</li>
                                            })}
                                    </ul>
                                </div> : '' }
                    {this.state.showFilePrivate !== null ? <div className="chat_file_box"><i className={'fa fa-times'} aria-hidden="true" onClick={this.closeImagePopup}></i><img className={'show-image'} src={this.state.showFilePrivate} alt="File" /></div>: ''}                
                    <div className="chat_close_icon"><span><i className={ this.state.newMessage ? 'fa fa-comments-o bg-green' : 'fa fa-comments-o' } aria-hidden="true"></i> {userDetails.firstName} {userDetails.lastName}</span> <i onClick={this.props.eventClick.bind(this,this.props.userId)} className={this.state.close === false ? 'fa fa-times' : 'fa fa-plus'} aria-hidden="true"></i></div>
                    
                    <div className={this.state.close === true ? 'hidden' : 'popup_outer'}>
                        <div className="chatBox" onDrop={e => this.handleDrop(e)}
                                        onDragOver={e => this.handleDragOver(e)}
                                        onDragEnter={e => this.handleDragEnter(e)}
                                        onDragLeave={e => this.handleDragLeave(e)}>
                            {dropBox ? <div className="drag-here"><span>Drop Here</span></div> : '' }

                            <div className="pin-msg-outer">
                                <div className="pin-msg">
                                    <div className={ (pinPopUp && pinData.length !== 0) ? 'pin-msg-text Active' : 'pin-msg-text' }>

                                        {pinData.map((res, index) => {
                                            return <p key={index}>
                                                <i className="fa fa-thumb-tack" aria-hidden="true"></i>
                                                <i onClick={this.removeTopPins.bind(this,res._id)} title="Remove" className="fa fa-times"></i>
                                                <span onClick={this.scrollTo.bind(this, res.messageId)}><b>{res.firstName} {res.lastName}: </b> <span dangerouslySetInnerHTML={{__html: res.message ? res.message.length > 25 ? res.message.substring(0,25)+"..." : res.message : '' }} /></span>
                                            </p>
                                        })}

                                    </div>
                                    
                                    { pinData.length !== 0 ? <div className="pin-msg-expend" onClick={this.pinHandler} >
                                            <i title={ pinPopUp ? 'Close' : 'Open' } className={ pinPopUp ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down' } ></i>
                                        </div> : '' }
                                </div>
                            </div>
                            
                            {rows1.map((chatData, index) => (
                                
                                <div key={index} ref={ref => this.videoRefs[chatData._id] = ref} className={'single-msg-outer'}>
                                    <h6 style={{background: chatData.color}} title={chatData.userId === StorageService.getLogin().profile.userId ? 'You' : chatData.firstName+' '+chatData.lastName}>{chatData.firstName.charAt(0)}</h6>
                                    <p>
                                        <b>{chatData.userId === StorageService.getLogin().profile.userId ? 'You' : chatData.firstName+' '+chatData.lastName}: </b>
                                        <span className="text_msg" dangerouslySetInnerHTML={{__html:chatData.message }}/>

                                        <i title={'Options'} className="fa fa-ellipsis-v dot_icon" onClick={this.optHandler.bind(this, chatData._id)}></i>
                                        { (optPopUp !== null && optPopUp === chatData._id ) ? <span className="opt_dropdown">
                                            <li onClick={this.topPinHandler.bind(this,chatData._id)}>
                                                <i className="fa fa-thumb-tack" aria-hidden="true"></i> 
                                                {'Pin message'}
                                            </li></span> : '' }
                                    </p>
                                    
                                    {chatData.file !== '' ? <div className="chat-img"><img onClick={this.modalOpen.bind(this,apiUrl+chatData.file)} src={apiUrl+chatData.file} className={'chat-image'} alt={'File'} /></div> : ''}
                                </div>
                            ))}
                            
                            <div ref={this.myRef}></div>
                        </div>
                        <form id='uploadFormm'
                            action=''
                            method='post'
                            encType="multipart/form-data">
                            <div className="image-upload">
                                <label htmlFor="file-inputt">
                                <i className="fa fa-paperclip"></i>
                                </label>
                                <input id="file-inputt" type="file" className="chat-file" name="chatFile" onChange={(this.handleFilePrivate)}/>
                            </div>
                        </form>
                        <Form onSubmit={this.addData.bind(this)} className="form-horizontal">
                            <Row>
                                <Col className="FormGroup mb-0">
                                    
                                    <input className="inputMsg" ref={this.focusRef} autoComplete={'off'} type="text" id="text-input" name="message" placeholder={this.state.connection ? 'Message' : 'Connecting..'} onChange={this.handleChange.bind(this, "message")} value={this.state.fields['message']  || ''} disabled={this.state.connection ? false : true}/>
                                    <div className={(this.state.errors['message'] !== void 0 && this.state.errors['message'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["message"]}</div>
                                </Col>
                            </Row>
                            { this.state.connection ? <div><i className="mr-2 btn fa fa-smile-o emoji_icon" onClick={this.emojiPopUp} aria-hidden="true"></i><Button type="submit" className={'mr-2 msg_send'}><img src={icon} alt="Send" /></Button></div> : <Button type="submit" className={'mr-2 msg_send'} disabled><Loader type="Oval" color="#00BFFF" height={25} width={25} /></Button>} 
                            
                        </Form>
                    </div>
                </div>

                <Modal className='modal-lg mr_10 popup-img' isOpen={this.state.modal} backdrop={'static'}>
                    
                    <div className="modal-close-btn">
                        <i className={'fa fa-times'} aria-hidden="true" onClick={() => this.modelClose()}></i>
                    </div>
                
                    <div className={'modal-image'}>
                        <img src={this.state.modalData} alt="File" />
                    </div>
                    
                </Modal>
            </>
        );
    }
}

export default PrivateChat;


