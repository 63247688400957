import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Image } from 'react-bootstrap';
import { userService } from './../services';
import StorageService from './../storage/StorageService';
import logo from './../assets/images/SetLinkLiveNoSignalCard.jpg';
import apiUrl, { subscribePath, accountId, millicastTurnUrl } from './../constants';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import Chat from './Chat';
let iceServers = [];

class Viewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: undefined,
            jwt: undefined,
            streamName: undefined,
            open: false,
            fields: {
                noSignalImg: undefined,
            },
            waterMark: null,
        }
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = "View | SetlinkLive";
        this.getUser();
        this.getParticularData();
        this.getNoSignalImage();
    }

    // Set Image for not signal
    getNoSignalImage = async () => {
        try {
            
            const data = await userService.httpApi('GET', `admin/teams/${this.props.match.params.teamId}`, null);
            this.setState({
                fields: {
                    noSignalImg: data.team.noSignalImage,
                },
                waterMark: data.team.waterMark === void(0) ? true : data.team.waterMark
            });
        } catch (error) {
            console.log(error);
        }
    };

    // Get User's details
    getUser = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${StorageService.getLogin().token}`
            },
        };
        return fetch(`${apiUrl}admin/users/${StorageService.getLogin().profile.userId}`, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    StorageService.clearLogin();
                    this.props.history.push(`${process.env.PUBLIC_URL}/`);
                    return;
                }
            })
            .then(data => {
                const storageData = StorageService.getLogin();
                if (storageData !== null) {
                    if (storageData.profile.userType !== data.userType) {
                        storageData.profile.userType = data.userType;
                    }
                    StorageService.setLogin(storageData);
                }
            });
    }

    // Get Selected Token details
    getParticularData = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${StorageService.getLogin().token}`
            },
        };
        return fetch(`${apiUrl}publish/token/${this.props.match.params.id}`, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    this.props.history.push(`${process.env.PUBLIC_URL}/`);
                    return;
                }
            })
            .then(data => {
                if (data === undefined) {
                    this.props.history.push(`${process.env.PUBLIC_URL}/`);
                } else {
                    this.setState({ streamName: this.props.match.params.stream });
                    setTimeout(() => {
                        document.addEventListener('DOMContentLoaded', this.ready);
                    }, 100);
                }
            });
    }

    // Connect millicast api
    connect() {
        
        if (!this.state.url) {
            this.updateMillicastAuth()
                .then(d => {
                    this.setState({ jwt: d.jwt, url: d.urls[0], open: true });
                })
                .catch(e => {
                    if (e.data !== void 0 && e.data.message !== void 0) {
                        setTimeout(() => {
                            this.setState({ open: false })
                            //this.connect();
                        }, 3000);
                    }
                });
            return;
        }
        //create Peer connection object
        let conf = {
            iceServers: iceServers,
            rtcpMuxPolicy: "require",
            bundlePolicy: "max-bundle"
        };
        let pc = new RTCPeerConnection(conf);
        //Listen for track once it starts playing.
        pc.ontrack = function (event) {
            let vidWin = document.getElementsByTagName('video')[0];
            if (vidWin) {
                vidWin.srcObject = event.streams[0];
                vidWin.controls = true;
            }
        };

        //connect with Websockets for handshake to media server.
        let ws = new WebSocket(this.state.url + '?token=' + this.state.jwt);
        ws.onopen = function () {
            //create a WebRTC offer to send to the media server  let offer =
            pc.createOffer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: true
            }).then(desc => {
                //set local description and send offer to media server via ws.
                pc.setLocalDescription(desc)
                    .then(() => {
                        let data = {
                            streamId: accountId,//Millicast accountId
                            sdp: desc.sdp
                        }
                        let payload = {
                            type: "cmd",
                            transId: 0,
                            name: 'view',
                            data: data
                        }
                        ws.send(JSON.stringify(payload));
                        console.log('success');
                    })
                    .catch(e => {
                        console.log('setLocalDescription failed: ', e);
                    })
            }).catch(e => {
                console.log('createOffer failed: ', e);
            });
        }

        ws.addEventListener('message', evt => {
            let msg = JSON.parse(evt.data);
            switch (msg.type) {
                //Handle counter response coming from the Media Server.
                /* new RTCSessionDescription( {
                        type: 'answer',
                        sdp: data.sdp
                    } ) */
                case "response":
                    let data = msg.data;
                    let answer = {
                        type: 'answer',
                        sdp: data.sdp
                    };
                    pc.setRemoteDescription(answer)
                        .then(d => {
                        })
                        .catch(e => {
                            console.log('setRemoteDescription', e)
                        });
                    break;
                default:
                    break;
            }
        });
    }

    // Get ICE Server details
    getICEServers() {
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function (evt) {
                if (xhr.readyState === 4) {
                    let res = JSON.parse(xhr.responseText), a;
                    switch (xhr.status) {
                        case 200:
                            //returns array.
                            if (res.s !== 'ok') {
                                a = [];
                                //failed to get ice servers, resolve anyway to connect w/ out.
                                resolve(a);
                                return
                            }
                            let list = res.v.iceServers;
                            a = [];
                            //call returns old format, this updates URL to URLS in credentials path.
                            list.forEach(cred => {
                                let v = cred.url;
                                if (!!v) {
                                    cred.urls = v;
                                    delete cred.url;
                                }
                                a.push(cred);
                            });
                            resolve(a);
                            break;
                        default:
                            a = [];
                            //reject(xhr.responseText);
                            //failed to get ice servers, resolve anyway to connect w/ out.
                            resolve(a);
                            break;
                    }
                }
            }
            xhr.open("PUT", millicastTurnUrl, true);
            xhr.send();
        })
    }

    // Update Millicast Authentication
    updateMillicastAuth() {
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function (evt) {
                if (xhr.readyState === 4) {
                    let res = JSON.parse(xhr.responseText);
                    switch (xhr.status) {
                        case 200:
                            let d = res.data;
                            resolve(d);
                            break;
                        default:
                            reject(res);
                    }
                }
            }
            xhr.open("POST", subscribePath, true);
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.send(JSON.stringify({ streamAccountId: accountId, streamName: this.state.streamName, unauthorizedSubscribe: true }));
        });
    }

    // Using For Ready Streaming
    ready() {
        let v = document.getElementsByTagName('video')[0];
        if (v) {
            v.addEventListener("click", evt => {
                v.play();
            });
        }
        this.getICEServers()
            .then(list => {
                iceServers = list;
                this.connect();
            });
    }

    // Close Streaming
    closeViewStream = async () => {
        try {
            await userService.httpApi('PATCH', `view/streams/${StorageService.getLogin().profile.userId}`, null);
            this.props.history.push(`${process.env.PUBLIC_URL}/projects`);
        } catch (error) {
            toast.warn(error);
        }
    }

    // Take Screenshot
    onScreenshot() {
        const video = document.getElementsByTagName('video')[0];
        let canvas = document.getElementById('canvas');
        let ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, 1280, 720);
        saveAs(canvas.toDataURL(), `${Math.random().toString(36).substring(8)}.png`);
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        return (
            <>
                <main className="chat_bgimg_outer">
                    {this.state.open ?
                        <video controls autoPlay muted playsInline className="viewer-video"></video> :
                        <Image className="chat_bgimg" src={this.state.fields.noSignalImg ? apiUrl+this.state.fields.noSignalImg : logo}></Image>}
                    {this.state.open ? <Button className="btn-screenshot" onClick={this.onScreenshot} ><i className="fa fa-camera"></i></Button> : null}
                    <Button className="btn-close" onClick={this.closeViewStream}><i className="fa fa-close"></i></Button>

                    <Chat streamId={this.props.match.params.stream} tokenId={this.props.match.params.id} teamId={''}/>

                    {this.state.open && this.state.waterMark ?
                        <div className="video_watermark">
                            <h4>{StorageService.getLogin().profile.firstName + ' ' + StorageService.getLogin().profile.lastName}</h4>
                            <p>{StorageService.getLogin().profile.email}</p>
                        </div> : ''}
                    {
                        (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") ? this.ready() : ''
                    }
                    
                    <canvas id="canvas" width="1280" height="720" style={{ display: 'none' }}>  </canvas>
                </main>
            </>
        );
    }
}
export default Viewer;