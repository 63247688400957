import React, { Component } from 'react';
import {
    Col,
    Row,
    Form,
    Label,
    Input,
    Button,
} from 'reactstrap';
import SiteHeader from './SiteHeader';
import StorageService from './../storage/StorageService';
import { userService } from './../services';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

class EditProjectForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                projectName: "",
                status: "",
                tokenId: "",
            },
            errors: {},
            tid: ''
        }
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = 'Edit Project | SetlinkLive';
        this.getProject();
    }

    // Get Project's details
    getProject = async () => {
        try {
            const data = await userService.httpApi('GET', `admin/projects/${this.props.match.params.id}`, null);
            this.setState({
                fields: {
                    projectName: data[0].projectName,
                    status: data[0].status,
                    tokenId: data[0].tokens.map(x => x.label),
                },
                tid: data[0].tokenId,
            });
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                StorageService.clearLogin();
            } else if (error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else {
                toast.warn(error);
            }
        }
    }

    // Check input Validations
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (fields["projectName"].trim().length === 0) {
            formIsValid = false;
            errors["projectName"] = "Project name is required";
        }
        if (fields["status"].length === 0) {
            formIsValid = false;
            errors["status"] = "Status is required";
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    // Update Project's data
    updateData = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                console.log(this.state.tid)
                await userService.httpApi('PATCH', `admin/projects/${this.props.match.params.id}`, JSON.stringify({
                    projectName: this.state.fields.projectName,
                    status: this.state.fields.status,
                    tokenId: this.state.tid,
                }));
                toast.success('Project updated successfully');
                this.props.history.push(`${process.env.PUBLIC_URL}/projects`);
            }
        } catch (error) {
            toast.warn(error);
        }
    }

    // Bind input data on event onChange
    handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        return (
            <>
                <SiteHeader />
                <div className="container my-4 my-md-5">
                    <Row className="justify-content-md-center">
                        <Col xs="12" md="12" lg="10">
                            <div className="page_wrapper">
                                <Form onSubmit={this.updateData.bind(this)} className="form-horizontal">
                                    <h2 className="add_form_heading">Edit Project</h2>
                                    <Row>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Project Name</Label>
                                            <Input type="text" id="text-input" name="projectName" placeholder="Project Name" value={this.state.fields["projectName"]} onChange={this.handleChange.bind(this, "projectName")} />
                                            <div className={(this.state.errors['projectName'] !== void 0 && this.state.errors['projectName'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["projectName"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Publish Token</Label>
                                            <Input type="text" id="tokenId" name="tokenId" placeholder="Publish Token" value={this.state.fields["tokenId"]} onChange={this.handleChange.bind(this, "tokenId")} readOnly />
                                            <div className={(this.state.errors['tokenId'] !== void 0 && this.state.errors['tokenId'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["tokenId"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Status</Label>
                                            <Input type="select" id="select" name="status" placeholder="Status" value={this.state.fields["status"]} onChange={this.handleChange.bind(this, "status")}>
                                                <option value="">Please Select</option>
                                                <option value={true}>Active</option>
                                                <option value={false}>Inactive</option>
                                            </Input>
                                            <div className={(this.state.errors['status'] !== void 0 && this.state.errors['status'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["status"]}</div>
                                        </Col>
                                    </Row>
                                    <Button type="submit" color="primary" className={'mr-2'}><i className="fa fa-dot-circle-o"></i> Submit</Button>
                                    <Link className="btn btn-danger" to={`${process.env.PUBLIC_URL}/projects`} size="sm" color="danger"><i className="fa fa-close"></i> Cancel</Link>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default EditProjectForm;
