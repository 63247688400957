import React, { Component } from 'react';
import { Form, Button, Row, Col, Image } from 'react-bootstrap';
import StorageService from './../storage/StorageService';
import history from './../helper/history';
import SiteHeader from './SiteHeader';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions, alertActions } from './../actions';

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: { email: '', password: '', pid: this.props.match.params.pid !== void(0) ? this.props.match.params.pid : ''},
            errors: {},
            currentUser: null,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = "Login | SetlinkLive";
        const { dispatch } = this.props;
        if (StorageService.getLogin() !== null) {
            history.push(`${process.env.PUBLIC_URL}/teams`);
        } else {
            this.props.dispatch(userActions.logout());
        }

        history.listen((location, action) => {
            dispatch(alertActions.clear());
        });
        
    }

    // Bind input data on event onChange
    handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
        this.handleValidation();
    }

    // Check input Validations
    handleValidation = () => {
        let fields = this.state.fields;
        // eslint-disable-next-line no-useless-escape
        //let emailField = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        let errors = {};
        let formIsValid = true;

        if (fields["email"].trim().length === 0) {
            formIsValid = false;
            errors["email"] = "Cannot be empty";
        }
        // else if (fields["email"].trim().length !== 0 && emailField.test(fields["email"].trim()) === false) {
        //     formIsValid = false;
        //     errors["email"] = "Email id is invalid";
        // }
        if (fields["password"].trim().length === 0) {
            formIsValid = false;
            errors["password"] = "Cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    // Submit form data
    handleSubmit = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        history.listen((location, action) => {
            dispatch(alertActions.clear());
        });
        if (this.handleValidation() === true) {
            dispatch(userActions.login(this.state.fields));
        }
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        const { alert } = this.props;
        const { loading } = this.props;

        return (
            <>
                <SiteHeader />
                <div className="container my-4 my-md-5" >
                    <Row>
                        <Col className="col-md-6 mx-auto">
                            <div className="page_wrapper">
                                <h1 className="text-center login_heading">Login</h1>
                                <Form onSubmit={this.handleSubmit}>
                                    {alert.message === "singleLogin" ?  
                                        <div></div> :
                                        <div className={`alert ${alert.type}`}>{alert.message}</div>
                                    }
                                    <div className={(this.state.errors['invalid'] !== void 0 && this.state.errors['invalid'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["invalid"]}</div>
                                    <Form.Group controlId="formBasicEmail" >
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" value={this.state.fields["email"]} onChange={this.handleChange.bind(this, "email")} />
                                        <div className={(this.state.errors['email'] !== void 0 && this.state.errors['email'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["email"]}</div>
                                        <Form.Text className="text-muted">
                                            We'll never share your email with anyone else.
                                    </Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" autoComplete="false" placeholder="Password" value={this.state.fields["password"]}
                                            onChange={this.handleChange.bind(this, "password")} />
                                        <div className="invalid-feedback">{this.state.errors["password"]}</div>
                                        <div className={(this.state.errors['password'] !== void 0 && this.state.errors['password'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["password"]}</div>
                                    </Form.Group>
                                    <div className="forgot_link">
                                        <Link to={`${process.env.PUBLIC_URL}/forgot-password`} className="forgot-password">Forgot Password?</Link>
                                    </div>
                                    <Button variant="primary" type="submit" >Submit </Button>
                                    {loading !== void 0 && <Image className="ml-3" src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='></Image>}
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

// Wrapper component will subscribe to Redux store updates
// If you don't want to subscribe to store updates, pass null or undefined in place of mapStateToProps
function mapStateToProps(state) {
    const { alert } = state;
    const { loading } = state.users;
    return {
        alert,
        loading,
    };
}

// The return of connect() is a wrapper function that takes your component 
// and returns a wrapper component with the additional props it injects
export default connect(mapStateToProps)(LoginForm)
