import React, { Component } from 'react';
import {
    Col,
    Row,
    Form,
    Label,
    Input,
    Button,
} from 'reactstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import apiUrl from './../constants';
import StorageService from './../storage/StorageService';
import { Multiselect } from 'multiselect-react-dropdown';
import SiteHeader from './SiteHeader';
import { userService } from './../services';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner'

class EditTeamForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                teamName: "",
                headerColor: "",
            },
            logo: null,
            noSignal: null,
            noSignalImageName:"",
            logoImageName: "",
            file: null,
            noSignalFile: null,
            noSignalS: null,
            errors: {},
            members: [],
            projects: [],
            admins: [],
            selectedMembers: [],
            selectedProjects: [],
            selectedAdmins: [],
            selectedMembersValue: [],
            selectedProjectsValue: [],
            selectedAdminsValue: [],
	    loading: false,
            waterMark: null,
        }
        this.removeImage = this.removeImage.bind(this);
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = 'Edit User | SetlinkLive';
        this.getTeam();
        this.getMembers();
        this.getProjects();
        this.getAdmins();
    }

    // Get Team's Users list
    getMembers = async () => {
        const { currentUser } = this.props;
        try {
            if (currentUser.currentUser === 'superAdmin') {
                let arr = [];
                const data = await userService.httpApi('GET', `users`, null);
                data.map((x) =>
                    arr.push({
                        id: x._id,
                        name: x.firstName + ' ' + x.lastName + ' (' + x.email + ')',
                    })
                )
                this.setState({ members: arr });
            } else if (currentUser.currentUser === 'broadcaster') {
                    let arr = [];
                    const data = await userService.httpApi('GET', `users`, null);
                    data.map((x) =>
                        arr.push({
                            id: x._id,
                            name: x.firstName + ' ' + x.lastName + ' (' + x.email + ')',
                        })
                    )
                    this.setState({ members: arr });
                } else {
                let arr = [];
                const data = await userService.httpApi('GET', `users/admin/${currentUser.id}`, null);
                data.map((x) =>
                    arr.push({
                        id: x._id,
                        name: x.firstName + ' ' + x.lastName + ' (' + x.email + ')',
                    })
                )
                this.setState({ members: arr });
            }

        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                StorageService.clearLogin();
            } else if (error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else {
                toast.warn(error);
            }
        }
    }

    // Get Projects list
    getProjects = async () => {
        const { currentUser } = this.props;
        try {
            if (currentUser.currentUser === 'superAdmin') {
                let arr = [];
                const data = await userService.httpApi('GET', `projects`, null);
                data.map((x) =>
                    arr.push({
                        id: x._id,
                        name: x.projectName,
                    })
                )
                this.setState({ projects: arr });
            } else {
                let arr = [];
                const data = await userService.httpApi('GET', `projects/admin/${currentUser.id}`, null);
                data.map((x) =>
                    arr.push({
                        id: x._id,
                        name: x.projectName,
                    })
                )
                this.setState({ projects: arr });
            }
        } catch (error) {
            toast.warn(error.message);
        }
    }

    // Get Admins list
    getAdmins = async () => {
        const { currentUser } = this.props;
        try {
            if (currentUser.currentUser === 'superAdmin') {
                let arr = [];
                const data = await userService.httpApi('GET', `admins`, null);
                data.map((x) =>
                    arr.push({
                        id: x._id,
                        name: x.firstName + ' ' + x.lastName + ' (' + x.email + ')',
                    })
                )
                this.setState({ admins: arr });
            } else {
                let arr = [];
                const data = await userService.httpApi('GET', `admins/admin/${currentUser.id}`, null);
                data.map((x) =>
                    arr.push({
                        id: x._id,
                        name: x.firstName + ' ' + x.lastName + ' (' + x.email + ')',
                    })
                )
                this.setState({ admins: arr });
            }
        } catch (error) {
            toast.warn(error.message);
        }
    }

    // Get selected user's details
    onSelectMembers = async (selectedList, selectedItem) => {
        const list = selectedList.map(x => x.id);
        await userService.httpApi('POST', `edit/team/members/${this.props.match.params.id}`, JSON.stringify({
            userId: selectedItem.id,
        }));
        this.setState({ selectedMembers: list });
        this.getMembers();
    }

    // Remove selected User
    onRemoveMembers = async (selectedList, removedItem) => {
        const list = selectedList.map(x => x.id);
        await userService.httpApi('DELETE', `edit/team/members/${this.props.match.params.id}`, JSON.stringify({
            userId: removedItem.id,
        }));
        this.setState({ selectedMembers: list });
        this.getMembers();
    }

    // Get selected Project's details
    onSelectProjects = async (selectedList, selectedItem) => {
        const list = selectedList.map(x => x.id);
        await userService.httpApi('POST', `edit/team/project/${this.props.match.params.id}`, JSON.stringify({
            projectId: selectedItem.id,
        }));
        this.setState({ selectedProjects: list });
        this.getProjects();
    }

    // Remove selected Project
    onRemoveProjects = async (selectedList, removedItem) => {
        const { currentUser } = this.props;
        if(currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin'){
       
            const list = selectedList.map(x => x.id);
            await userService.httpApi('DELETE', `edit/team/project/${this.props.match.params.id}`, JSON.stringify({
                projectId: removedItem.id,
            }));
            this.setState({ selectedProjects: list });
            this.getProjects();
                
            }
        }

    // Get selected Admin's details
    onSelectAdmins = async (selectedList, selectedItem) => {
        const list = selectedList.map(x => x.id);
        await userService.httpApi('POST', `edit/team/admins/${this.props.match.params.id}`, JSON.stringify({
            userId: selectedItem.id,
        }));
        this.setState({ selectedAdmins: list });
        this.getAdmins();
    }

    // Remove selected Admin
    onRemoveAdmins = async (selectedList, removedItem) => {
        const list = selectedList.map(x => x.id);
        const { currentUser } = this.props;
        if(currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin'){
                await userService.httpApi('DELETE', `edit/team/admins/${this.props.match.params.id}`, JSON.stringify({
                    userId: removedItem.id,
                }));
                this.setState({ selectedAdmins: list });
                this.getAdmins();
            }
        }

    // Get Team details
    getTeam = async () => {
        try {
            let selectedMembersArr = [];
            let selectedProjectsArr = [];
            let selectedAdminsArr = [];
            let selectedMembers = [];
            let selectedProjects = [];
            let selectedAdmins = [];
            const data = await userService.httpApi('GET', `admin/teams/${this.props.match.params.id}`, null);
            
            const noSignalImageName = this.getImageName(data.team.noSignalImage);
            const logoImageName = this.getImageName(data.team.logo);

            data.teamMembers.map(x => (
                x.members.map(y => selectedMembersArr.push({
                    id: y._id,
                    name: y.firstName + ' ' + y.lastName + ' (' + y.email + ')',
                })
                )));
            data.teamMembers.map(x => (
                x.members.map(y => selectedMembers.push(y._id)
                )));
            data.teamProjects.map(x => (
                x.projects.map(y => selectedProjectsArr.push({
                    id: y._id,
                    name: y.projectName,
                })
                )));
            data.teamProjects.map(x => (
                x.projects.map(y => selectedProjects.push(y._id)
                )));
            data.teamAdmins.map(x => (
                x.admins.map(y => selectedAdminsArr.push({
                    id: y._id,
                    name: y.firstName + ' ' + y.lastName + ' (' + y.email + ')',
                })
                )));
            data.teamAdmins.map(x => (
                x.admins.map(y => selectedAdmins.push(y._id)
                )));

            let url ='';
            let urlLogo ='';
            if(data.team.noSignalImage) {
                url = apiUrl;
            }
            if(data.team.logo) {
                urlLogo = apiUrl;
            }

            this.setState({
                fields: {
                    teamName: data.team.teamName,
                    headerColor: data.team.headerColor,
                },
                noSignalImageName: noSignalImageName,
                logoImageName: logoImageName,
                logo: urlLogo+data.team.logo,
                noSignal: url+data.team.noSignalImage,
                selectedMembersValue: selectedMembersArr,
                selectedMembers: selectedMembers,
                selectedProjectsValue: selectedProjectsArr,
                selectedProjects: selectedProjects,
                selectedAdminsValue: selectedAdminsArr,
                selectedAdmins: selectedAdmins,
                waterMark: data.team.waterMark === void(0) ? true : data.team.waterMark,
            });
        } catch (error) {
            toast.warn(error.message);
        }
    }

    // Get Image Name
    getImageName = imageData => {
        const imageSplitName = imageData.split('--');
        const imageSplitLastName = imageSplitName[0].split('/');
        return imageSplitLastName[imageSplitLastName.length - 1];
    }

    // Bind data on event onChange
    onChangeHandler = event => {
        const extn = event.target.files[0].name.split('.').pop().toLowerCase();

        if (extn === 'png' || extn === 'jpg' || extn === 'jpeg' || extn === 'gif') {
            if (event.target.name === 'logo') {
                this.setState({
                    file: event.target.files[0],
                    loaded: 0,
                    logo: URL.createObjectURL(event.target.files[0]),
                });
            } else {
                this.setState({
                    noSignal: URL.createObjectURL(event.target.files[0]),
                    noSignalFile: event.target.files[0],
                    loaded: 0,
                });
            }
            
        } else {
            toast.warn("Invalid File Format.");
        }
    }

    // Check input Validations
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (fields["teamName"].trim().length === 0) {
            formIsValid = false;
            errors["teamName"] = "Team name cannot be empty";
        }
        if (this.state.selectedAdmins.length === 0) {
            formIsValid = false;
            errors["admins"] = "Admins cannot be empty";
        }
        if (fields["headerColor"].trim().length !== 0) {
            const hexCode = /^#[0-9a-f]{3}([0-9a-f]{3})?$/i;
            if (!hexCode.test(fields["headerColor"].trim())) {
                formIsValid = false;
                errors["headerColor"] = "Invalid Color";
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    // Update Project's data
    updateData = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                const data = new FormData();
                data.append('teamName', this.state.fields.teamName);
                data.append('headerColor', this.state.fields.headerColor);
                data.append('file', this.state.file);
                data.append('noSignalFile', this.state.noSignalFile);
                data.append('waterMark', this.state.waterMark);

                const headers = {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${StorageService.getLogin().token}`
                }
                axios.patch(`${apiUrl}admin/teams/${this.props.match.params.id}`, data, {
                    headers: headers
                })
                    .then(res => {
                        toast.success('Team updated successfully');
                        this.props.history.push(`${process.env.PUBLIC_URL}/teams`)
                    }
                    )
                    .catch(err => {
                        toast.warn("Team Name already used.");
                    })
            }
        } catch (error) {
            toast.warn(error);
        }
    }

    // Bind input data on event onChange
    handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    removeImage = param => e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        if(window.confirm('Are you sure to delete this file ?')){
            const data = {
                'imageName': param
            }
            try {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${StorageService.getLogin().token}`
                }
                axios.patch(`${apiUrl}admin/teams/remove-image/${this.props.match.params.id}`, data, {
                    headers: headers
                }).then(res => {
                        toast.success(res.data.message);
                        this.getTeam();
                        this.getMembers();
                        this.getProjects();
                        this.getAdmins();
                        this.setState({loading: false});
                }).catch(err => {
                    toast.warn("Image not found");
                })
    
            } catch (error) {
                toast.warn(error);
            }
        }
        this.setState({loading: false});
    }

    onCheckHandler = (value) => {
        this.setState({
            waterMark: value
        });
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        const { currentUser } = this.props;
        return (
            <>
                <SiteHeader />
                <div className="container my-4 my-md-5">
                    <Row className="justify-content-md-center">
                        <Col xs="12" md="12" lg="10">
                            <div className="page_wrapper">
                                <Form onSubmit={this.updateData.bind(this)} className="form-horizontal">
                                    <h2 className="add_form_heading">Edit Team: <span style={{color:'#777777'}}>{this.state.fields["teamName"]}</span></h2>
                                    <Row>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Team Name</Label>
                                            <Input type="text" id="text-input" name="teamName" placeholder="Team Name" value={this.state.fields["teamName"] || ''} onChange={this.handleChange.bind(this, "teamName")} />
                                            <div className={(this.state.errors['teamName'] !== void 0 && this.state.errors['teamName'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["teamName"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Header Color</Label>
                                            <div className="color_edit_outer">
                                                <Input type="text" id="headerColor" name="headerColor" placeholder="Header Color" value={this.state.fields["headerColor"] || ''} onChange={this.handleChange.bind(this, "headerColor")} />
                                                <div className={(this.state.errors['headerColor'] !== void 0 && this.state.errors['headerColor'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["headerColor"]}</div>
                                                <Input type="text" className="selectedColor" name="color" style={{ backgroundColor: (this.state.fields["headerColor"] === '' ? '#ffffff' : this.state.fields["headerColor"]), pointerEvents: "none" }} readOnly />
                                            </div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Members</Label>
                                            <Multiselect
                                                options={this.state.members}
                                                selectedValues={this.state.selectedMembersValue}
                                                onSelect={this.onSelectMembers.bind(this)}
                                                onRemove={this.onRemoveMembers.bind(this)}
                                                displayValue="name"
                                            />
                                            {/* <div className={(this.state.errors['members'] !== void 0 && this.state.errors['members'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["members"]}</div> */}
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Projects</Label>
                                            <Multiselect
                                                options={this.state.projects}
                                                selectedValues={this.state.selectedProjectsValue}
                                                onSelect={this.onSelectProjects.bind(this)}
                                                onRemove={currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin'  ? this.onRemoveProjects.bind(this) : ''}
                                                displayValue="name"
                                            />
                                            {/* <div className={(this.state.errors['projects'] !== void 0 && this.state.errors['projects'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["projects"]}</div> */}
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Admins</Label>
                                            <Multiselect
                                                options={this.state.admins}
                                                selectedValues={this.state.selectedAdminsValue}
                                                onSelect={this.onSelectAdmins.bind(this)}
                                                onRemove={currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin'  ? this.onRemoveAdmins.bind(this) : ''}
                                                displayValue="name"
                                            />
                                            <div className={(this.state.errors['admins'] !== void 0 && this.state.errors['admins'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["admins"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Logo</Label>
                                            <Input type="file" className="form-control" id="text-input" name="logo" onChange={this.onChangeHandler} />
                                            {this.state.logo !== "" ? (<><img src={this.state.logo} className="mt-8" alt="No Signal" width="24%" height="50"/><button className="remove-button" onClick={this.removeImage("logo")}><i className="fa fa-close"></i></button></>) : ''}
                                            {this.state.logo !== "" ? <span className="d-flex">{this.state.logoImageName}</span> : ''}
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">No Signal Image</Label>
                                            <Input type="file" className="form-control" id="noSignal-input" name="noSignal" onChange={this.onChangeHandler} />
                                            {this.state.noSignal !== "" ? (<><img src={this.state.noSignal} className="mt-8" alt="No Signal" width="24%" height="50"/>{this.state.loading ? (<div className="remove-button-loader"><Loader type="Oval" color="#00BFFF" height={20} width={20} /></div>) : (<button className="remove-button" onClick={this.removeImage("noSignalImage")}><i className="fa fa-close"></i></button>) }</>) : ''}
                                            {this.state.noSignal !== "" ? <span className="d-flex">{this.state.noSignalImageName}</span> : ''}                                            
                                        </Col>
                                        <Col sm="6" className="FormGroup d-flex">
                                            <Label htmlFor="text-input">Water Mark</Label>
                                            <label class="custom__switch">
                                            <Input type="checkbox" className="form-control" id="water-mark" name="waterMark" checked={ this.state.waterMark || ''} onChange={(e) => {
                                                this.onCheckHandler(e.target.checked)
                                            }} />
                                            <span class="slider round"></span>
                                            </label>
                                        </Col>
                                    </Row>
                                    <Button type="submit" color="primary" className={'mr-2'}><i className="fa fa-dot-circle-o"></i> Submit</Button>
                                    <Link className="btn btn-danger" to={`${process.env.PUBLIC_URL}/teams`} size="sm" color="danger"><i className="fa fa-close"></i> Cancel</Link>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

// Wrapper component will subscribe to Redux store updates
// If you don't want to subscribe to store updates, pass null or undefined in place of mapStateToProps
function mapStateToProps(state) {
    const { logedIn } = state.isLogged;
    const { currentUser } = state;
    return {
        logedIn,
        currentUser,
    };
}

// The return of connect() is a wrapper function that takes your component 
// and returns a wrapper component with the additional props it injects
export default connect(mapStateToProps)(EditTeamForm);
