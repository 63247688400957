import { combineReducers } from 'redux';

import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { isLogged } from './isLogged.reducer';
import { currentUser } from './currentUser.reducer';
const rootReducer = combineReducers({
  users,
  isLogged,
  alert,
  currentUser,
});

export default rootReducer;

