import React, { Component } from 'react';
import {
    Col,
    Row,
    Form,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import SiteHeader from './SiteHeader';
import StorageService from './../storage/StorageService';
import { userService } from './../services';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

class EditUserForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                phone: "",
                userType: "",
            },
            errors: {},
            options: [],
            selectedOptions: [],
        }
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = 'Edit User | SetlinkLive'
        this.getUser();
    }

    // Get User details
    getUser = async () => {
        try {
            const data = await userService.httpApi('GET', `admin/users/${this.props.match.params.id}`, null);
            this.setState({
                fields: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    password: data.password,
                    phone: data.phone,
                    userType: data.userType,
                },
            });
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                StorageService.clearLogin();
            } else if (error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else {
                toast.warn(error);
            }
        }
    }

    // Check input Validations
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (fields["firstName"].trim().length === 0) {
            formIsValid = false;
            errors["firstName"] = "Firstname cannot be empty";
        }
        if (fields["lastName"].trim().length === 0) {
            formIsValid = false;
            errors["lastName"] = "Lastname cannot be empty";
        }
        if (fields["email"].trim().length === 0) {
            formIsValid = false;
            errors["email"] = "Email cannot be empty";
        }
        if (fields["email"].trim().length !== 0) {
            const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"].trim())) {
                formIsValid = false;
                errors["email"] = "Please enter valid email address.";
            }
        }
        if (fields["password"] !== undefined) {
            const upper = /(?=.*[A-Z])/;
            const digit = /(?=.*[0-9])/;
            const special = /(?=.*[!@#_$%^&*])/;
            if (fields["password"].trim().length < 8) {
                formIsValid = false;
                errors["password"] = "Please enter minimum 8 characters";
            }
            if (fields["password"].trim().length > 16) {
                formIsValid = false;
                errors["password"] = "Please enter maximum 16 characters";
            }
            if (!upper.test(fields["password"])) {
                formIsValid = false;
                errors["password"] = "Please enter one uppercase letter";
            }
            if (!digit.test(fields["password"])) {
                formIsValid = false;
                errors["password"] = "Please enter one digit";
            }
            if (!special.test(fields["password"])) {
                formIsValid = false;
                errors["password"] = "Please enter one special character";
            }
        }
        if (fields["phone"].trim().length === 0) {
            formIsValid = false;
            errors["phone"] = "Phone cannot be empty";
        }
        // if (fields["phone"].trim().length !== 0) {
        //     const phonepattern = /[0-9]{10}/;
        //     if (fields["phone"].trim().length < 10) {
        //         formIsValid = false;
        //         errors["phone"] = "Please enter minimum 10 digits";
        //     }
        //     if (fields["phone"].trim().length > 15) {
        //         formIsValid = false;
        //         errors["phone"] = "Please enter maximum 15 digits";
        //     }
        //     if (!phonepattern.test(fields["phone"])) {
        //       formIsValid = false;
        //       errors["phone"] = "Please enter valid phone no.";
        //     }
        // }
        if (fields["userType"].trim().length === 0) {
            formIsValid = false;
            errors["userType"] = "User type cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    // Update Project's data
    updateData = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                if (this.state.fields.password === undefined) {
                    await userService.httpApi('PATCH', `admin/users/${this.props.match.params.id}`, JSON.stringify({
                        firstName: this.state.fields.firstName,
                        lastName: this.state.fields.lastName,
                        email: this.state.fields.email.trim(),
                        phone: this.state.fields.phone,
                        userType: this.state.fields.userType,
                    }));
                    toast.success('User updated successfully');
                    this.props.history.push(`${process.env.PUBLIC_URL}/users`);
                } else {
                    await userService.httpApi('PATCH', `admin/users/${this.props.match.params.id}`, JSON.stringify({
                        firstName: this.state.fields.firstName,
                        lastName: this.state.fields.lastName,
                        email: this.state.fields.email.trim(),
                        password: this.state.fields.password,
                        phone: this.state.fields.phone,
                        userType: this.state.fields.userType,
                    }));
                    toast.success('User updated successfully');
                    this.props.history.push(`${process.env.PUBLIC_URL}/users`);
                }
            }
        } catch (error) {
            toast.warn(error);
        }
    }

    // Bind input data on event onChange
    handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        const { currentUser } = this.props;
        return (
            <>
                <SiteHeader />
                <div className="container my-4 my-md-5">
                    <Row className="justify-content-md-center">
                        <Col xs="12" md="12" lg="10">
                            <div className="page_wrapper">
                                <Form onSubmit={this.updateData.bind(this)} className="form-horizontal">
                                    <h2 className="add_form_heading">Edit User</h2>
                                    <Row>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">First Name</Label>
                                            <Input type="text" id="text-input" name="firstName" placeholder="First Name" value={this.state.fields["firstName"]} onChange={this.handleChange.bind(this, "firstName")} />
                                            <div className={(this.state.errors['firstName'] !== void 0 && this.state.errors['firstName'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["firstName"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Last Name</Label>
                                            <Input type="text" id="text-input" name="lastName" placeholder="Last Name" value={this.state.fields["lastName"]} onChange={this.handleChange.bind(this, "lastName")} />
                                            <div className={(this.state.errors['lastName'] !== void 0 && this.state.errors['lastName'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["lastName"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Email</Label>
                                            <Input type="text" id="text-input" name="email" placeholder="Email" value={this.state.fields["email"]} onChange={this.handleChange.bind(this, "email")} />
                                            <div className={(this.state.errors['email'] !== void 0 && this.state.errors['email'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["email"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Password</Label>
                                            <Input type="text" id="text-input" name="password" placeholder="Please enter new password" onChange={this.handleChange.bind(this, "password")} />
                                            <div className={(this.state.errors['password'] !== void 0 && this.state.errors['password'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["password"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Phone</Label>
                                            <Input type="text" id="text-input" name="phone" placeholder="Phone" value={this.state.fields["phone"]} onChange={this.handleChange.bind(this, "phone")} />
                                            <div className={(this.state.errors['phone'] !== void 0 && this.state.errors['phone'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["phone"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">User Type</Label>
                                            <Input type="select" id="select" name="userType" placeholder="User Type" value={this.state.fields["userType"]} onChange={this.handleChange.bind(this, "userType")}>
                                                <option value="">Please Select</option>
                                                {(currentUser === 'superAdmin') ? <option value="superAdmin">Super Admin</option> : ''}
                                                {(currentUser === 'superAdmin') ? <option value="admin">Admin</option> : ''}
                                                <option value="broadcaster">Broadcaster</option>
                                                <option value="viewer">Viewer</option>
                                            </Input>
                                            <div className={(this.state.errors['userType'] !== void 0 && this.state.errors['userType'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["userType"]}</div>
                                        </Col>
                                    </Row>
                                    <Button type="submit" color="primary" className={'mr-2'}><i className="fa fa-dot-circle-o"></i> Submit</Button>
                                    <Link className="btn btn-danger" to={`${process.env.PUBLIC_URL}/users`} size="sm" color="danger"><i className="fa fa-close"></i> Cancel</Link>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

// Wrapper component will subscribe to Redux store updates
// If you don't want to subscribe to store updates, pass null or undefined in place of mapStateToProps
function mapStateToProps(state) {
    const { logedIn } = state.isLogged;
    const { currentUser } = state.currentUser;
    return {
        logedIn,
        currentUser,
    };
}

// The return of connect() is a wrapper function that takes your component 
// and returns a wrapper component with the additional props it injects
export default connect(mapStateToProps)(EditUserForm);