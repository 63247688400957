import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import  StorageService   from './../storage/StorageService';

export const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
       (StorageService.getLogin()!==null && (StorageService.getLogin().profile.userType === 'superAdmin' || StorageService.getLogin().profile.userType === 'admin' || StorageService.getLogin().profile.userType === 'broadcaster'))
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/projects', state: { from: props.location } }} />
    )} />
)
