import React, { Component } from 'react';
import {
    Col,
    Row,
    Label,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import { Image } from 'react-bootstrap';
import loading from './../assets/images/Loading_icon.gif';
import SiteHeader from './SiteHeader';
import StorageService from './../storage/StorageService';
import { userService } from './../services';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

class EditTokenForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            modal: false,
            fields: {
                label: "",
                streamName: "",
            },
            stream: '',
            expiresOn: '',
            isActive: true,
            streams: [],
        }
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = 'Edit Token | SetlinkLive'
        this.getToken();
    }

    // Get Token details
    getToken = async () => {
        try {
            const data = await userService.httpApi('GET', `publish/token/${this.props.match.params.id}`, null);
            this.setState(
                {
                    fields: {
                        label: data.label,
                        streamName: data.streams[0].streamName,
                    },
                    streams: data.streams,
                    isActive: data.isActive,
                    expiresOn: (data.expiresOn === 0) ? '-' : new Date(data.expiresOn).toLocaleDateString("en-US") + ' ' + new Date(data.expiresOn).toLocaleTimeString("en-US"),
                }
            );
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                StorageService.clearLogin();
            } else if (error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else {
                toast.warn(error);
            }
        }
    }

    // Update Token's Label
    updateLabel = async () => {
        try {
            let data = this.state.fields;
            delete data._id;
            if (this.state.fields.label !== '') {
                await userService.httpApi('PATCH', `publish/token/${this.props.match.params.id}`, JSON.stringify({
                    label: this.state.fields.label,
                }));
                toast.success("Label updated successfully.");
                this.props.history.push(`${process.env.PUBLIC_URL}/tokens`);
            }
        } catch (error) {
            toast.warn(error.message);
        }
    }

    // Bind input data on event onChange
    handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    // Remove Stream
    removeStream = async () => {
        const streams = this.state.streams;
        const selectedStream = this.state.fields["streamName"];
        if (selectedStream) {
            const index = streams.findIndex(x => x.streamName === selectedStream);
            const st = streams.filter(x => x.streamName === selectedStream);
            let arr = [];
            arr.push(st[0].streamName);

            if (streams.length > 1) {
                if (index > -1) {
                    streams.splice(index, 1)
                    this.setState({ streams: streams });
                    await userService.httpApi('PATCH', `publish/token/streams/${this.props.match.params.id}`, JSON.stringify({
                        removeTokenStreams: arr,
                        streams: streams,
                    }));
                    toast.success('Stream removed successfully.');
                    this.getToken();
                }
            } else {
                toast.warn("At least one stream is required.");
            }
        } else {
            toast.warn("At least select one stream to remove.");
        }
    }

    // Get Stream 
    getTokenStreams = (e) => {
        const stream = e.target.value;
        this.setState({ stream: stream });
    }

    // Add Token Stream
    addTokenStreams = async () => {
        if (Number(this.state.stream)) {
            const token = await userService.httpApi('PATCH', `publish/token/addstreams/${this.props.match.params.id}`, JSON.stringify({
                streams: this.state.stream,
            }));
            if (token.status === 'success') {
                toast.success('Streams Added successfully');
                this.setState({ fields: { stream: '' } });
                this.modelClose();
                this.getToken();
            }
        } else {
            toast.warn("Stream cannot be empty")
        }
    }

    // Add Token
    addToken = () => {
        let fields = this.state.fields;
        this.setState({ fields, modal: true });
    }

    // Modal close 
    modelClose() {
        this.setState({ modal: false });
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        return (
            <>
                <SiteHeader />
                {this.state.isLoading === true ?
                    <Image className="loading-icon" src={loading}></Image> :
                    <div className="container my-4 my-md-5">
                        <Row className="justify-content-md-center"> <Col xs="12" md="12" lg="10">
                            <div className="page_wrapper">
                                <div className="back-btn mb-4">
                                    <Link to={`${process.env.PUBLIC_URL}/tokens`} className={'back_btn mr-2'}><i class="fa fa-long-arrow-left"></i> Back</Link>
                                    <Button className="save" onClick={() => this.updateLabel()}> Save</Button>
                                </div>
                                <h2 className="add_form_heading">Edit Token</h2>
                                <Row>
                                    <Col sm="6" className="FormGroup select_height">
                                        <Label htmlFor="text-input">Stream Names</Label>
                                        <Input type="select" id="text-input" name="streamName" size="5" placeholder="Stream Name" value={this.state.fields["streamName"]} onChange={this.handleChange.bind(this, "streamName")} disabled={this.state.isActive === false ? true : false}>
                                            {this.state.streams.map((x, index) =>
                                                <option key={index} value={x.streamName}>{x.streamName}</option>)
                                            }
                                        </Input>
                                    </Col>
                                    <Col sm="6" className="FormGroup">
                                        <Label htmlFor="text-input">Label</Label>
                                        {/* <Input type="text" id="text-input" name="label" placeholder="Label" value={this.state.fields["label"]} onChange={this.handleChange.bind(this, "label")} onBlur={this.updateLabel.bind(this)} className={this.state.isActive === false ? 'label-inactive' : 'label-active'}/> */}
                                        <Input type="text" id="text-input" name="label" placeholder="Label" value={this.state.fields["label"]} onChange={this.handleChange.bind(this, "label")} className={this.state.isActive === false ? 'label-inactive' : 'label-active'} />
                                    </Col>
                                    <Col sm="6" className="expires FormGroup">
                                        <Label htmlFor="text-input">Expires On</Label>
                                        <Input type="text" id="text-input" name="expiresOn" value={this.state.expiresOn} readOnly />
                                    </Col>
                                </Row>
                                <Button className={'mr-2 remove remove__btn'} onClick={this.removeStream} disabled={this.state.isActive === false ? true : false}> Remove</Button>
                                <Button className={'mr-2 add add__btn'} onClick={() => this.addToken()} disabled={this.state.isActive === false ? true : false}> Add</Button>
                                <Link to={`${process.env.PUBLIC_URL}/broadcast/${this.props.match.params.id}/${this.state.fields.streamName}`} className={(this.state.isActive === true) ? "btn btn-twitter m-1 broadcast-btn broadcast__btn" : "disable-link"}>Broadcast</Link>
                            </div>
                        </Col>
                        </Row>

                        <Modal className='modal-lg mr_10 add-streams' isOpen={this.state.modal} backdrop={'static'}>
                            <ModalHeader>Add Publish Token</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col sm="12">
                                        <Col md="12">
                                            <Label htmlFor="text-input">Add Stream Name/s</Label>
                                        </Col>
                                        <Col xs="12" md="12">
                                            <Input type="number" name="stream" id="stream" onChange={e => this.getTokenStreams(e)} min="1" max="100" />
                                        </Col>
                                    </Col>
                                </Row>
                                <Row className="add-stream">
                                    <Col sm="12">
                                        <Button color="primary" onClick={() => this.addTokenStreams()}>Submit</Button>
                                        <Button className="cancel ml-2" variant="danger" onClick={() => this.modelClose()}>Cancel</Button>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                    </div>}
            </>
        );
    }
}

export default EditTokenForm;
