import React, { Component } from 'react';
import { Container, Nav, Navbar, NavDropdown, Image } from 'react-bootstrap';
import logo from './../assets/images/site_logo.png';
import apiUrl from '../constants';
import { Link } from 'react-router-dom';
import StorageService from './../storage/StorageService';
import { connect } from 'react-redux';
import history from './../helper/history';
import { userService } from './../services';
import { toast } from 'react-toastify';

class SiteHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: '',
            logo: '',
        }

        this.logoutUser = this.logoutUser.bind(this);
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        if (StorageService.getLogin() !== null) {
            this.getTeamData();
        }
    }

    // Get Team details
    getTeamData = async () => {
        try {
            const { currentUser } = this.props;
            if (currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin' || currentUser.currentUser === 'broadcaster') {
                this.setState({ color: '#fff8d4' });
            } else {
                const data = await userService.httpApi('GET', `team/userdetails/${currentUser.id}`, null);
                if (data.length > 0) {
                    this.setState({
                        color: data[0].headerColor,
                        logo: data[0].logo,
                    });
                }
            }
        } catch (error) {
            toast.warn(error.message);
        }
    }

    // For User Logout 
    logoutUser = () => {
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${StorageService.getLogin().token}`,
            },
        };
        return fetch(`${apiUrl}logout/${StorageService.getLogin().profile.userId}`, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    StorageService.clearLogin();
                    history.push(`${process.env.PUBLIC_URL}/login`);
                    return;
                } else {
                    StorageService.clearLogin();
                    history.push(`${process.env.PUBLIC_URL}/login`);
                    return;
                }
            })
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        const { logedIn } = this.props;
        const { currentUser } = this.props;

        return (
            <>
                <Navbar collapseOnSelect expand="lg" className={(logedIn === true) ? 'site-header' : 'site-header headerLogout'} style={{ backgroundColor: this.state.color }}>
                    <Container>
                        <Navbar.Brand ><Link to={`${process.env.PUBLIC_URL}/teams`}>{currentUser.currentUser === 'superAdmin' ? <Image className="site-logo" src={logo}></Image> : <Image className="site-logo" src={this.state.logo === '' ? logo : apiUrl + this.state.logo}></Image>}</Link></Navbar.Brand>
                        <div className="mobile_toggle">
                        {(logedIn === true && (currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin' || currentUser.currentUser === 'broadcaster')) ?
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" /> : '' }
                            <NavDropdown className="mobile_view" title={(StorageService.getLogin() !== null) ? StorageService.getLogin().profile.firstName + ' ' + StorageService.getLogin().profile.lastName : ''} id="basic-nav-dropdown">
                                {(logedIn === true) ? <NavDropdown.Item href={`${process.env.PUBLIC_URL}/profile/${currentUser.id}`}>Edit Profile
                            </NavDropdown.Item> : ''}
                                {(logedIn === true) ? <NavDropdown.Item onClick={this.logoutUser}>Logout
                            </NavDropdown.Item> : ''}
                            </NavDropdown>
                        </div>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="desktop_view">
                                {(logedIn === true && (currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin' || currentUser.currentUser === 'broadcaster'))  ?
                                    <NavDropdown title="Users" id="basic-nav-dropdown">
                                      {(logedIn === true && (currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin'  ))  ?  <NavDropdown.Item href={`${process.env.PUBLIC_URL}/users`}>All Users
                            </NavDropdown.Item>: ''}
                                        <NavDropdown.Item href={`${process.env.PUBLIC_URL}/teams`}>Teams
                            </NavDropdown.Item>
                                    </NavDropdown> : ''}
                            </Nav>
                            <Nav className="desktop_view">
                                {(logedIn === true && (currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin'   )) ?
                                    <NavDropdown title="Tokens" id="basic-nav-dropdown">
                                        <NavDropdown.Item href={`${process.env.PUBLIC_URL}/tokens`}>All Tokens
                            </NavDropdown.Item>
                                    </NavDropdown> : ''}
                            </Nav>
                            <Nav className="desktop_view">
                                {(logedIn === true && (currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin' )) ?
                                    <NavDropdown title="Projects" id="basic-nav-dropdown">
                                        <NavDropdown.Item href={`${process.env.PUBLIC_URL}/projects`}>All Projects
                            </NavDropdown.Item>
                                        <NavDropdown.Item href={`${process.env.PUBLIC_URL}/project-details`}>Project Details
                            </NavDropdown.Item>
                                    </NavDropdown> : ''}
                            </Nav>
                            <Nav className="desktop_view">
                                {(logedIn === true && (currentUser.currentUser === 'superAdmin')) ?
                                    <NavDropdown title="Settings" id="basic-nav-dropdown">
                                        <NavDropdown.Item href={`${process.env.PUBLIC_URL}/terms-conditions`}>Terms & Conditions
                            </NavDropdown.Item>
                                        <NavDropdown.Item href={`${process.env.PUBLIC_URL}/tfa-teams-list`}>TFA
                            </NavDropdown.Item>
                                    </NavDropdown> : ''}
                            </Nav>
                            <Nav className="desktop_view">
                                {(logedIn === true && (currentUser.currentUser === 'superAdmin')) ?
                                    <NavDropdown title="Logs" id="basic-nav-dropdown">
                                        <NavDropdown.Item href={`${process.env.PUBLIC_URL}/logs`}>Activity Logs
                            </NavDropdown.Item>
                                        <NavDropdown.Item href={`${process.env.PUBLIC_URL}/active-users`}>Active Stream Users
                            </NavDropdown.Item>
                                    </NavDropdown> : ''}
                            </Nav>
                            <Nav className="mr-auto">
                                {/* {(logedIn === true && (currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin')) ?<NavLink className="header-link" to={`${process.env.PUBLIC_URL}/tokens`} activeClassName="active">Tokens</NavLink> : ''} */}
                            </Nav>
                            {(logedIn === true) ?
                                <Nav className={(currentUser.currentUser === 'superAdmin') ? 'super-profile' : (currentUser.currentUser === 'admin') ? 'admin-profile' : (currentUser.currentUser === 'broadcaster') ? 'broadcaster-profile' : 'viewer-profile'}>{currentUser.currentUser === 'superAdmin' ? 'Super Admin' : currentUser.currentUser.charAt(0).toUpperCase() + currentUser.currentUser.slice(1)}</Nav>
                                : ''}
                            {(logedIn === true) ?
                                <Nav className="desktop_view">
                                    <NavDropdown title={(StorageService.getLogin() !== null) ? StorageService.getLogin().profile.firstName + ' ' + StorageService.getLogin().profile.lastName : ''} id="basic-nav-dropdown">
                                        {(logedIn === true) ? <NavDropdown.Item href={`${process.env.PUBLIC_URL}/profile/${currentUser.id}`}>Edit Profile</NavDropdown.Item> : ''}
                                        {(logedIn === true) ? <NavDropdown.Item onClick={this.logoutUser}>Logout</NavDropdown.Item> : ''}
                                    </NavDropdown>
                                </Nav> : ''}
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </>
        );
    }

}

// Wrapper component will subscribe to Redux store updates
// If you don't want to subscribe to store updates, pass null or undefined in place of mapStateToProps
function mapStateToProps(state) {
    const { logedIn } = state.isLogged;
    const { currentUser } = state;
    return {
        logedIn,
        currentUser,
    };
}

// The return of connect() is a wrapper function that takes your component 
// and returns a wrapper component with the additional props it injects
export default connect(mapStateToProps)(SiteHeader);

