import React, { Component } from 'react';
import SiteHeader from './SiteHeader';
import StorageService from './../storage/StorageService';
import { userService } from './../services';
import { toast } from 'react-toastify';
import MUIDataTable from "mui-datatables";
import Loader from 'react-loader-spinner'

class ProjectDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [],
            rows: [],
            options: {},
            userData: [],
            loading: true
        }
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = "Project Details | SetlinkLive";
        this.setState({
            columns: [
                {
                    name: "sno",
                    label: "S No.",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
                {
                    name: "username",
                    label: "Username",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "email",
                    label: "Email",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "projectName",
                    label: "Project Name",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
            ],
        });
        this.loader();
        this.getProjectDetails();
    }

    /**
    * @loader - data loading process
    */
    loader = () => {
        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 4000);
    }

    // Get Project Details
    getProjectDetails = async () => {
        try {
            let rows = []
            const data = await userService.httpApi('GET', 'admin/projects/details', null);
           
            data.map((x, index) =>
                rows.push({
                    sno: index + 1,
                    _id: x._id,
                    username: x.firstName.charAt(0).toUpperCase() + x.firstName.slice(1) + ' ' + x.lastName.charAt(0).toUpperCase() + x.lastName.slice(1),
                    email: x.email,
                    projectName: x.projectName.charAt(0).toUpperCase() + x.projectName.slice(1),
                })
            )
            this.setState({ rows: rows });
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                StorageService.clearLogin();
            } else if (error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else {
                toast.warn(error);
            }
        }
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        const options = {
            rowsPerPage: 10,
            rowsPerPageOptions: [10,15,20],
            filter: true,
            filterType: 'dropdown',
            serverSide: false,
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: "none",
            textLabels: {
                body: {
                    noMatch: this.state.loading ?
                        <Loader
                            type="Oval"
                            color="#00BFFF"
                            height={30}
                            width={30}
                        />
                    :
                        'Sorry, there is no matching data to display',
                },
            }
        }
        return (
            <>
                <SiteHeader />
                <div className="container main my-4 my-md-5 common-table">
                    <div className="page_wrapper mui-dataTables">
                        <MUIDataTable
                            title={"Project Details"}
                            data={this.state.rows}
                            columns={this.state.columns}
                            options={options}
                            />  
                    </div>
                </div>
            </>
        );
    }
}

export default ProjectDetail;