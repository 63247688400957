import React, { Component } from 'react';
import { Row, Button, Col } from 'react-bootstrap';
import SiteHeader from './SiteHeader';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Input, Label, FormGroup, Form } from 'reactstrap';
import StorageService from './../storage/StorageService';
import { userService } from './../services';
import { toast } from 'react-toastify';
import { MDBInput } from 'mdbreact';
import MUIDataTable from "mui-datatables";
import Loader from 'react-loader-spinner'

class ViewProjectToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            rows: [],
            fields1: {
                subject: '',
                message: '',
            },
            modal1: false,
            errors1: {},
            loading: true
            
        }
        this.handleChangeModal = this.handleChangeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = "Tokens | SetlinkLive";
        const { currentUser } = this.props;
        if (currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin') {
            this.setState({
                columns: [
                    {
                        name: "sno",
                        label: "S No.",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "enable",
                        label: "Status",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "label",
                        label: "Token Label",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "streams",
                        label: "Streams",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "addedOn",
                        label: "Added On",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "expiresOn",
                        label: "Expires On",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "broadcast",
                        label: "Broadcast",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "share",
                        label: "Share",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                ]
            })
        } else if (currentUser.currentUser === 'broadcaster') {
            this.setState({
                columns: [
                    {
                        name: "sno",
                        label: "S No.",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "label",
                        label: "Token Label",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "streams",
                        label: "Streams",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "addedOn",
                        label: "Added On",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "expiresOn",
                        label: "Expires On",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "broadcast",
                        label: "Broadcast",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "share",
                        label: "Share",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                ]
            })
        } else if (currentUser.currentUser === 'viewer') {
            this.setState({
                columns: [
                    {
                        name: "sno",
                        label: "S No.",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "label",
                        label: "Token Label",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "share",
                        label: "Share",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "streams",
                        label: "Streams",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "addedOn",
                        label: "Added On",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "expiresOn",
                        label: "Expires On",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                ]
            })
        }
        this.loader();
        this.getPublishTokens();
    }

    /**
    * @loader - data loading process
    */
    loader = () => {
        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 4000);
    }

    // Get Publish Tokens list
    getPublishTokens = async () => {
        try {
            let rows = [];
            const data = await userService.httpApi('GET', `project/tokens/${this.props.match.params.id}`, null);
            data.map((y, index) => y.tokens.forEach(x =>{
                rows.push({
                    sno: index + 1,
                    id: x._id,
                    enable: (x.enable === true) ? <MDBInput label=" " onChange={() => this.handleChangeBtnSwitch(false, x)} type="checkbox" className="switch-checkbox" checked={true} id={`switch${x._id}`} disabled={x.isActive === false ? true : false} /> : <MDBInput label=" " type="checkbox" className="switch-checkbox" onChange={() => this.handleChangeBtnSwitch(true, x)} checked={false} id={`switch${x._id}`} disabled={x.isActive === false ? true : false} />,
                    label: <span className={x.isActive === false ? 'strike' : ''}>{x.label}</span>,
                    streams: (x.streams.length > 1) ? <span className={x.isActive === false ? 'strike' : ''}>{(x.streams[0].streamName + ',...')}</span> : <span className={x.isActive === false ? 'strike' : ''}>{x.streams.map(y => y.streamName).join(',')}</span>,
                    addedOn: (x.addedOn === 0) ? '-' : new Date(x.addedOn).toLocaleDateString("en-US"),
                    expiresOn: (x.expiresOn === 0) ? '-' : new Date(x.expiresOn).toLocaleDateString("en-US"),
                    broadcast: <> <Link to={`${process.env.PUBLIC_URL}/broadcast/${x._id}/${x.streams[0].streamName}`} className={(x.isActive === true && x.enable === true) ? "btn-sm btn btn-twitter m-1" : "disable-link"}>Broadcast</Link></>,
                    share: <> <Link to={`${process.env.PUBLIC_URL}/viewer/${this.props.match.params.teamId}/${x._id}/${x.streams[0].streamName}`} target={'_blank'} className={(x.isActive === true && x.enable === true) ? "btn-sm btn btn-twitter m-1" : "disable-link"} onClick={this.clickViewStream.bind(this)}>View Stream</Link></>,
                })
            }))
            this.setState({ rows: rows });
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else if (error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
            }
        }
    }

    // View Live streaming on click
    clickViewStream = async () => {
        const { currentUser } = this.props;
        try {
            const getData = await userService.httpApi('GET', `get/project/token/${this.props.match.params.id}`, null);
            const data = {
                userId: currentUser.id,
                tokenId: getData.tokenId,
                viewStream: true,
            };
            await userService.httpApi('POST', `view/streams`, JSON.stringify(data));
        } catch (error) {
            toast.warn(error);
        }
    }

    // Slide Button's 
    handleChangeBtnSwitch = async (value, data) => {
        try {
            let tokenData = this.state.rows;
            const index = tokenData.findIndex(x => x.id === data._id);
            if (index > -1) {
                tokenData[index].enable = value;
                const id = tokenData[index].id;
                this.setState({ rows: tokenData });
                await userService.httpApi('PATCH', `publish/token/status/${id}`, JSON.stringify({ enable: value }));
                toast.success('Token status updated successfully');
                this.getPublishTokens()
            }
        } catch (error) {
            toast.warn(error.message);
        }
    }

    // Send email
    sendUsersEmail() {
        this.setState({ fields1: {}, modal1: true });
    }

    // Close modal
    modelClose = () => {
        this.setState({ fields1: {}, errors1: {} });
        this.setState({ modal1: false });
    }

    // Bind input data on event onChange
    handleChangeModal = (field, e) => {
        let fields1 = this.state.fields1;
        fields1[field] = e.target.value;
        this.setState({ fields1 });
    }

    // Check input Validations
    handleValidation = () => {
        let errors1 = {};
        let formIsValid = true;
        if (this.state.fields1.subject.trim().length === 0) {
            formIsValid = false;
            errors1["subject"] = 'Subject is required.';
        }
        this.setState({ errors1: errors1 });
        return formIsValid;
    }

    // Submit form data
    handleSubmit = async (e) => {
        e.preventDefault();
        const { currentUser } = this.props;
        try {
            if (this.handleValidation()) {
                const data = {
                    userId: currentUser.id,
                    subject: this.state.fields1.subject,
                    message: this.state.fields1.message,
                }
                await userService.httpApi('POST', `send/support/email`, JSON.stringify(data));
                toast.success('Email sent successfully');
                this.setState({ modal1: false });
            }
            
        } catch (error) {
            toast.warn(error);
        }
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        const { currentUser } = this.props;
        const options = {
            rowsPerPage: 10,
            rowsPerPageOptions: [10,15,20],
            filter: true,
            filterType: 'dropdown',
            serverSide: false,
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: "none",
            textLabels: {
                body: {
                    noMatch: this.state.loading ?
                        <Loader
                            type="Oval"
                            color="#00BFFF"
                            height={30}
                            width={30}
                        />
                    :
                        'Sorry, there is no matching data to display',
                },
            }
        }
        return (
            <>
                <SiteHeader />
                <div className="main container my-4 my-md-5">
                    <div className="page_wrapper mui-dataTables">
                        <Row className="justify-content">
                            <Col xs lg="12 mb-4"><Link to={(currentUser.currentUser === 'superAdmin') ? `${process.env.PUBLIC_URL}/view-team-projects/${this.props.match.params.teamId}` : (currentUser.currentUser === 'admin') ? `${process.env.PUBLIC_URL}/teams` : `${process.env.PUBLIC_URL}/teams`} className={'back_btn'}><i className="fa fa-long-arrow-left"></i> Back</Link>{currentUser.currentUser === 'viewer' ? <Button className="add-token btn btn-outline-secondary float-right" onClick={() => this.sendUsersEmail()}>Send Email</Button> : ''}</Col>
                            
                        </Row>
                        <MUIDataTable
                            title={"Tokens"}
                            data={this.state.rows}
                            columns={this.state.columns}
                            options={options}
                            /> 

                        <Modal className='modal-lg mr_10' isOpen={this.state.modal1} backdrop={'static'}>
                            <Form onSubmit={this.handleSubmit}>
                                {(currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin') ?
                                    <ModalHeader>Signup Invitation Email</ModalHeader> :
                                    <ModalHeader>Support Email</ModalHeader>}
                                <ModalBody>
                                    <Row>
                                        <Col sm="12" className="FormGroup">
                                            <Label htmlFor="text-input">Subject</Label>
                                            <Input type="text" name="subject" value={this.state.fields1["subject"] || ''} id="subject" placeholder="Subject" onChange={this.handleChangeModal.bind(this, "subject")} />
                                            <div className={(this.state.errors1['subject'] !== void 0 && this.state.errors1['subject'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors1["subject"]}</div>
                                        </Col>
                                        <Col sm="12" className="FormGroup">
                                            <Label htmlFor="text-input">Message</Label>
                                            <Input type="textarea" name="message" value={this.state.fields1["message"] || ''} id="message" rows="10" cols="70" placeholder="Message" onChange={this.handleChangeModal.bind(this, "message")} />
                                            <div className={(this.state.errors1['message'] !== void 0 && this.state.errors1['message'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors1["message"]}</div>
                                        </Col>
                                        <Col sm="12">
                                            <FormGroup className='submitbtn'>
                                                <Button color="primary" type='submit'>Submit</Button>
                                                <Button className="cancel ml-2" variant="danger" onClick={() => this.modelClose()}>Cancel</Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </ModalBody>
                            </Form>
                        </Modal>
                    </div>
                </div>
            </>
        );
    }
}

// Wrapper component will subscribe to Redux store updates
// If you don't want to subscribe to store updates, pass null or undefined in place of mapStateToProps
function mapStateToProps(state) {
    const { logedIn } = state.isLogged;
    const { currentUser } = state;
    return {
        logedIn,
        currentUser,
    };
}

// The return of connect() is a wrapper function that takes your component 
// and returns a wrapper component with the additional props it injects
export default connect(mapStateToProps)(ViewProjectToken);


