import React, { Component } from 'react';
import {
    Col,
    Row,
    Form,
    Input,
    Button,
} from 'reactstrap';
import apiUrl from './../constants';
import SiteHeader from './SiteHeader';
import StorageService from './../storage/StorageService';
import { toast } from 'react-toastify';
import QRCode from "react-qr-code";

class Tfa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                authCode: '',
            },
            errors: {},
            qrCode: '',
        }
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = "Account Settings | SetlinkLive";
        this.getAuth();
    }

    // Get Authentications
    getAuth = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return fetch(`${apiUrl}users/auth/${this.props.match.params.email}`, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                    return;
                }
            })
            .then(data => {
                this.setState({ qrCode: data.authUri });
            });
    }

    // Bind input data on event onChange
    handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    // Check input Validations
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (fields["authCode"].trim().length === 0) {
            formIsValid = false;
            errors["authCode"] = "Auth Code cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    // Check Token Authenticate
    verifyToken = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                let data = this.state.fields;
                delete data._id;

                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                };
                return fetch(`${apiUrl}users/auth/${this.props.match.params.email}`, requestOptions)
                    .then(response => response.json())
                    .then((json) => {
                        if (json.flag === "success") {
                            StorageService.setLogin({
                                flag: json.flag,
                                token: json.token,
                                profile: {
                                    firstName: json.profile.firstName,
                                    lastName: json.profile.lastName,
                                    email: json.profile.email,
                                    phone: json.profile.phone,
                                    userType: json.profile.userType,
                                    status: json.profile.status,
                                    qrCode: json.profile.qrCode,
                                    termsPopup: json.profile.termsPopup,
                                    agreeTermsPopup: json.profile.agreeTermsPopup,
                                    loginDurationInMinutes: json.profile.loginDurationInMinutes,
                                    userId: json.profile.userId,
                                }
                            });
                            toast.success('Logged In Successfully!');
                            this.props.history.push(`${process.env.PUBLIC_URL}/teams`);
                        } else {
                            toast.warn(json.message);
                        }
                    }
                    )
            }
        } catch (error) {
            toast.warn(error);
        }
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        return (
            <>
                <SiteHeader />
                <div className="container my-4 my-md-5">
                    <Row className="justify-content-md-center two-factor-auth">
                        <Col xs="12" md="12" lg="6">
                            <div className="page_wrapper">
                                <h2 className="text-center login_heading">Enter the Auth Code to verify</h2>
                                <div className="mb-4 text-center">
                                    <p className="login-box-msg">Scan the QR code for Authentication</p>
                                    {this.state.qrCode !== '' ? <div className="qr-code">
                                        <QRCode value={this.state.qrCode} />
                                    </div> : ''}
                                </div>
                                <Form onSubmit={this.verifyToken.bind(this)} className="form-horizontal">
                                    <Row className="justify-content-md-center">
                                        <Col xs="12" md="12" lg="12" className="FormGroup">
                                            <Input type="text" id="text-input" name="authCode" placeholder="Auth Code" value={this.state.fields["authCode"]} onChange={this.handleChange.bind(this, "authCode")} />
                                            <div className={(this.state.errors['authCode'] !== void 0 && this.state.errors['authCode'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["authCode"]}</div>
                                        </Col>
                                        <Col xs="12" md="12" lg="12">
                                            <Button type="submit" color="primary" className={'mr-2'}><i className="fa fa-dot-circle-o"></i> Submit</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default Tfa;