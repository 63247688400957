import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SiteHeader from './SiteHeader';
import { connect } from 'react-redux';
import StorageService from './../storage/StorageService';
import { userService } from '../services';
import { toast } from 'react-toastify';
import MUIDataTable from "mui-datatables";
import Loader from 'react-loader-spinner'

class ViewTeamProjects extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [],
            rows: [],
            userData: [],
            teamName: '',
            loading: true
        }
    }
    
    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = "Team Details | SetlinkLive";
        const { currentUser } = this.props;
        if(currentUser.currentUser === 'superAdmin') {
            this.setState({
                columns: [
                    {
                        name: "sno",
                        label: "S No.",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "projectName",
                        label: "Project Name",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "status",
                        label: "Status",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "publishToken",
                        label: "Publish Token",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "link",
                        label: "Signup Link",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                ]
            });
        } else if(currentUser.currentUser === 'admin'){
            this.setState({
                columns: [
                    {
                        name: "sno",
                        label: "S No.",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "projectName",
                        label: "Project Name",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "status",
                        label: "Status",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "publishToken",
                        label: "Publish Token",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "link",
                        label: "Signup Link",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                ]
            });
        } else {
            this.setState({
                columns: [
                    {
                        name: "sno",
                        label: "S No.",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "projectName",
                        label: "Project Name",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "publishToken",
                        label: "Publish Token",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                ]
            });
        }
        this.loader();
        this.getTeamData();
    }

    /**
    * @loader - data loading process
    */
    loader = () => {
        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 4000);
    }

    // Copy URL on clipboard
    copyToClipboard(e, txt) {
        e.preventDefault();
        const textField = document.createElement('textarea');
        textField.innerText = txt;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        toast.success('Link Copied');
    }

    // Get Team details
    getTeamData = async () => {
        try {
            let rows = []
            const data = await userService.httpApi('GET', `team/projects/${this.props.match.params.id}`, null);
            this.setState({
                teamName: data[0].teamName
            })
            data.map((x, index) => 
            rows.push({ 
                sno: index + 1,
                _id: x._id,
                tokenId: x.tokenId,
                projectName: x.projectName,
                status: (x.status === true) ? 'Enabled' : 'Disabled',
                publishToken: <Link to={`${process.env.PUBLIC_URL}/view-project-tokens/${this.props.match.params.id}/${x.projectId}`} className="btn-sm btn btn-twitter m-1">Tokens</Link>,
                link: <Button onClick={(e) => this.copyToClipboard(e, `http://${window.location.host}/signup/${x.projectId}`)}>Copy</Button>,
            })
          )
          this.setState({ rows: rows }); 
        } catch (error) {
            if(error === 'Token Expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
              } else if(error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
              } else {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
              }   
        }
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        const options = {
            rowsPerPage: 10,
            rowsPerPageOptions: [10,15,20],
            filter: true,
            filterType: 'dropdown',
            serverSide: false,
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: "none",
            textLabels: {
                body: {
                    noMatch: this.state.loading ?
                        <Loader
                            type="Oval"
                            color="#00BFFF"
                            height={30}
                            width={30}
                        />
                    :
                        'Sorry, there is no matching data to display',
                },
            }
        }
        return (
            <>
                <SiteHeader />
                <div className="main container my-4 my-md-5">
                <div className="page_wrapper mui-dataTables">
                    <Row className="justify-content">
                        <Col xs lg="12 mb-4"><Link to={`${process.env.PUBLIC_URL}/teams`} className={'back_btn'}><i className="fa fa-long-arrow-left"></i> Back</Link></Col>
                    </Row>
                    <MUIDataTable
                        title={this.state.teamName+' : Projects'}
                        data={this.state.rows}
                        columns={this.state.columns}
                        options={options}
                        /> 
                    </div>
                </div> 
            </>
        );
    }
}

// Wrapper component will subscribe to Redux store updates
// If you don't want to subscribe to store updates, pass null or undefined in place of mapStateToProps
function mapStateToProps(state) {
    const { logedIn } = state.isLogged;
    const { currentUser } = state;
    return {
        logedIn,
        currentUser,
    };
}

// The return of connect() is a wrapper function that takes your component 
// and returns a wrapper component with the additional props it injects
export default connect(mapStateToProps)(ViewTeamProjects);