import React, { Component } from 'react';
import SiteHeader from './SiteHeader';
import { userService } from './../services';
import { toast } from 'react-toastify';
import StorageService from './../storage/StorageService';
import { Button, Row, Col, Label, Input, FormGroup, Form, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swal from 'sweetalert2';
import MUIDataTable from "mui-datatables";
import Loader from 'react-loader-spinner'

class TfaTeamsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            rows: [],
            list: [],
            errors: {},
            teamName: '',
            teamId: '',
            modal: false,
            data: {},
            loginModal: false,
            fields: {
                authType: '',
            },
            fields1: {
                loginDuration: '1',
            },
            options: {},
            userData: [],
            loading: true
        }
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = "Teams | SetlinkLive";
        this.setState({
            columns: [
                {
                    name: "sno",
                    label: "S No.",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "team",
                    label: "Team Name",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "check",
                    label: "Tfa",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
                {
                    name: "popup",
                    label: "Terms and Conditions",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
                {
                    name: "login",
                    label: "Login Duration",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
                {
                    name: "loginCount",
                    label: "Multiple Login",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
            ],
        })
        this.loader();
        this.getTeams();
    }

    /**
    * @loader - data loading process
    */
    loader = () => {
        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 4000);
    }

    // Remove Selected Team
    deleteTeam(id) {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: 'You will not be able to recover this team!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it'
            }).then(async (result) => {
                if (result.value) {
                    const teamData = this.state.rows;
                    const index = teamData.findIndex(x => x._id === id);
                    if (index > -1) {
                        await userService.httpApi('DELETE', `admin/teams/${id}`, null);
                        teamData.splice(index, 1);
                        this.setState({ rows: teamData });
                        this.getTeams();
                        Swal.fire(
                            'Deleted!',
                            'Team has been deleted.',
                            'success'
                        )
                    }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                        'Cancelled',
                        'team is safe :)',
                        'error'
                    )
                }
            })
        } catch (error) {
            toast.warn(error.message);
        }
    }

    // Get Teams list
    getTeams = async () => {
        try {
            const data = await userService.httpApi('GET', 'admin/teams', null);
            this.drawRows(data);
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else if (error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
            }
        }
    }

    // Create Table rows
    drawRows(data) {
        let rows = [];
        data.map((x, index) =>
            rows.push({
                sno: index + 1,
                _id: x._id,
                team: x.teamName,
                check: (x.qrCode === false) ? <Button className="btn-sm btn-disable" onClick={() => this.addAuth(x._id, x.teamName)}>Disable</Button> : <Button className="btn-sm btn-enable" onClick={() => this.disableAuth(x._id)}>Enable</Button>,
                popup: (x.termsPopup === false) ? <Button className="btn-sm btn-disable" onClick={() => this.enablePopup(x._id, x.teamName)}>Disable</Button> : <Button className="btn-sm btn-enable" onClick={() => this.disablePopup(x._id)}>Enable</Button>,
                login: (x.loginDuration === false) ? <Button className="btn-sm btn-disable" onClick={() => this.addLoginLimit(x._id, x.teamName)}>Disable</Button> : <Button className="btn-sm btn-enable" onClick={() => this.disableLoginLimit(x._id)}>Enable</Button>,
                loginCount: (x.multipleLogin === false) ? <Button className="btn-sm btn-disable" onClick={() => this.enableMultiLogin(x._id)}>Disable</Button> : <Button className="btn-sm btn-enable" onClick={() => this.disableMultipleLogin(x._id)}>Enable</Button>,
            })
        )
        this.setState({ rows: rows });
    }

    // Check input Validations
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        if (this.state.fields.authType.trim().length === 0) {
            formIsValid = false;
            errors["authType"] = 'Auth type is required';
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    // Bind input data on event onChange
    handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    // Bind input data on event onChange
    handleChangeLoginForm = (field1, e) => {
        let fields1 = this.state.fields1;
        fields1[field1] = e.target.value;
        this.setState({ fields1 });
    }

    // Add Authentication
    addAuth = (teamId, teamName) => {
        this.setState({ teamId: teamId, teamName: teamName });
        this.setState({ fields: {} });
        this.setState({ modal: true });
    }

    // Disable Authentication
    disableAuth = async (id) => {
        await userService.httpApi('PATCH', `disable/tfa/${id}`, JSON.stringify({
            qrCode: false,
            authType: '',
        }));
        toast.success('TFA disabled successfully');
        this.getTeams();
    }

    // Enable Popup Window
    enablePopup = async (id) => {
        await userService.httpApi('PATCH', `teams/popup/${id}`, JSON.stringify({
            termsPopup: true,
        }));
        toast.success('Popup enabled successfully');
        this.getTeams();
    }

    // Disable Popup Window
    disablePopup = async (id) => {
        await userService.httpApi('PATCH', `disable/popup/${id}`, JSON.stringify({
            termsPopup: false,
        }));
        toast.success('Popup disabled successfully');
        this.getTeams();
    }

    /**
    *
    *@handleSubmit - its call when you submit the form
    */
    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                await userService.httpApi('PATCH', `teams/tfa/${this.state.teamId}`, JSON.stringify({
                    qrCode: true,
                    authType: this.state.fields.authType,
                }));
                toast.success('Team status updated successfully');
                this.modelClose();
                this.getTeams();
            }
        } catch (error) {
            this.modelClose();
            toast.warn(error);
        }
    }

    // Set limit for login
    addLoginLimit = (teamId, teamName) => {
        this.setState({ teamId: teamId, teamName: teamName });
        this.setState({
            fields1: {
                loginDuration: '1',
            }
        });
        this.setState({ loginModal: true });
    }

    /**
    *
    *@handleSubmitLoginDurationForm - its call when you submit the form
    */
    handleSubmitLoginDurationForm = async (e) => {
        e.preventDefault();
        try {
            await userService.httpApi('PATCH', `enable/loginDuration/${this.state.teamId}`, JSON.stringify({
                loginDuration: true,
                loginDurationInMinutes: Number(this.state.fields1.loginDuration),
            }));
            toast.success('Login Duration added successfully');
            this.modalClose();
            this.getTeams();
        } catch (error) {
            this.modalClose();
            toast.warn(error);
        }
    }

    // Modal Close
    modelClose() {
        this.setState({ fields: {} });
        this.setState({ modal: false });
    }

    // For Enable Multiple Login at a time
    enableMultiLogin = async (teamId) => {
        await userService.httpApi('PATCH', `set/multiLogin/${teamId}`, JSON.stringify({
            multipleLogin: true,
        }));
        toast.success('Multiple Login enabled successfully');
        this.getTeams();
    }

    // Disable limit for login
    disableLoginLimit = async (id) => {
        await userService.httpApi('PATCH', `disable/loginDuration/${id}`, JSON.stringify({
            multipleLogin: false,
        }));
        toast.success('Login Duration removed successfully');
        this.getTeams();
    }

    // Modal Close
    modalClose() {
        this.setState({
            fields1: {
                loginDuration: '1',
            }
        });
        this.setState({ loginModal: false });
    }

    /**
    *
    *@handleSubmitLoginCountForm - it is called when you submit the form
    */
    handleSubmitLoginCountForm = async (e) => {
        e.preventDefault();
        try {
            await userService.httpApi('PATCH', `set/loginCount/${this.state.teamId}`, JSON.stringify({
                multipleLogin: true,
                loginCount: Number(this.state.fields2.loginCount),
            }));
            toast.success('Multiple login enabled successfully');
            this.loginCountModalClose();
            this.getTeams();
        } catch (error) {
            this.loginCountModalClose();
            toast.warn(error);
        }
    }

    // For Disable Multiple Login at a time
    disableMultipleLogin = async (id) => {
        await userService.httpApi('PATCH', `disable/loginCount/${id}`, JSON.stringify({
            loginCount: false,
        }));
        toast.success('Multiple Login disabled successfully');
        this.getTeams();
    }

    // Modal for Login count
    loginCountModalClose() {
        this.setState({
            fields2: {
                loginCount: 1,
            }
        });
        this.setState({ loginCountModal: false });
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        const options = {
            rowsPerPage: 10,
            rowsPerPageOptions: [10,15,20],
            filter: true,
            filterType: 'dropdown',
            serverSide: false,
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: "none",
            textLabels: {
                body: {
                    noMatch: this.state.loading ?
                        <Loader
                            type="Oval"
                            color="#00BFFF"
                            height={30}
                            width={30}
                        />
                    :
                        'Sorry, there is no matching data to display',
                },
            }
        }
        return (
            <>
                <SiteHeader />
                <div className="main container my-4 my-md-5 common-table">
                    <div className="page_wrapper mui-dataTables">
                        <MUIDataTable
                            title={"TFA"}
                            data={this.state.rows}
                            columns={this.state.columns}
                            options={options}
                            />

                        <Modal className='modal-lg mr_10' isOpen={this.state.modal} backdrop={'static'}>
                            <Form onSubmit={this.handleSubmit}>
                                <ModalHeader>Select Auth Type</ModalHeader>
                                <ModalBody>
                                    <Row>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Team Name</Label>
                                            <Input type="text" name="teamName" value={this.state.teamName} id="teamName" onChange={this.handleChange.bind(this, "authType")} readOnly />
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Auth Type</Label>
                                            <Input type="select" id="select" name="authType" placeholder="Auth Type" value={this.state.fields["authType"]} onChange={this.handleChange.bind(this, "authType")}>
                                                <option value="">Please Select</option>
                                                <option value="sms">SMS</option>
                                                <option value="app">APP</option>
                                            </Input>
                                            <div className={(this.state.errors['authType'] !== void 0 && this.state.errors['authType'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors["authType"]}</div>
                                        </Col>
                                        <Col sm="12">
                                            <FormGroup className='submitbtn'>
                                                <Button color="primary" type='submit'>Submit</Button>
                                                <Button className="cancel ml-2" variant="danger" onClick={() => this.modelClose()}>Cancel</Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </ModalBody>
                            </Form>
                        </Modal>

                        <Modal className='modal-lg mr_10' isOpen={this.state.loginModal} backdrop={'static'}>
                            <Form onSubmit={this.handleSubmitLoginDurationForm}>
                                <ModalHeader>Set Login Duration</ModalHeader>
                                <ModalBody>
                                    <Row>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Team Name</Label>
                                            <Input type="text" name="teamName" value={this.state.teamName} id="teamName" readOnly />
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Login Duration (Minutes)</Label>
                                            <Input type="number" name="loginDuration" placeholder="Please Enter Login Duration in Minutes" id="loginDuration" min="1" value={this.state.fields1["loginDuration"]} onChange={this.handleChangeLoginForm.bind(this, "loginDuration")} />
                                        </Col>
                                        <Col sm="12">
                                            <FormGroup className='submitbtn'>
                                                <Button color="primary" type='submit'>Submit</Button>
                                                <Button className="cancel ml-2" variant="danger" onClick={() => this.modalClose()}>Cancel</Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </ModalBody>
                            </Form>
                        </Modal>

                    </div>
                </div>
            </>
        );
    }
}

export default TfaTeamsList;


