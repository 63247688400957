export * from './user.constants';

// const apiUrl = 'https://milicastapi.techpss.com/';

const apiUrl = 'https://api.setlink.io/';
//const apiUrl = 'https://devapi.setlink.io/';

// const apiUrl = 'http://192.168.43.18:4010/';

export const logglyKey = 'a03afd71-9111-4e9b-8faa-d9df5b2954ec';
export const millicastPath = 'https://director.millicast.com/api/director/publish';
export const subscribePath = 'https://director.millicast.com/api/director/subscribe';
export const millicastTurnUrl = 'https://turn.millicast.com/webrtc/_turn';
export const accountId = 'xAmaw6';

export default apiUrl;
