import { userConstants } from '../constants';
import { userService } from './../services';
import { alertActions } from './';
import history from './../helper/history';
import StorageService from './../storage/StorageService';

export const userActions = {
    login,
    logout
};

function login(data) {
    return dispatch => {
        dispatch(request({ data }));
          try{
           setTimeout(()=>{
            userService.login(data)
            .then(
                user => { 
                    if(user.flag === "verify") {
                        history.push(`${process.env.PUBLIC_URL}/verify-auth/${data.email}`);
                    } else if (user.flag === "showQrCode") {
                        history.push(`${process.env.PUBLIC_URL}/tfa/${data.email}`);
                    } else if (user.flag === "sms-auth") {
                        history.push(`${process.env.PUBLIC_URL}/sms/${data.email}`);
                    } else {
                        StorageService.setLogin(user);
                        if(user.profile.userType === 'broadcaster' || user.profile.userType === 'viewer') {
                            dispatch(success(user));
                            history.push(`${process.env.PUBLIC_URL}/projects`);
                        } else {
                            dispatch(success(user));
                            history.push(`${process.env.PUBLIC_URL}/teams`);
                        }     
                    }                                  
                },
                error => {
                    dispatch(failure(error));
                    if(error.length){
                        dispatch(alertActions.error(error));
                    }else{
                        dispatch(alertActions.error('Not found'));
                    }                 
                }
            );
           }, 500);
          }catch(err){
            dispatch(failure(err));
            dispatch(alertActions.error(err));
        }
    };
    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}