import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SiteHeader from './SiteHeader';
import StorageService from './../storage/StorageService';
import { userService } from '../services';
import { toast } from 'react-toastify';
import MUIDataTable from "mui-datatables";
import Loader from 'react-loader-spinner'

class ViewTeamAdmins extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [],
            rows: [],
            options: {},
            userData: [],
            teamname: '',
            loading: true
        }
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = "Team Details | SetlinkLive";
        this.setState({
            columns: [
                {
                    name: "sno",
                    label: "S No.",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
                {
                    name: "firstName",
                    label: "Firstname",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "lastName",
                    label: "Lastname",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "email",
                    label: "Email",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "phone",
                    label: "Phone",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "date",
                    label: "Sign Up Date",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "userType",
                    label: "User Type",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
            ],
        });
        this.loader();
        this.getTeamData();
    }

    /**
    * @loader - data loading process
    */
    loader = () => {
        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 4000);
    }

    // Get Team details
    getTeamData = async () => {
        try {
            let rows = []
            const data = await userService.httpApi('GET', `team/admins/${this.props.match.params.id}`, null);
            this.setState({
                teamName: data[0].teamName
            })
            data.map((x, index) =>
                rows.push({
                    sno: index + 1,
                    _id: x._id,
                    firstName: x.firstName,
                    lastName: x.lastName,
                    email: x.email,
                    phone: x.phone,
                    date: new Date(x.created).toLocaleDateString("en-US"),
                    userType: x.userType.charAt(0).toUpperCase() + x.userType.slice(1),
                })
            )
            this.setState({ rows: rows });
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else if (error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
            }
        }
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        const options = {
            rowsPerPage: 10,
            rowsPerPageOptions: [10,15,20],
            filter: true,
            filterType: 'dropdown',
            serverSide: false,
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: "none",
            textLabels: {
                body: {
                    noMatch: this.state.loading ?
                        <Loader
                            type="Oval"
                            color="#00BFFF"
                            height={30}
                            width={30}
                        />
                    :
                        'Sorry, there is no matching data to display',
                },
            }
        }
        return (
            <>
                <SiteHeader />
                <div className="main container my-4 my-md-5">
                    <div className="page_wrapper mui-dataTables">
                        <Row className="justify-content">
                            <Col xs lg="12 mb-4"><Link to={`${process.env.PUBLIC_URL}/teams`} className={'back_btn'}><i className="fa fa-long-arrow-left"></i> Back</Link></Col>
                        </Row>
                        <MUIDataTable
                            title={this.state.teamName+" : Admins"}
                            data={this.state.rows}
                            columns={this.state.columns}
                            options={options}
                            />  
                    </div>
                </div>
            </>
        );
    }
}

export default ViewTeamAdmins;