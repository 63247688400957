import React, { Component } from 'react';
import {
  Col,
  Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import CSVReader from "react-csv-reader";
import SiteHeader from './SiteHeader';
import { userService } from './../services';
import { toast } from 'react-toastify';

class UploadUsersCSV extends Component {
  
  // This method is the least used lifecycle method 
  // and called before any HTML element is rendered
  componentDidMount() {
    document.title = "Upload CSV | SetstreamLive";
  }

  // Using for Upload CSV data
  handleForce = async (data) => {
    try {
      let arr = [];
      data.map(async (x) => {
        arr.push(x)
      });
      await userService.httpApi('POST', `admin/users/upload-csv`, JSON.stringify(arr));
      toast.success("CSV uploaded successfully");
      this.props.history.push(`${process.env.PUBLIC_URL}/users`);
    } catch (error) {
      toast.warn(error);
      this.props.history.push(`${process.env.PUBLIC_URL}/users`);
    }
  };

  // Returns a JSX
  // Because that component needs to display the HTML markup or we can say JSX syntax
  render() {
    const parseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: header =>
        header
          //.toLowerCase()
          .replace(/\W/g, '_')
    }
    return (
      <>
        <SiteHeader />
        <div className="container my-4 my-md-5">
          <Row className="justify-content-md-center">
            <Col xs="12" md="12" lg="10">
              <div className="page_wrapper">
                <h2 className="add_form_heading">Upload CSV File</h2>
                <CSVReader
                  cssClass="csv-reader-input"
                  label="Select CSV here"
                  onFileLoaded={this.handleForce}
                  parserOptions={parseOptions}
                  inputId="ObiWan"
                  inputStyle={{ color: 'red' }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

// Wrapper component will subscribe to Redux store updates
// If you don't want to subscribe to store updates, pass null or undefined in place of mapStateToProps
function mapStateToProps(state) {
  const { logedIn } = state.isLogged;
  const { currentUser } = state;
  return {
    logedIn,
    currentUser,
  };
}

// The return of connect() is a wrapper function that takes your component 
// and returns a wrapper component with the additional props it injects
export default connect(mapStateToProps)(UploadUsersCSV);
