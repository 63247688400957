import React, { Component } from 'react';
import {
    Col,
    Row,
    Form,
    Label,
    Input,
    Button,
} from 'reactstrap';
import axios from 'axios';
import apiUrl from './../constants';
import SiteHeader from './SiteHeader';
import StorageService from './../storage/StorageService';
import { Multiselect } from 'multiselect-react-dropdown';
import { Link } from 'react-router-dom';
import { userService } from './../services';
import { toast } from 'react-toastify';

class AddTeamForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                teamName: "",
                headerColor: ""
            },
            waterMark: true,
            file: null,
            noSignalFile: null,
            errors: {},
            members: [],
            projects: [],
            admins: [],
            selectedMembers: [],
            selectedProjects: [],
            selectedAdmins: [],
        }
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = 'Add Team | SetlinkLive';
        this.getMembers();
        this.getProjects();
        this.getAdmins();
    }

    // Get all Team's users
    getMembers = async () => {
        try {
            let arr = [];
            const data = await userService.httpApi('GET', `users`, null);
            data.map((x) =>
                arr.push({
                    id: x._id,
                    name: x.firstName + ' ' + x.lastName + ' (' + x.email + ')',
                })
            )
            this.setState({ members: arr });
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                StorageService.clearLogin();
            } else if (error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else {
                toast.warn(error);
            }
        }
    }

    // Get Projects list
    getProjects = async () => {
        try {
            let arr = [];
            const data = await userService.httpApi('GET', `projects`, null);
            data.map((x) =>
                arr.push({
                    id: x._id,
                    name: x.projectName,
                })
            )
            this.setState({ projects: arr });
        } catch (error) {
            toast.warn(error.message);
        }
    }

    // Get all Admins list
    getAdmins = async () => {
        try {
            let arr = [];
            const data = await userService.httpApi('GET', `admins`, null);
            data.map((x) =>
                arr.push({
                    id: x._id,
                    name: x.firstName + ' ' + x.lastName + ' (' + x.email + ')',
                })
            )
            this.setState({ admins: arr });
        } catch (error) {
            toast.warn(error.message);
        }
    }

    // Check input Validations
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (fields["teamName"].trim().length === 0) {
            formIsValid = false;
            errors["teamName"] = "Team name cannot be empty";
        }
        if (this.state.selectedAdmins.length === 0) {
            formIsValid = false;
            errors["admins"] = "Admins cannot be empty";
        }
        if (fields["headerColor"].trim().length !== 0) {
            const hexCode = /^#[0-9a-f]{3}([0-9a-f]{3})?$/i;
            if (!hexCode.test(fields["headerColor"].trim())) {
                formIsValid = false;
                errors["headerColor"] = "Invalid Color";
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    // Save Post data
    addData = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                const data = new FormData();
                data.append('teamName', this.state.fields.teamName);
                data.append('headerColor', this.state.fields.headerColor);
                data.append('members', JSON.stringify(this.state.selectedMembers));
                data.append('admins', JSON.stringify(this.state.selectedAdmins));
                data.append('projects', JSON.stringify(this.state.selectedProjects));
                data.append('file', this.state.file);
                data.append('noSignalFile', this.state.noSignalFile);
                data.append('waterMark', this.state.waterMark);

                const headers = {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${StorageService.getLogin().token}`
                }
                axios.post(`${apiUrl}admin/teams`, data, {
                    headers: headers
                })
                    .then(res => {
                        toast.success('Team added successfully');
                        this.props.history.push(`${process.env.PUBLIC_URL}/teams`);
                    })
                    .catch(function (error) {
                        toast.warn('Team name already used');
                    });
            }
        } catch (error) {
            toast.warn(error);
        }
    }

    // Bind data on event onChange
    handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    // Get selected user's details
    onSelectMembers(selectedList, selectedItem) {
        const list = selectedList.map(x => x.id);
        this.setState({ selectedMembers: list });
    }

    // Remove selected User
    onRemoveMembers(selectedList, removedItem) {
        const list = selectedList.map(x => x.id);
        this.setState({ selectedMembers: list });
    }

    // Get selected Project's details
    onSelectProjects(selectedList, selectedItem) {
        const list = selectedList.map(x => x.id);
        this.setState({ selectedProjects: list });
    }

    // Remove selected Project
    onRemoveProjects(selectedList, removedItem) {
        const list = selectedList.map(x => x.id);
        this.setState({ selectedProjects: list });
    }

    // Get selected Admin's details
    onSelectAdmins(selectedList, selectedItem) {
        const list = selectedList.map(x => x.id);
        this.setState({ selectedAdmins: list });
    }

    // Remove selected Admin
    onRemoveAdmins(selectedList, removedItem) {
        const list = selectedList.map(x => x.id);
        this.setState({ selectedAdmins: list });
    }

    // Bind data on event onChange
    onChangeHandler = event => {
        const extn = event.target.files[0].name.split('.').pop().toLowerCase();

        if (extn === 'png' || extn === 'jpg' || extn === 'jpeg' || extn === 'gif') {
            if (event.target.name === 'logo') {
                this.setState({
                    file: event.target.files[0],
                    loaded: 0,
                });
            } else {
                this.setState({
                    noSignalFile: event.target.files[0],
                    loaded: 0,
                });
            }
        } else {
            toast.warn("Invalid File Format.");
        }
    }

    onCheckHandler = (value) => {
        this.setState({
            waterMark: value
        });
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        return (
            <>
                <SiteHeader />
                <div className="container my-4 my-md-5">
                    <Row className="justify-content-md-center">
                        <Col xs="12" md="12" lg="10">
                            <div className="page_wrapper_team">
                                <Form onSubmit={this.addData.bind(this)} className="form-horizontal">
                                    <h2 className="add_form_heading">Add Team</h2>
                                    <Row>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Team Name</Label>
                                            <Input type="text" id="text-input" name="teamName" placeholder="Team Name" value={this.state.fields["teamName"]} onChange={this.handleChange.bind(this, "teamName")} />
                                            <div className={(this.state.errors['teamName'] !== void 0 && this.state.errors['teamName'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["teamName"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Header Color</Label>
                                            <div className="color_edit_outer">
                                                <Input type="text" id="headerColorAdd" name="headerColor" placeholder="Header Color" value={this.state.fields["headerColor"]} onChange={this.handleChange.bind(this, "headerColor")} />
                                                <Input type="text" className="selectedColor" name="color" style={{ backgroundColor: (this.state.fields["headerColor"] === '' ? '#ffffff' : this.state.fields["headerColor"]), pointerEvents: "none" }} readOnly />
                                                <div className={(this.state.errors['headerColor'] !== void 0 && this.state.errors['headerColor'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["headerColor"]}</div>
                                            </div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Members</Label>
                                            <Multiselect
                                                options={this.state.members}
                                                selectedValues={this.state.selectedValue}
                                                onSelect={this.onSelectMembers.bind(this)}
                                                onRemove={this.onRemoveMembers.bind(this)}
                                                displayValue="name"
                                            />
                                            {/* <div className={(this.state.errors['members'] !== void 0 && this.state.errors['members'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["members"]}</div> */}
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="date-input">Projects</Label>
                                            <Multiselect
                                                options={this.state.projects}
                                                selectedValues={this.state.selectedValue}
                                                onSelect={this.onSelectProjects.bind(this)}
                                                onRemove={this.onRemoveProjects.bind(this)}
                                                displayValue="name"
                                            />
                                            {/* <div className={(this.state.errors['projects'] !== void 0 && this.state.errors['projects'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["projects"]}</div> */}
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="date-input">Admins</Label>
                                            <Multiselect
                                                options={this.state.admins}
                                                selectedValues={this.state.selectedValue}
                                                onSelect={this.onSelectAdmins.bind(this)}
                                                onRemove={this.onRemoveAdmins.bind(this)}
                                                displayValue="name"
                                            />
                                            <div className={(this.state.errors['admins'] !== void 0 && this.state.errors['admins'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["admins"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Logo</Label>
                                            <Input type="file" className="form-control" id="file" name="file" onChange={this.onChangeHandler} />
                                            {/* <div className={(this.state.errors['logo'] !== void 0 && this.state.errors['logo'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["logo"]}</div> */}
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">No Signal Image</Label>
                                            <Input type="file" className="form-control" id="noSignal-input" name="noSignal" placeholder="No Signal Image" onChange={this.onChangeHandler} />
                                        </Col>
                                        <Col sm="6" className="FormGroup d-flex">
                                            <Label htmlFor="text-input">Water Mark</Label>
                                            <label class="custom__switch">
                                            <Input type="checkbox" className="form-control" id="water-mark" name="waterMark" checked={ this.state.waterMark } onChange={(e) => {
                                                this.onCheckHandler(e.target.checked)
                                            }} />
                                            <span class="slider round"></span>
                                            </label>
                                        </Col>
                                        <Col sm="6" className="FormGroup d-flex">
                                            <Label htmlFor="text-input">Water Mark</Label>
                                            <label class="custom__switch">
                                            <Input type="checkbox" className="form-control" id="water-mark" name="waterMark" checked={ this.state.waterMark } onChange={(e) => {
                                                this.onCheckHandler(e.target.checked)
                                            }} />
                                            <span class="slider round"></span>
                                            </label>
                                        </Col>
                                    </Row>
                                    <Button type="submit" color="primary" className={'mr-2'}><i className="fa fa-dot-circle-o"></i> Submit</Button>
                                    <Link className="btn btn-danger" to={`${process.env.PUBLIC_URL}/teams`} size="sm" color="danger"><i className="fa fa-close"></i> Cancel</Link>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div >
            </>
        );
    }
}

export default AddTeamForm;
