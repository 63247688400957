import React, { Component } from 'react';
import { Row, Button } from 'react-bootstrap';
import SiteHeader from './SiteHeader';
import { Link } from 'react-router-dom';
import { userService } from './../services';
import StorageService from './../storage/StorageService';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import apiUrl from './../constants';
import Swal from 'sweetalert2';
import MUIDataTable from "mui-datatables";
import Loader from 'react-loader-spinner'

class Team extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            rows: [],
            list: [],
            deleteArr: [],
            errors: {},
            options: {},
            userData: [],
            loading: true
        }
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = "Teams | SetlinkLive";
        const { currentUser } = this.props;
        if (currentUser.currentUser === 'superAdmin') {
            this.setState({
                columns: [
                    {
                        name: "sno",
                        label: "S No.",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "team",
                        label: "Team Name",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "headerColor",
                        label: "Header Color ",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "admins",
                        label: "Admins",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "members",
                        label: "Members",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "projects",
                        label: "Projects",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "loggedInUsers",
                        label: "Logged In Users",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "action",
                        label: "Actions",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                ],
            })
        } else if (currentUser.currentUser === 'admin') {
            this.setState({
                columns: [
                    {
                        name: "sno",
                        label: "S No.",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "team",
                        label: "Team Name",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "headerColor",
                        label: "Header Color ",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "admins",
                        label: "Admins",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "members",
                        label: "Members",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "projects",
                        label: "Projects",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "loggedInUsers",
                        label: "Logged In Users ",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "action",
                        label: "Actions",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                ],
            })

        } else if (currentUser.currentUser === 'broadcaster') {
            this.setState({
                columns: [
                    {
                        name: "sno",
                        label: "S No.",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "team",
                        label: "Team Name",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "headerColor",
                        label: "Header Color ",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "admins",
                        label: "Admins",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "members",
                        label: "Members",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "projects",
                        label: "Projects",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "loggedInUsers",
                        label: "Logged In Users ",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "action",
                        label: "Actions",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                ],
            })
        } else {
            this.setState({
                columns: [
                    {
                        name: "sno",
                        label: "S No.",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "team",
                        label: "Team Name",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "headerColor",
                        label: "Header Color ",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "admins",
                        label: "Admins",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "members",
                        label: "Members",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "projects",
                        label: "Projects",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                ],
            })
        }
        this.loader();
        this.getUser();
        this.getTeams();
    }

    /**
    * @loader - data loading process
    */
    loader = () => {
        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 4000);
    }

    /**
    * @getUser - Get User's details 
    */
    getUser = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${StorageService.getLogin().token}`
            },
        };
        return fetch(`${apiUrl}admin/users/${StorageService.getLogin().profile.userId}`, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    StorageService.clearLogin();
                    this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                    return;
                }
            })
            .then(data => {
                this.setState({ fields: data });
                const storageData = StorageService.getLogin();
                if (storageData !== null) {
                    if (storageData.profile.userType !== data.userType) {
                        storageData.profile.userType = data.userType;
                        StorageService.setLogin(storageData);
                    }
                    if (storageData.profile.status !== data.status) {
                        storageData.profile.status = data.status;
                        if (data.status === "inactive") {
                            StorageService.clearLogin();
                        } else {
                            StorageService.setLogin(storageData);
                        }
                    } else if (storageData.profile.status === data.status) {
                        if (storageData.profile.status === "inactive") {
                            StorageService.clearLogin();
                        }
                    }
                }
            });
    }

    // Remove selected Team
    deleteTeam(id) {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: 'You will not be able to recover this team!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it'
            }).then(async (result) => {
                if (result.value) {
                    const teamData = this.state.rows;
                    const index = teamData.findIndex(x => x._id === id);
                    if (index > -1) {
                        await userService.httpApi('DELETE', `admin/teams/${id}`, null);
                        teamData.splice(index, 1);
                        this.setState({ rows: teamData });
                        this.getTeams();
                        Swal.fire(
                            'Deleted!',
                            'Team has been deleted.',
                            'success'
                        )
                    }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                        'Cancelled',
                        'team is safe :)',
                        'error'
                    )
                }
            })
        } catch (error) {
            toast.warn(error.message);
        }
    }

    // Find data indexes for get ids for multi delete
    handleGetIndex = (id) => {
        const data = this.state.deleteArr;
        const arr = this.state.userData;

        
            if(arr[id]) {
                data.push(arr[id]._id);
            }
            this.setState({ deleteArr: data });
            
    };

    // Get Team's details
    getTeams = async () => {
        const { currentUser } = this.props;
        try {
            if (currentUser.currentUser === 'superAdmin') {
                const data = await userService.httpApi('GET', 'admin/teams', null);
                this.drawRows(data);
                this.setState({ userData: data });
            } else if (currentUser.currentUser === 'admin') {
                const data = await userService.httpApi('GET', `teams/admin/${currentUser.id}`, null);
                this.drawRows(data);
                this.setState({ userData: data });
            } else if (currentUser.currentUser === 'broadcaster') {
                const data = await userService.httpApi('GET', `teams/user/${currentUser.id}`, null);
                this.drawRows(data);
                this.setState({ userData: data });
            } else {
                const data = await userService.httpApi('GET', `teams/user/${currentUser.id}`, null);
                this.drawRows(data);
                this.setState({ userData: data });
            }
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                StorageService.clearLogin();
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
            } else if (error === "Loin session expired") {
                toast.warn(error);
                StorageService.clearLogin();
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
            } else {
                toast.warn(error);
            }
        }
    }

    // Create table rows
    drawRows(data) {
        let rows = [];
        const { currentUser } = this.props;
        if (currentUser.currentUser === 'superAdmin') {
            data.map((x, index) =>
            
                rows.push({
                    sno: index + 1,
                    _id: x._id,
                    team: x.teamName,
                    headerColor: (x.headerColor) === '' ? '-' : x.headerColor,
                    admins: <Link to={`${process.env.PUBLIC_URL}/view-team-admins/${x._id}`} className="btn-sm btn btn-twitter m-1">Admins</Link>,
                    members: <Link to={`${process.env.PUBLIC_URL}/view-team-members/${x._id}`} className="btn-sm btn btn-twitter m-1">Members</Link>,
                    projects: <Link to={`${process.env.PUBLIC_URL}/view-team-projects/${x._id}`} className="btn-sm btn btn-twitter m-1">Projects</Link>,
                    loggedInUsers: <Link to={`${process.env.PUBLIC_URL}/loggedIn-users/${x._id}`} className="btn-sm btn btn-twitter m-1">Logged In Users</Link>,
                    action: <><Link to={`${process.env.PUBLIC_URL}/edit-team/${x._id}`} className="btn-sm btn btn-success m-1"><i className="fa fa-edit"></i></Link><Button className="btn-sm btn-danger m-1" id={x._id} onClick={() => this.deleteTeam(x._id)}><i className="fa fa-trash"></i></Button></>
                })
            )
            this.setState({ rows: rows });
        } else if (currentUser.currentUser === 'admin') {
            data.map((x, index) =>
                rows.push({
                    sno: index + 1,
                    _id: x.teams.map(y => y._id),
                    team: x.teams.map(y => y.teamName),
                    headerColor: x.teams.map(y => y.headerColor),
                    admins: <Link to={`${process.env.PUBLIC_URL}/view-team-admins/${x.teams.map(y => y._id)}`} className="btn-sm btn btn-twitter m-1">Admins</Link>,
                    members: <Link to={`${process.env.PUBLIC_URL}/view-team-members/${x.teams.map(y => y._id)}`} className="btn-sm btn btn-twitter m-1">Members</Link>,
                    projects: <Link to={`${process.env.PUBLIC_URL}/view-team-projects/${x.teams.map(y => y._id)}`} className="btn-sm btn btn-twitter m-1">Projects</Link>,
                    loggedInUsers: <Link to={`${process.env.PUBLIC_URL}/loggedIn-users/${x.teams.map(y => y._id)}`} className="btn-sm btn btn-twitter m-1">Logged In Users</Link>,
                    action: <><Link to={`${process.env.PUBLIC_URL}/edit-team/${x.teams.map(y => y._id)}`} className="btn-sm btn btn-success m-1"><i className="fa fa-edit"></i></Link>{currentUser.currentUser === 'superAdmin' ? <Button className="btn-sm btn-danger m-1" id={x._id} onClick={() => this.deleteTeam(x._id)}><i className="fa fa-trash"></i></Button> : ''}</>
                })
            )
            this.setState({ rows: rows });
        } else if (currentUser.currentUser === 'broadcaster') {
            data.map((x, index) =>
                rows.push({
                    sno: index + 1,
                    _id: x.teams.map(y => y._id),
                    team: x.teams.map(y => y.teamName),
                    headerColor: x.teams.map(y => y.headerColor),
                    admins: <Link to={`${process.env.PUBLIC_URL}/view-team-admins/${x.teams.map(y => y._id)}`} className="btn-sm btn btn-twitter m-1">Admins</Link>,
                    members: <Link to={`${process.env.PUBLIC_URL}/view-team-members/${x.teams.map(y => y._id)}`} className="btn-sm btn btn-twitter m-1">Members</Link>,
                    projects: <Link to={`${process.env.PUBLIC_URL}/view-team-projects/${x.teams.map(y => y._id)}`} className="btn-sm btn btn-twitter m-1">Projects</Link>,
                    loggedInUsers: <Link to={`${process.env.PUBLIC_URL}/loggedIn-users/${x.teams.map(y => y._id)}`} className="btn-sm btn btn-twitter m-1">Logged In Users</Link>,
                    action: <><Link to={`${process.env.PUBLIC_URL}/edit-team/${x.teams.map(y => y._id)}`} className="btn-sm btn btn-success m-1"><i className="fa fa-edit"></i></Link>{currentUser.currentUser === 'superAdmin' ? <Button className="btn-sm btn-danger m-1" id={x._id} onClick={() => this.deleteTeam(x._id)}><i className="fa fa-trash"></i></Button> : ''}</>
                })
            )
            this.setState({ rows: rows });
        } else {
            data.map((x, index) =>
                rows.push({
                    sno: index + 1,
                    _id: x.teams.map(y => y._id),
                    team: x.teams.map(y => y.teamName),
                    headerColor: x.teams.map(y => y.headerColor),
                    admins: <Link to={`${process.env.PUBLIC_URL}/view-team-admins/${x.teams.map(y => y._id)}`} className="btn-sm btn btn-twitter m-1">Admins</Link>,
                    members: <Link to={`${process.env.PUBLIC_URL}/view-team-members/${x.teams.map(y => y._id)}`} className="btn-sm btn btn-twitter m-1">Members</Link>,
                    projects: <Link to={`${process.env.PUBLIC_URL}/view-team-projects/${x.teams.map(y => y._id)}`} className="btn-sm btn btn-twitter m-1">Projects</Link>,
                })
            )
        }
    }

    // Delete multiple data on single click
    handleClick = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover the checked teams!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.value) {
                const data = { id: this.state.deleteArr };
                await userService.httpApi('DELETE', 'multiple/teams/delete', JSON.stringify(data));
                this.getTeams();
                Swal.fire(
                    'Deleted!',
                    'Checked teams deleted successfully!',
                    'success'
                )
                toast.success('Checked teams deleted successfully!');
                this.getTeams();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Checked teams are safe :)',
                    'error'
                )
            }
        })
    };

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        const { currentUser } = this.props;
        const options = {
            rowsPerPage: 10,
            rowsPerPageOptions: [10,15,20],
            filter: true,
            filterType: 'dropdown',
            serverSide: false,
            print: false,
            download: false,
            viewColumns: false,
            textLabels: {
                body: {
                    noMatch: this.state.loading ?
                        <Loader
                            type="Oval"
                            color="#00BFFF"
                            height={30}
                            width={30}
                        />
                    :
                        'Sorry, there is no matching data to display',
                },
            },
            onRowsDelete: (rowsDeleted, newData) => {
                
                for(var i = 0; i < rowsDeleted.data.length; i++) {
                   
                    if (rowsDeleted.data[i].index > -1) {
                        this.handleGetIndex(rowsDeleted.data[i].index);
                    } 
                    
                }
                this.handleClick();
                return false;
            }
        }
        return (
            <>
                <SiteHeader />
                <div className="main container my-4 my-md-5 common-table">
                    <div className="page_wrapper">
                        <Row className="table_btn mb-4">
                            <div>
                               
                            </div>
                            {currentUser.currentUser === 'superAdmin'  ?
                                <div>
                                    <Link to={`${process.env.PUBLIC_URL}/add-team`} className={'btn btn-outline-secondary'}><i className="fa fa-plus"></i> Add Team</Link>
                                </div> : ''}
                        </Row>

                        <MUIDataTable
                        title={"All Teams"}
                        data={this.state.rows}
                        columns={this.state.columns}
                        options={options}
                        />  
                    </div>
                </div>
            </>
        );
    }
}

// Wrapper component will subscribe to Redux store updates
// If you don't want to subscribe to store updates, pass null or undefined in place of mapStateToProps
function mapStateToProps(state) {
    const { logedIn } = state.isLogged;
    const { currentUser } = state;
    return {
        logedIn,
        currentUser,
    };
}

// The return of connect() is a wrapper function that takes your component 
// and returns a wrapper component with the additional props it injects
export default connect(mapStateToProps)(Team);

