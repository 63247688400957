import React, { Component } from 'react';
import {
    Col,
    Row,
    Form,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import SiteHeader from './SiteHeader';
import StorageService from './../storage/StorageService';
import { Link } from 'react-router-dom';
import { userService } from './../services';
import { toast } from 'react-toastify';

class AddProjectForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                projectName: "",
                status: "",
                tokenId: "",
            },
            errors: {},
            tokens: [],
        }
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = 'Add Project | SetlinkLive';
        this.getTokens();
    }

    // Get Tokens list
    getTokens = async () => {
        const { currentUser } = this.props;
        try {
            if (currentUser.currentUser === 'superAdmin') {
                const data = await userService.httpApi('GET', `publish/token`, null);
                this.setState({ tokens: data });
            } else if (currentUser.currentUser === 'admin') {
                const data1 = await userService.httpApi('GET', `publish/token`, null);
                const data = data1.filter(x => x.createdBy === currentUser.id);
                this.setState({ tokens: data });
            }
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                StorageService.clearLogin();
            } else if (error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else {
                toast.warn(error);
            }
        }
    }

    // Check input Validations
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        if (this.state.fields.projectName.trim().length === 0) {
            formIsValid = false;
            errors["projectName"] = 'Project name is required';
        }
        if (this.state.fields.tokenId.trim().length === 0) {
            formIsValid = false;
            errors["tokenId"] = "Publish token cannot be empty";
        }
        if (this.state.fields.status.trim().length === 0) {
            formIsValid = false;
            errors["status"] = 'Status is required';
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    // Save Post data
    addData = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                const data = {
                    projectName: this.state.fields.projectName,
                    status: this.state.fields.status,
                    createdBy: StorageService.getLogin().profile.userId,
                    tokenId: this.state.fields.tokenId,
                };
                await userService.httpApi('POST', `admin/projects`, JSON.stringify(data));
                toast.success('Project added successfully');
                this.props.history.push(`${process.env.PUBLIC_URL}/projects`);
            }
        } catch (error) {
            toast.warn(error);
        }
    }

    // Bind data on event onChange
    handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        return (
            <>
                <SiteHeader />
                <div className="container my-4 my-md-5">
                    <Row className="justify-content-md-center">
                        <Col xs="12" md="12" lg="10">
                            <div className="page_wrapper">
                                <Form onSubmit={this.addData.bind(this)} className="form-horizontal">
                                    <h2 className="add_form_heading">Add Project</h2>
                                    <Row>
                                        <Col sm="6" className="FormGroup">
                                            <Label for="projectName">Project Name</Label>
                                            <Input type="text" name="projectName" id="projectName" placeholder="Project Name" value={this.state.fields["projectName"]} onChange={this.handleChange.bind(this, "projectName")} />
                                            <div className={(this.state.errors['projectName'] !== void 0 && this.state.errors['projectName'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors["projectName"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Publish Token</Label>
                                            <Input type="select" id="select" name="tokenId" placeholder="Publish Token" value={this.state.fields["tokenId"]} onChange={this.handleChange.bind(this, "tokenId")}>
                                                <option value="">Please Select</option>
                                                {this.state.tokens.map(x =>
                                                    <option key={x._id} value={x._id}>{x.label}</option>
                                                )}
                                            </Input>
                                            <div className={(this.state.errors['tokenId'] !== void 0 && this.state.errors['tokenId'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["tokenId"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label for="status">Status</Label>
                                            <Input type="select" id="select" name="status" placeholder="Status" value={this.state.fields["status"]} onChange={this.handleChange.bind(this, "status")}>
                                                <option value="">Please Select</option>
                                                <option value={true}>Active</option>
                                                <option value={false}>Inactive</option>
                                            </Input>
                                            <div className={(this.state.errors['status'] !== void 0 && this.state.errors['status'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors["status"]}</div>
                                        </Col>
                                    </Row>
                                    <Button type="submit" color="primary" className={'mr-2'}><i className="fa fa-dot-circle-o"></i> Submit</Button>
                                    <Link className="btn btn-danger" to={`${process.env.PUBLIC_URL}/projects`} size="sm" color="danger"><i className="fa fa-close"></i> Cancel</Link>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div >
            </>
        );
    }
}

// Wrapper component will subscribe to Redux store updates
// If you don't want to subscribe to store updates, pass null or undefined in place of mapStateToProps
function mapStateToProps(state) {
    const { logedIn } = state.isLogged;
    const { currentUser } = state;
    return {
        logedIn,
        currentUser,
    };
}

// The return of connect() is a wrapper function that takes your component 
// and returns a wrapper component with the additional props it injects
export default connect(mapStateToProps)(AddProjectForm);
