import React, { Component } from 'react';
import './App.css';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import Publisher from './components/Publisher';
import Viewer from './components/Viewer';
import Login from './components/Login';
import Profile from './components/Profile';
import Token from './components/Token';
import Team from './components/Team';
import TermsConditions from './components/TermsConditions';
import AccountSettings from './components/AccountSettings';
import Tfa from './components/Tfa';
import TfaTeamsList from './components/TfaTeamsList';
import Project from './components/Project';
import AddProject from './components/AddProject';
import ActivityLog from './components/ActivityLog';
import AddTeam from './components/AddTeam';
import ViewTeamAdmins from './components/ViewTeamAdmins';
import ViewTeamMembers from './components/ViewTeamMembers';
import ViewTeamProjects from './components/ViewTeamProjects';
import ViewProjectTokens from './components/ViewProjectToken';
import ProjectDetail from './components/ProjectDetail';
import EditUserForm from './components/EditUserForm';
import EditTokenForm from './components/EditTokenForm';
import EditProjectForm from './components/EditProjectForm';
import EditTeamForm from './components/EditTeamForm';
import AddUser from './components/AddUser';
import User from './components/User';
import UploadUsersCSV from './components/UploadUsersCSV';
import ForgotPassword from './components/ForgotPassword';
import Signup from './components/Signup';
import Sms from './components/Sms';
import LoggedInUsers from './components/LoggedInUsers';
import { PrivateRoute } from './private/PrivateRoute';
import { ProtectedRoute } from './protected/ProtectedRoute';
import history from './helper/history';
import store from './helper/store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import VerifyAuth from './components/VerifyAuth';
import EditTermsConditions from './components/EditTermsConditions';
import ActiveStreamUsers from './components/ActiveStreamUsers';

class App extends Component {

  // Returns a JSX
  // Because that component needs to display the HTML markup or we can say JSX syntax
  render() {
    return (
      <>
        <Provider store={store}>
          <Router history={history}>
          <ToastContainer />
            <>
              <Switch>
                <PrivateRoute path={`${process.env.PUBLIC_URL}/broadcast/:id/:stream`} component={Publisher} />
                <PrivateRoute path={`${process.env.PUBLIC_URL}/viewer/:teamId/:id/:stream`} component={Viewer} />
                <PrivateRoute path={`${process.env.PUBLIC_URL}/profile/:id`} component={Profile} />
                <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
                <Route path={`${process.env.PUBLIC_URL}/link/login/:pid`} component={Login} />
                <Route path={`${process.env.PUBLIC_URL}/verify-auth/:email`} component={VerifyAuth} />
                <Route path={`${process.env.PUBLIC_URL}/signup/:pid`} component={Signup} />
                <Route path={`${process.env.PUBLIC_URL}/forgot-password`} component={ForgotPassword} />
                <PrivateRoute path={`${process.env.PUBLIC_URL}/tokens`} component={Token} />
                <PrivateRoute path={`${process.env.PUBLIC_URL}/account-settings/:id`} component={AccountSettings} />
                <Route path={`${process.env.PUBLIC_URL}/tfa/:email`} component={Tfa} />
                <Route path={`${process.env.PUBLIC_URL}/sms/:email`} component={Sms} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/tfa-teams-list`} component={TfaTeamsList} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/loggedIn-users/:id`} component={LoggedInUsers} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/teams`} component={Team} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/logs`} component={ActivityLog} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/active-users`} component={ActiveStreamUsers} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/add-team`} component={AddTeam} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/view-team-admins/:id`} component={ViewTeamAdmins} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/view-team-members/:id`} component={ViewTeamMembers} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/view-team-projects/:id`} component={ViewTeamProjects} />
                <PrivateRoute path={`${process.env.PUBLIC_URL}/view-project-tokens/:teamId/:id`} component={ViewProjectTokens} />
                <PrivateRoute path={`${process.env.PUBLIC_URL}/view-tokens/:teamId/:id`} component={ViewProjectTokens} />
                <PrivateRoute path={`${process.env.PUBLIC_URL}/projects`} component={Project} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/project-details`} component={ProjectDetail} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/terms-conditions`} component={TermsConditions} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/edit-terms/:id`} component={EditTermsConditions} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/add-project`} component={AddProject} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/add-user`} component={AddUser} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/edit-user/:id`} component={EditUserForm} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/edit-token/:id`} component={EditTokenForm} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/edit-project/:id`} component={EditProjectForm} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/edit-team/:id`} component={EditTeamForm} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/users`} component={User} />
                <ProtectedRoute path={`${process.env.PUBLIC_URL}/upload-users-csv`} component={UploadUsersCSV} />
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              </Switch>
            </>
          </Router>

        </Provider>
      </>
    );
  }
}

export default App;
