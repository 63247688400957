import React, { Component } from 'react';
import { Row, Col, Button, Image } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, Input, Label, Form, FormGroup } from 'reactstrap';
import loading from './../assets/images/Loading_icon.gif';
import DateTimePicker from 'react-datetime-picker';
import SiteHeader from './SiteHeader';
import { Link } from 'react-router-dom';
import { userService } from './../services';
import { connect } from 'react-redux';
import apiUrl from './../constants';
import StorageService from './../storage/StorageService';
import { toast } from 'react-toastify';
import { MDBInput } from 'mdbreact';
import Swal from 'sweetalert2';
import MUIDataTable from "mui-datatables";
import Loader from 'react-loader-spinner'

class Token extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            columns: [],
            rows: [],
            list: [],
            deleteArr: [],
            modal: false,
            data: {},
            toggleStatus: true,
            expires: false,
            fields: {
                label: '',
                streamName: '1',
            },
            errors: {},
            date: new Date(),
            minDate: new Date(),
            options: {},
            userData: [],
            loading: true
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = "Tokens | SetlinkLive";
        this.setState({
            columns: [
                {
                    name: "sno",
                    label: "S No.",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
                {
                    name: "enable",
                    label: "Enable",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
                {
                    name: "label",
                    label: "Token Label",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "streams",
                    label: "Streams",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
                {
                    name: "addedOn",
                    label: "Added On",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "expiresOn",
                    label: "Expires On",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
                {
                    name: "broadcast",
                    label: "Broadcast",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
                {
                    name: "share",
                    label: "Share",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
                {
                    name: "action",
                    label: "Actions",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
            ],
        });
        this.loader();
        this.setState({ minDate: new Date(Date.now() + 24 * 60 * 60 * 1000) });
        this.getUser();
        this.getPublishTokens();
    }
    
    /**
    * @loader - data loading process
    */
    loader = () => {
        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 4000);
    }

    // Get User's details
    getUser = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${StorageService.getLogin().token}`
            },
        };
        return fetch(`${apiUrl}admin/users/${StorageService.getLogin().profile.userId}`, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    StorageService.clearLogin();
                    this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                    return;
                }
            })
            .then(data => {
                const storageData = StorageService.getLogin();
                if (storageData !== null) {
                    if (storageData.profile.userType !== data.userType) {
                        storageData.profile.userType = data.userType;
                        StorageService.setLogin(storageData);
                    }
                    if (storageData.profile.status !== data.status) {
                        storageData.profile.status = data.status;
                        if (data.status === "inactive") {
                            StorageService.clearLogin();
                        } else {
                            StorageService.setLogin(storageData);
                        }
                    } else if (storageData.profile.status === data.status) {
                        if (storageData.profile.status === "inactive") {
                            StorageService.clearLogin();
                        }
                    }
                }
            });
    }

    // Data Toggle handler
    toggleHandler = () => {
        this.setState({ toggleStatus: (this.state.toggleStatus) ? false : true });
    }

    // Slide Button's 
    handleChangeBtnSwitch = async (value, data) => {
        try {
            let tokenData = this.state.rows;
            const index = tokenData.findIndex(x => x.id === data._id);
            if (index > -1) {
                tokenData[index].enable = value;
                const id = tokenData[index].id;
                this.setState({ rows: tokenData });
                await userService.httpApi('PATCH', `publish/token/status/${id}`, JSON.stringify({ enable: value }));
                toast.success('Token status updated successfully');
                this.getPublishTokens()
            }
        } catch (error) {
            toast.warn(error.message);
        }
    }

    // Bind input data on event onChange
    handleChange = (field, e) => {
        let fields = this.state.fields;

        fields[field] = e.target.value;
        this.setState({ fields });
    }

    // Using for Checkboxes
    handleCheckbox(e) {
        let isChecked = e.target.checked;
        this.setState({ expires: isChecked });
    }

    /**
    *
    *@handleValidation - used for form validation
   */
    handleValidation = () => {
        console.log(this.state.fields)
        let errors = {};
        let formIsValid = true;
        if (this.state.fields.label.trim().length === 0) {
            formIsValid = false;
            errors["label"] = 'Label is required';
        }
        if (this.state.fields.label.trim().length > 30) {
            formIsValid = false;
            errors["label"] = "Please enter maximum 30 characters";
        }
        if (this.state.fields.streamName.trim().length === 0) {
            formIsValid = false;
            errors["streamName"] = 'Stream is required';
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    /**
    *
    *@handleSubmit - its call when you submit the form
   */
    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isLoading: true });
                const token = await userService.httpApi('POST', `publish/token`, JSON.stringify({
                    userId: StorageService.getLogin().profile.userId,
                    label: this.state.fields.label,
                    streams: this.state.fields.streamName,
                    expiresOn: (this.state.expires === true) ? this.state.date : '',
                }));
                if (token.status === 'success') {
                    this.setState({ isLoading: false });
                    toast.success('Token Added successfully');
                    this.setState({ fields: { label: '', streamName: '' } });
                    this.modelClose();
                    this.getPublishTokens();
                }
            }
        } catch (error) {
            this.setState({ isLoading: false })
            this.modelClose();
            toast.warn(error);
        }
    }

    // Used for Add Token
    addToken = () => {
        this.setState({
            fields: {
                label: '',
                streamName: '1',
            }
        });
        this.setState({ modal: true });
    }

    // Modal Close
    modelClose() {
        this.setState({
            fields: {
                label: '',
                streamName: '1',
            }
        });
        this.setState({ modal: false });
    }

    // Remove Selected Token
    deleteToken(id) {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: 'You will not be able to recover this token!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it'
            }).then(async (result) => {
                this.setState({ isLoading: true });
                if (result.value) {
                    const tokenData = this.state.rows;
                    const index = tokenData.findIndex(x => x.id === id);
                    if (index > -1) {
                        const token = await userService.httpApi('DELETE', `publish/token/${id}`, null);
                        if (token.status === 'success') {
                            tokenData.splice(index, 1);
                            this.setState({ rows: tokenData });
                            this.setState({ isLoading: false });
                            this.getPublishTokens();
                            Swal.fire(
                                'Deleted!',
                                'Token has been deleted.',
                                'success'
                            )
                        }
                    }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                        'Cancelled',
                        'token is safe :)',
                        'error'
                    )
                    this.setState({ isLoading: false });
                }
            })
        } catch (error) {
            toast.warn(error.message);
        }
    }

    // Find data indexes for get ids for multi delete
    handleGetIndex = (id) => {
        const data = this.state.deleteArr;
        const arr = this.state.userData;

        
            if(arr[id]) {
                data.push(arr[id]._id);
            }
            this.setState({ deleteArr: data });
            
    };

    // Using for get all Publish Tokens
    getPublishTokens = async () => {
        const { currentUser } = this.props;
        try {
            if (currentUser.currentUser === 'superAdmin') {
                const data = await userService.httpApi('GET', 'admin/publish/token', null);
                this.drawRows(data);
                this.setState({ userData: data });
            } else {
                const data = await userService.httpApi('GET', `tokens/admin/${currentUser.id}`, null);
                this.drawRows(data);
                this.setState({ userData: data });
            }
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else if (error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
            }
        }
    }

    // Set State for date
    onChange = (date) => {
        this.setState({ date: date });
    }

    // Create Table Rows
    drawRows(data) {
        let rows = [];
        data.map((x, index) =>
            rows.push({
                sno: index + 1,
                id: x._id,
                enable: (x.enable === true) ? <MDBInput label=" " onChange={() => this.handleChangeBtnSwitch(false, x)} type="checkbox" className="switch-checkbox" checked={true} id={`switch${x._id}`} disabled={x.isActive === false ? true : false} /> : <MDBInput label=" " type="checkbox" className="switch-checkbox" onChange={() => this.handleChangeBtnSwitch(true, x)} checked={false} id={`switch${x._id}`} disabled={x.isActive === false ? true : false} />,
                label: (x.isActive === false) ? <span className="strike">{x.label}</span> : x.label,
                streams: (x.streams.length > 1) ? <span className={x.isActive === false ? 'strike' : ''}>{(x.streams[0].streamName + ',...')}</span> : <span className={x.isActive === false ? 'strike' : ''}>{x.streams.map(y => y.streamName).join(',')}</span>,
                addedOn: (x.addedOn) ? new Date(x.addedOn).toLocaleDateString("en-US") : '-',
                expiresOn: (x.expiresOn === 0) ? '-' : new Date(x.expiresOn).toLocaleDateString("en-US"),
                broadcast: <> <Link to={`${process.env.PUBLIC_URL}/broadcast/${x._id}/${x.streams[0].streamName}`} className={(x.isActive === true && x.enable === true) ? "btn-sm btn btn-twitter m-1" : "disable-link"}>Broadcast</Link></>,
                share: <> <Link to={`${process.env.PUBLIC_URL}/viewer/${x._id}/${x._id}/${x.streams[0].streamName}`} target={'_blank'} className={(x.isActive === true && x.enable === true) ? "btn-sm btn btn-twitter m-1" : "disable-link"}>View Stream</Link></>,
                action: <> <Link to={`${process.env.PUBLIC_URL}/edit-token/${x._id}`} className="btn-sm btn btn-success m-1"><i className="fa fa-cog"></i></Link><Button className="btn-sm btn-danger m-1" id={x._id} onClick={() => this.deleteToken(x._id)}><i className="fa fa-trash"></i></Button></>
            })
        )
        this.setState({ rows: rows });
        this.setState({ isLoading: false });
    }

    // Delete multiple data on single click
    handleClick = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover the checked tokens!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.value) {
                const data = { id: this.state.deleteArr };
                await userService.httpApi('DELETE', 'multiple/tokens/delete', JSON.stringify(data));
                this.getPublishTokens();
                Swal.fire(
                    'Deleted!',
                    'Checked tokens deleted successfully!',
                    'success'
                )
                toast.success('Checked tokens deleted successfully!');
                this.getPublishTokens();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Checked tokens are safe :)',
                    'error'
                )
            }
        })
    };

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        const { currentUser } = this.props;
        const options = {
            rowsPerPage: 10,
            rowsPerPageOptions: [10,15,20],
            filter: true,
            filterType: 'dropdown',
            serverSide: false,
            print: false,
            download: false,
            viewColumns: false,
            textLabels: {
                body: {
                    noMatch: this.state.loading ?
                        <Loader
                            type="Oval"
                            color="#00BFFF"
                            height={30}
                            width={30}
                        />
                    :
                        'Sorry, there is no matching data to display',
                },
            },
            onRowsDelete: (rowsDeleted, newData) => {
                
                for(var i = 0; i < rowsDeleted.data.length; i++) {
                   
                    if (rowsDeleted.data[i].index > -1) {
                        this.handleGetIndex(rowsDeleted.data[i].index);
                    } 
                    
                }
                this.handleClick();
                return false;
            }
        }
        return (
            <>
                <SiteHeader />
                {this.state.isLoading === true ?
                    <Image className="loading-icon" src={loading}></Image> :
                    <div className="main my-4 my-md-5 common-table container">
                        <div className="page_wrapper">
                            <Row className="table_btn mb-4">
                                <div>
                                    
                                </div>
                                {(currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin') ?
                                    <div>
                                        <Button className="add-token btn btn-outline-secondary" onClick={() => this.addToken()}>Add Publish Token</Button>
                                    </div> : ''}
                            </Row>

                            <MUIDataTable
                                title={"All Tokens"}
                                data={this.state.rows}
                                columns={this.state.columns}
                                options={options}
                                />  
                            
                            <Modal className='modal-lg mr_10' isOpen={this.state.modal} backdrop={'static'}>
                                <Form onSubmit={this.handleSubmit}>
                                    <ModalHeader>Add Publish Token</ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col sm="6" className="FormGroup">
                                                <Label htmlFor="text-input">Token Label</Label>
                                                <Input type="text" name="label" id="label" placeholder="Token Label" value={this.state.fields["label"]} onChange={this.handleChange.bind(this, "label")} />
                                                <div className={(this.state.errors['label'] !== void 0 && this.state.errors['label'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors["label"]}</div>
                                            </Col>
                                            <Col sm="6" className="FormGroup">
                                                <Label htmlFor="text-input">Add Stream Name/s</Label>
                                                <Input type="number" name="streamName" id="streamName" value={this.state.fields["streamName"]} min="1" max="100" onChange={this.handleChange.bind(this, "streamName")} />
                                                <div className={(this.state.errors['streamName'] !== void 0 && this.state.errors['streamName'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors["streamName"]}</div>
                                            </Col>
                                            <Col sm="6" className="FormGroup">
                                                <div className="checkbox_align">
                                                    <Input type="checkbox" name="expires" id="expires" checked={this.state.expires} onChange={e => this.handleCheckbox(e)} />
                                                    <Label htmlFor="text-input">Expires on</Label>
                                                </div>
                                            </Col>
                                            {(this.state.expires === true) ?
                                                <Col sm="6" className="FormGroup datepicker_outer">
                                                    <Label htmlFor="text-input">Expiry Date</Label>
                                                    <DateTimePicker
                                                        onChange={this.onChange}
                                                        minDate={this.state.minDate}
                                                        value={this.state.date}
                                                    />
                                                </Col> : ''}
                                            <Col sm="12">
                                                <FormGroup className='submitbtn'>
                                                    <Button color="primary" type='submit'>Submit</Button>
                                                    <Button className="cancel ml-2" variant="danger" onClick={() => this.modelClose()}>Cancel</Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                </Form>
                            </Modal>
                        </div>
                    </div>}
            </>
        );
    }
}

// Wrapper component will subscribe to Redux store updates
// If you don't want to subscribe to store updates, pass null or undefined in place of mapStateToProps
function mapStateToProps(state) {
    const { logedIn } = state.isLogged;
    const { currentUser } = state;
    return {
        logedIn,
        currentUser,
    };
}

// The return of connect() is a wrapper function that takes your component 
// and returns a wrapper component with the additional props it injects
export default connect(mapStateToProps)(Token);
