import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import SiteHeader from './SiteHeader';
import { userService } from './../services';
import { toast } from 'react-toastify';
import StorageService from './../storage/StorageService';
import { Label, Input, FormGroup, Form, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Multiselect } from 'multiselect-react-dropdown';
import apiUrl from './../constants';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import csv from './../assets/csv/users.csv';
import MenuItem from '@material-ui/core/MenuItem';
import { Select } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import Loader from 'react-loader-spinner'

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            rows: [],
            list: [],
            modal: false,
            fields: {
                subject: '',
                message: '',
            },
            errors: {},
            members: [],
            membersCC: [],
            membersBCC: [],
            selectedMembers: [],
            selectedMembersCC: [],
            selectedMembersBCC: [],
            deleteArr: [],
            options: {},
            userData: [],
            loading: true
        }
        this.handleChangeModal = this.handleChangeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = "Users  | SetlinkLive";
        this.setState({
            columns: [
                {
                    name: "sno",
                    label: "S No.",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "name",
                    label: "Name",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "email",
                    label: "Email",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "phone",
                    label: "Phone",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "userType",
                    label: "User Type",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "status",
                    label: "Status",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
                {
                    name: "date",
                    label: "Date",
                    options: {
                        filter: true,
                        sort: false,
                    }
                },
                {
                    name: "action",
                    label: "Actions",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
            ],
        });
        this.loader();
        this.getUser();
        this.getUsers();
        this.getMembers();
    }

    /**
    * @loader - data loading process
    */
    loader = () => {
        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 4000);
    }

    // Get User's details
    getUser = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${StorageService.getLogin().token}`
            },
        };
        return fetch(`${apiUrl}admin/users/${StorageService.getLogin().profile.userId}`, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    StorageService.clearLogin();
                    this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                    return;
                }
            })
            .then(data => {
                const storageData = StorageService.getLogin();
                if (storageData !== null) {
                    if (storageData.profile.userType !== data.userType) {
                        storageData.profile.userType = data.userType;
                        StorageService.setLogin(storageData);
                    }
                    if (storageData.profile.status !== data.status) {
                        storageData.profile.status = data.status;
                        if (data.status === "inactive") {
                            StorageService.clearLogin();
                        } else {
                            StorageService.setLogin(storageData);
                        }
                    } else if (storageData.profile.status === data.status) {
                        if (storageData.profile.status === "inactive") {
                            StorageService.clearLogin();
                        }
                    }
                }
            });
    }

    // Get All Members list
    getMembers = async () => {
        try {
            let arr = [];
            const { currentUser } = this.props;
            if (currentUser.currentUser === 'superAdmin') {
                const data = await userService.httpApi('GET', `users`, null);
                data.map((x) =>
                    arr.push({
                        email: x.email,
                        name: x.firstName + ' ' + x.lastName + ' (' + x.email + ')',
                    })
                )
                this.setState({ members: arr, membersCC: arr, membersBCC: arr });
            } else if (currentUser.currentUser === 'admin') {
                const data = await userService.httpApi('GET', `admins/user/${currentUser.id}`, null);
                data.map((x) =>
                    arr.push({
                        email: x.email,
                        name: x.firstName + ' ' + x.lastName + ' (' + x.email + ')',
                    })
                )
                this.setState({ members: arr, membersCC: arr, membersBCC: arr });
            }
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                StorageService.clearLogin();
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
            } else {
                toast.warn(error);
            }
        }
    }

    // Remove Selected User
    deleteUser(id,name,lastName) {
        try {
            Swal.fire({
                title: 'Are you sure?',
                html: "You will not be able to recover <b>"+name+" "+lastName+"</b>",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it'
            }).then(async (result) => {
                if (result.value) {
                    const userData = this.state.rows;
                    const index = userData.findIndex(x => x._id === id);
                    if (index > -1) {
                        await userService.httpApi('DELETE', `admin/users/${id}`, null);
                        userData.splice(index, 1);
                        this.setState({ rows: userData });
                        this.getUsers();
                        Swal.fire(
                            'Deleted!',
                            'User has been deleted.',
                            'success'
                        )
                    }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                        'Cancelled',
                        'user is safe :)',
                        'error'
                    )
                }
            })
        } catch (error) {
            toast.warn(error.message);
        }
    }

    // Find data indexes for get ids for multi delete
    handleGetIndex = (id) => {
        const data = this.state.deleteArr;
        const arr = this.state.userData;
            if(arr[id]) {
                data.push(arr[id]._id);
            }
            this.setState({ deleteArr: data });
            
    };

    // Get Users list
    getUsers = async () => {
        try {
            const { currentUser } = this.props;
            if (currentUser.currentUser === 'superAdmin') {
                const data = await userService.httpApi('GET', 'admin/users', null);
                this.drawRows(data);
                this.setState({ userData: data });
            }
            else if (currentUser.currentUser === 'admin') {
                const data = await userService.httpApi('GET', `admins/user/${currentUser.id}`, null);
                this.drawRows(data);
                this.setState({ userData: data });
            }
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                StorageService.clearLogin();
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
            } else if (error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else {
                toast.warn(error);
            }
        }
    }

    // Create Table Rows
    drawRows(data) {
        const { currentUser } = this.props;
        const index = data.findIndex(x => x._id === currentUser.id);
        if (index > -1) {
            data.splice(index, 1);
        }
        const rows = [];
        data.map((x, index) =>
            rows.push({
                sno: index + 1,
                _id: x._id,
                name: x.firstName + ' ' + x.lastName,
                email: x.email,
                phone: x.phone ? x.phone : '-',
                userType: x.userType.charAt(0).toUpperCase() + x.userType.slice(1),
                status: <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={x.status}
                        onChange={(e) => this.handleChange(e, x)}>
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="inactive">Inactive</MenuItem>
                        <MenuItem value="pending">Pending</MenuItem>
                    </Select>,
                date: new Date(x.created).toLocaleDateString("en-US"),
                action: <><Link to={`${process.env.PUBLIC_URL}/edit-user/${x._id}`} className="btn-sm btn btn-success m-1"><i className="fa fa-edit"></i></Link><Button className="btn-sm btn-danger m-1" onClick={() => this.deleteUser(x._id,x.firstName,x.lastName)}><i className="fa fa-trash" id={x._id}></i></Button></>
            })
        );
        this.setState({ rows: rows });
    }

    // Bind input data on event onChange
    handleChange = async (e, data) => {
        if (e.target.value === 'active') {
            await userService.httpApi('PATCH', `admin/users/status/${data._id}`, JSON.stringify({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                password: data.password,
                phone: data.phone,
                userType: data.userType,
                status: e.target.value,
                fromEmail: StorageService.getLogin().profile.email,
            }));
            toast.success('User status updated successfully');
            this.getUsers();
        } else {
            await userService.httpApi('PATCH', `admin/users/${data._id}`, JSON.stringify({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                password: data.password,
                phone: data.phone,
                userType: data.userType,
                status: e.target.value,
            }));
            toast.success('User status updated successfully');
            this.getUsers();
        }
    }

    // Delete multiple data on single click
    handleClick = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover the checked users!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.value) {
                const data = { id: this.state.deleteArr };
                await userService.httpApi('DELETE', 'multiple/users/delete', JSON.stringify(data));
                this.getUsers();
                Swal.fire(
                    'Deleted!',
                    'Checked users deleted successfully!',
                    'success'
                )
                toast.success('Checked users deleted successfully!');
                this.getUsers();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Checked users are safe :)',
                    'error'
                )
            }
        })
    };

    // Send Emails
    sendUsersEmail = () => {
        this.setState({ modal: true });
    }

    // Modal Close
    modelClose = () => {
        this.setState({ fields: {} });
        this.setState({ modal: false });
    }

    // Bind Modal Data
    handleChangeModal = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    // Check input Validations
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        if (this.state.selectedMembers.length === 0) {
            formIsValid = false;
            errors["to"] = 'To Email is required.';
        }
        if (this.state.fields.subject.trim().length === 0) {
            formIsValid = false;
            errors["subject"] = 'Subject is required.';
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    // Get Select Member details
    onSelectMembers(selectedList, selectedItem) {
        const list = selectedList.map(x => x.email);
        this.setState({ selectedMembers: list });
    }

    // Remove Selected Members
    onRemoveMembers(selectedList, removedItem) {
        const list = selectedList.map(x => x.email);
        this.setState({ selectedMembers: list });
    }

    // Get CC field user
    onSelectMembersCC(selectedList, selectedItem) {
        const list = selectedList.map(x => x.email);
        this.setState({ selectedMembersCC: list });
    }

    // Remove CC field user
    onRemoveMembersCC(selectedList, removedItem) {
        const list = selectedList.map(x => x.email);
        this.setState({ selectedMembersCC: list });
    }

    // Get BCC field user
    onSelectMembersBCC(selectedList, selectedItem) {
        const list = selectedList.map(x => x.email);
        this.setState({ selectedMembersBCC: list });
    }

    // Remove BCC field user
    onRemoveMembersBCC(selectedList, removedItem) {
        const list = selectedList.map(x => x.email);
        this.setState({ selectedMembersBCC: list });
    }

    // Submit form data 
    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                const data = {
                    to: this.state.selectedMembers,
                    cc: this.state.selectedMembersCC,
                    bcc: this.state.selectedMembersBCC,
                    subject: this.state.fields.subject,
                    message: this.state.fields.message,
                }
                await userService.httpApi('POST', `send/email`, JSON.stringify(data));
                toast.success('Email sent successfully');
                this.setState({ modal: false });
                this.props.history.push(`${process.env.PUBLIC_URL}/users`);
            }
        } catch (error) {
            toast.warn(error);
        }
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        const { currentUser } = this.props;
        const options = {
            rowsPerPage: 10,
            rowsPerPageOptions: [10,15,20],
            filter: true,
            filterType: 'dropdown',
            serverSide: false,
            print: false,
            download: false,
            viewColumns: false,
            textLabels: {
                body: {
                    noMatch: this.state.loading ?
                        <Loader
                            type="Oval"
                            color="#00BFFF"
                            height={30}
                            width={30}
                        />
                    :
                        'Sorry, there is no matching data to display',
                },
            },
            onRowsDelete: (rowsDeleted, newData) => {
                
                for(var i = 0; i < rowsDeleted.data.length; i++) {
                   
                    if (rowsDeleted.data[i].index > -1) {
                        this.handleGetIndex(rowsDeleted.data[i].index);
                    } 
                    
                }
                this.handleClick();
                return false;
            }
        }
        return (
            <>
                <SiteHeader />
                <div className="main container my-4 my-md-5 common-table user_wrapper">
                    <div className="page_wrapper">
                        <Row className="table_btn mb-4">
                            <div>
                                  
                            </div>
                            <div>
                                {currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin' ? <Button className="add-token btn btn-outline-secondary" onClick={() => this.sendUsersEmail()}>Send Email</Button> : ''}
                                <Link to={`${process.env.PUBLIC_URL}/upload-users-csv`} className={'btn btn-outline-secondary'}><i className="fa fa-upload"></i> Upload CSV</Link>
                                <Link to={csv} target="_blank" className={'btn btn-outline-secondary'} download>Download CSV Format</Link>
                                <Link to={`${process.env.PUBLIC_URL}/add-user`} className={'btn btn-outline-secondary'}><i className="fa fa-plus"></i> Add User</Link>
                            </div>
                        </Row>
                        
                        <MUIDataTable
                        title={"All Users"}
                        data={this.state.rows}
                        columns={this.state.columns}
                        options={options}
                        />

                        <Modal className='modal-lg mr_10' isOpen={this.state.modal} backdrop={'static'}>
                            <Form onSubmit={this.handleSubmit}>
                                <ModalHeader>Send Email</ModalHeader>
                                <ModalBody>
                                    <Row>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">To</Label>
                                            {/* <Input type="text" name="to" value={this.state.fields["to"]} id="to" placeholder="To" onChange={this.handleChangeModal.bind(this, "to")}/> */}
                                            <Multiselect
                                                options={this.state.members}
                                                selectedValues={this.state.selectedValue}
                                                onSelect={this.onSelectMembers.bind(this)}
                                                onRemove={this.onRemoveMembers.bind(this)}
                                                displayValue="name"
                                            />
                                            <div className={(this.state.errors['to'] !== void 0 && this.state.errors['to'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors["to"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">CC</Label>
                                            {/* <Input type="text" name="to" value={this.state.fields["cc"]} id="cc" placeholder="CC" onChange={this.handleChangeModal.bind(this, "cc")}/> */}
                                            <Multiselect
                                                options={this.state.membersCC}
                                                selectedValues={this.state.selectedValue}
                                                onSelect={this.onSelectMembersCC.bind(this)}
                                                onRemove={this.onRemoveMembersCC.bind(this)}
                                                displayValue="name"
                                            />
                                            <div className={(this.state.errors['cc'] !== void 0 && this.state.errors['cc'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors["cc"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">BCC</Label>
                                            {/* <Input type="text" name="bcc" value={this.state.fields["bcc"]} id="bcc" placeholder="BCC" onChange={this.handleChangeModal.bind(this, "bcc")}/> */}
                                            <Multiselect
                                                options={this.state.membersBCC}
                                                selectedValues={this.state.selectedValue}
                                                onSelect={this.onSelectMembersBCC.bind(this)}
                                                onRemove={this.onRemoveMembersBCC.bind(this)}
                                                displayValue="name"
                                            />
                                            <div className={(this.state.errors['bcc'] !== void 0 && this.state.errors['bcc'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors["bcc"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Subject</Label>
                                            <Input type="text" name="to" value={this.state.fields["subject"]} id="subject" placeholder="Subject" onChange={this.handleChangeModal.bind(this, "subject")} />
                                            <div className={(this.state.errors['subject'] !== void 0 && this.state.errors['subject'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors["subject"]}</div>
                                        </Col>
                                        <Col sm="12" className="FormGroup">
                                            <Label htmlFor="text-input">Message</Label>
                                            <Input type="textarea" name="message" value={this.state.fields["message"]} id="message" className="form-message" rows="6" cols="70" placeholder="Message" onChange={this.handleChangeModal.bind(this, "message")} />
                                            <div className={(this.state.errors['message'] !== void 0 && this.state.errors['message'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors["message"]}</div>
                                        </Col>
                                        <Col sm="12">
                                            <FormGroup className='submitbtn'>
                                                <Button color="primary" type='submit'>Submit</Button>
                                                <Button className="cancel ml-2" variant="danger" onClick={() => this.modelClose()}>Cancel</Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </ModalBody>
                            </Form>
                        </Modal>
                    </div>
                </div>
            </>
        );
    }
}

// Wrapper component will subscribe to Redux store updates
// If you don't want to subscribe to store updates, pass null or undefined in place of mapStateToProps
function mapStateToProps(state) {
    const { logedIn } = state.isLogged;
    const { currentUser } = state;
    return {
        logedIn,
        currentUser,
    };
}

// The return of connect() is a wrapper function that takes your component 
// and returns a wrapper component with the additional props it injects
export default connect(mapStateToProps)(User);


