import React, { Component } from 'react';
import {
    Col,
    Row,
    Form,
    Label,
    Input,
    Button,
} from 'reactstrap';
import SiteHeader from './SiteHeader';
import StorageService from './../storage/StorageService';
import { userService } from './../services';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

class EditTermsConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                title: "",
                description: "",
            },
            errors: {},
        }
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = 'Edit Terms & Conditions | SetlinkLive';
        this.getTerms();
    }

    // Get Terms Policy
    getTerms = async () => {
        try {
            const data = await userService.httpApi('GET', `admin/terms/${this.props.match.params.id}`, null);
            this.setState({
                fields: {
                    title: data.title,
                    description: data.description,
                },
            });
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                StorageService.clearLogin();
            } else if (error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else {
                toast.warn(error);
            }
        }
    }

    // Check input Validations
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (fields["title"].trim().length === 0) {
            formIsValid = false;
            errors["title"] = "Title cannot be empty";
        }
        if (fields["description"].trim().length === 0) {
            formIsValid = false;
            errors["description"] = "Description cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    // Update Project's data
    updateData = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                await userService.httpApi('PATCH', `admin/terms/${this.props.match.params.id}`, JSON.stringify({
                    title: this.state.fields.title,
                    description: this.state.fields.description,
                }));
                toast.success('Terms & Conditions updated successfully');
                this.props.history.push(`${process.env.PUBLIC_URL}/terms-conditions`);
            }
        } catch (error) {
            toast.warn(error);
        }
    }

    // Bind input data on event onChange
    handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        return (
            <>
                <SiteHeader />
                <div className="container my-4 my-md-5">
                    <Row className="justify-content-md-center">
                        <Col xs="12" md="12" lg="10">
                            <div className="page_wrapper">
                                <Form onSubmit={this.updateData.bind(this)} className="form-horizontal">
                                    <h2 className="add_form_heading">Edit User</h2>
                                    <Row>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Title</Label>
                                            <Input type="text" id="text-input" name="title" placeholder="Title" value={this.state.fields["title"]} onChange={this.handleChange.bind(this, "title")} />
                                            <div className={(this.state.errors['title'] !== void 0 && this.state.errors['title'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["title"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Description</Label>
                                            <Input type="textarea" id="text-input" name="description" placeholder="Description" value={this.state.fields["description"]} onChange={this.handleChange.bind(this, "description")} />
                                            <div className={(this.state.errors['description'] !== void 0 && this.state.errors['description'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["description"]}</div>
                                        </Col>
                                    </Row>
                                    <Button type="submit" color="primary" className={'mr-2'}><i className="fa fa-dot-circle-o"></i> Submit</Button>
                                    <Link className="btn btn-danger" to={`${process.env.PUBLIC_URL}/terms`} size="sm" color="danger"><i className="fa fa-close"></i> Cancel</Link>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default EditTermsConditions;