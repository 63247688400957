import React, { Component } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SiteHeader from './SiteHeader';
import apiUrl from './../constants';
import { toast } from 'react-toastify';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            code: '',
            fields: {
                npassword: '',
                cpassword: '',
            },
            error: {},
            errors: {},
            form1: true,
            form2: false,
            form3: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = "Forgot Password | SetlinkLive";
    }

    // Bind input data on event onChange
    handleChange = (e) => {
        this.setState({ email: e.target.value })
    }

    // Bind input data on event onChange
    handleChangeCode = (e) => {
        this.setState({ code: e.target.value })
    }

    // Bind input data on event onChange
    handleChangePassword = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    // Check input Validations
    handleValidation = () => {
        // eslint-disable-next-line no-useless-escape
        let emailField = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        let error = {};
        let formIsValid = true;

        if (this.state.email.trim().length === 0) {
            formIsValid = false;
            error["email"] = "Cannot be empty";
        } else if (this.state.email.trim().length !== 0 && emailField.test(this.state.email.trim()) === false) {
            formIsValid = false;
            error["email"] = "Email id is invalid";
        }
        this.setState({ error: error });
        return formIsValid;
    };

    // Check input Validations
    handleCodeValidation = () => {
        let error = {};
        let formIsValid = true;

        if (this.state.code.trim().length === 0) {
            formIsValid = false;
            error["code"] = "Cannot be empty";
        }
        this.setState({ error: error });
        return formIsValid;
    };


    // Check input Validations
    handlePasswordValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (fields['npassword'].trim().length === 0) {
            formIsValid = false;
            errors["npassword"] = "Cannot be empty";
        }
        if (fields["npassword"].trim().length !== 0) {
            const upper = /(?=.*[A-Z])/;
            const digit = /(?=.*[0-9])/;
            const special = /(?=.*[!@#_$%^&*])/;
            if (fields["npassword"].trim().length < 8) {
                formIsValid = false;
                errors["npassword"] = "Please enter minimum 8 characters";
            }
            if (fields["npassword"].trim().length > 16) {
                formIsValid = false;
                errors["npassword"] = "Please enter maximum 16 characters";
            }
            if (!upper.test(fields["npassword"])) {
                formIsValid = false;
                errors["npassword"] = "Please enter one uppercase letter";
            }
            if (!digit.test(fields["npassword"])) {
                formIsValid = false;
                errors["npassword"] = "Please enter one digit";
            }
            if (!special.test(fields["npassword"])) {
                formIsValid = false;
                errors["npassword"] = "Please enter one special character";
            }
        }
        if (fields['cpassword'].trim().length === 0) {
            formIsValid = false;
            errors["cpassword"] = "Cannot be empty";
        }
        if (fields['cpassword'] !== fields['npassword']) {
            formIsValid = false;
            errors["cpassword"] = "Confirm password does not match new password";
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    // Submit form data
    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        'email': this.state.email,
                    })
                };
                return fetch(`${apiUrl}users/email`, requestOptions)
                    .then(response => {
                        if (response.status === 200) {
                            this.setState({ form1: false, form2: true, form3: false });
                        } else {
                            toast.warn("Email not found.");
                        }
                    }
                    )
            }
        } catch (error) {
            toast.warn(error);
        }
    }

    // Submit form data
    submitCode = async (e) => {
        e.preventDefault();
        try {
            if (this.handleCodeValidation()) {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                return fetch(`${apiUrl}users/code-verification/${this.state.email}/${this.state.code}`, requestOptions)
                    .then(response => {
                        if (response.status === 200) {
                            this.setState({ form1: false, form2: false, form3: true });
                        } else {
                            toast.warn("Invalid verification code.");
                        }
                    }
                    )
            }
        } catch (error) {
            toast.warn(error);
        }
    }

    // Password update
    updatePassword = async (e) => {
        e.preventDefault();
        try {
            if (this.handlePasswordValidation()) {
                const requestOptions = {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        'password': this.state.fields.npassword,
                    })
                };
                return fetch(`${apiUrl}users/password/${this.state.email}`, requestOptions)
                    .then(response => {
                        if (response.status === 200) {
                            toast.success('Password updated successfully');
                            this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                        } else {
                            return;
                        }
                    }
                    )
            }
        } catch (error) {
            toast.warn(error);
        }
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        return (
            <>
                <SiteHeader />
                <div className="container my-4 my-md-5">
                    <Row>
                        <Col className="col-md-6 mx-auto">
                            <div className="page_wrapper">
                                <Row className="justify-content">
                                    <Col xs lg="12 mb-4"><Link to={`${process.env.PUBLIC_URL}/login`} className={'back_btn'}><i className="fa fa-long-arrow-left"></i> Back</Link></Col>
                                </Row>
                                {this.state.form1 === true ?
                                    <h1 className="text-center login_heading">Forgot Password</h1> : this.state.form2 === true ?
                                        <h1 className="text-center login_heading">Verify your Account</h1> : this.state.form3 === true ?
                                            <h1 className="text-center login_heading">Update Password</h1> : ''}
                                
                                {this.state.form1 === true ?
                                    <Form onSubmit={this.handleSubmit}>
                                        <Form.Group controlId="formBasicEmail" >
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="text" placeholder="Enter email" value={this.state.email} onChange={(e) => this.handleChange(e)} />
                                            <div className={(this.state.error['email'] !== void 0 && this.state.error['email'].length) ? "invalid-feedback d-block" : ""}>{this.state.error["email"]}</div>
                                        </Form.Group>
                                        <Button variant="primary" type="submit" >Next </Button>
                                    </Form> : this.state.form2 === true ?
                                        <Form onSubmit={this.submitCode}>
                                            <Form.Group controlId="formBasicCode" >
                                                <Form.Label>We just sent a code to {this.state.email}</Form.Label>
                                                <Form.Control type="text" placeholder="Enter code" value={this.state.code} onChange={(e) => this.handleChangeCode(e)} />
                                                <div className={(this.state.error['code'] !== void 0 && this.state.error['code'].length) ? "invalid-feedback d-block" : ""}>{this.state.error["code"]}</div>
                                            </Form.Group>
                                            <Button variant="primary" type="submit" >Next </Button>
                                        </Form> : this.state.form3 === true ?
                                            <Form onSubmit={this.updatePassword}>
                                                <Form.Group controlId="formBasicNewPassword" >
                                                    <Form.Label>New Password</Form.Label>
                                                    <Form.Control type="password" placeholder="Enter New Password" value={this.state.fields.npassword} onChange={this.handleChangePassword.bind(this, "npassword")} />
                                                    <div className={(this.state.errors['npassword'] !== void 0 && this.state.errors['npassword'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["npassword"]}</div>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicConfirmPassword" >
                                                    <Form.Label>Confirm Password</Form.Label>
                                                    <Form.Control type="password" placeholder="Enter Confirm Password" value={this.state.fields.cpassword} onChange={this.handleChangePassword.bind(this, "cpassword")} />
                                                    <div className={(this.state.errors['cpassword'] !== void 0 && this.state.errors['cpassword'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["cpassword"]}</div>
                                                </Form.Group>
                                                <Button variant="primary" type="submit" >Submit </Button>
                                            </Form> : ''}
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default ForgotPassword;
