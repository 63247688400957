import React, { Component } from 'react';
import {
    Col,
    Row,
    Form,
    Label,
    Input,
    Button,
} from 'reactstrap';
import SiteHeader from './SiteHeader';
import apiUrl from './../constants';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                phone: "",
                userType: "viewer",
            },
            errors: {},
        }
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = 'Signup | SetlinkLive';
    }

    // Check input Validations
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (fields["firstName"].trim().length === 0) {
            formIsValid = false;
            errors["firstName"] = "Firstname cannot be empty";
        }
        if (fields["lastName"].trim().length === 0) {
            formIsValid = false;
            errors["lastName"] = "Lastname cannot be empty";
        }
        if (fields["email"].trim().length === 0) {
            formIsValid = false;
            errors["email"] = "Email cannot be empty";
        }
        if (fields["email"].trim().length !== 0) {
            const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"].trim())) {
                formIsValid = false;
                errors["email"] = "Please enter valid email address.";
            }
        }
        if (fields["password"].trim().length === 0) {
            formIsValid = false;
            errors["password"] = "Password cannot be empty";
        }
        if (fields["password"].trim().length !== 0) {
            const upper = /(?=.*[A-Z])/;
            const digit = /(?=.*[0-9])/;
            const special = /(?=.*[-!@#_$%^&*])/;
            if (fields["password"].trim().length < 8) {
                formIsValid = false;
                errors["password"] = "Please enter minimum 8 characters";
            }
            if (fields["password"].trim().length > 16) {
                formIsValid = false;
                errors["password"] = "Please enter maximum 16 characters";
            }
            if (!upper.test(fields["password"])) {
                formIsValid = false;
                errors["password"] = "Please enter one uppercase letter";
            }
            if (!digit.test(fields["password"])) {
                formIsValid = false;
                errors["password"] = "Please enter one digit";
            }
            if (!special.test(fields["password"])) {
                formIsValid = false;
                errors["password"] = "Please enter one special character";
            }
        }
        if (fields["phone"].trim().length === 0) {
            formIsValid = false;
            errors["phone"] = "Phone cannot be empty";
        }
        if (fields["phone"].trim().length !== 0) {
            const phonepattern = /[0-9]{10}/;
            if (fields["phone"].trim().length < 10) {
                formIsValid = false;
                errors["phone"] = "Please enter minimum 10 digits";
            }
            if (fields["phone"].trim().length > 15) {
                formIsValid = false;
                errors["phone"] = "Please enter maximum 15 digits";
            }
            if (!phonepattern.test(fields["phone"])) {
                formIsValid = false;
                errors["phone"] = "Please enter valid phone no.";
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    // Save Post data
    addData = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        'firstName': this.state.fields.firstName,
                        'lastName': this.state.fields.lastName,
                        'email': this.state.fields.email.trim(),
                        'password': this.state.fields.password,
                        'phone': this.state.fields.phone,
                        'userType': this.state.fields.userType,
                    })
                };
                return fetch(`${apiUrl}signup/${this.props.match.params.pid}`, requestOptions)
                    .then(response => response.json()).then((json) => {
                        if (json.flag === 'usedEmail') {
                            toast.warn(json.message);
                        } else if (json.flag === 'noTeamProject') {
                            toast.warn(json.message);
                        } else {
                            toast.success('User added successfully');
                            this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                        }
                    }
                )
            }
        } catch (error) {
            toast.warn(error);
        }
    }

    // Bind input data on event onChange
    handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    // Reset form data
    reset() {
        this.setState({
            fields: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                phone: "",
                userType: "",
            },
            errors: {},
        })
    }
   
    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        return (
            <>
                <SiteHeader />
                <div className="container my-4 my-md-5">
                    <Row className="justify-content-md-center">
                        <Col xs="12" md="12" lg="10">
                            <div className="page_wrapper">
                                <Form onSubmit={this.addData.bind(this)} className="form-horizontal">
                                    <h2 className="text-center login_heading">Signup</h2>
                                    <Row>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Firstname</Label>
                                            <Input type="text" id="text-input" name="firstName" placeholder="Firstname" value={this.state.fields["firstName"]} onChange={this.handleChange.bind(this, "firstName")} />
                                            <div className={(this.state.errors['firstName'] !== void 0 && this.state.errors['firstName'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["firstName"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Lastname</Label>
                                            <Input type="text" id="text-input" name="lastName" placeholder="Lastname" value={this.state.fields["lastName"]} onChange={this.handleChange.bind(this, "lastName")} />
                                            <div className={(this.state.errors['lastName'] !== void 0 && this.state.errors['lastName'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["lastName"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="date-input">Email</Label>
                                            <Input type="text" id="email" name="email" value={this.state.fields["email"]} onChange={this.handleChange.bind(this, "email")} placeholder="Enter email" />
                                            <div className={(this.state.errors['email'] !== void 0 && this.state.errors['email'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["email"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="date-input">Phone</Label>
                                            <Input type="text" id="phone" name="phone" value={this.state.fields["phone"]} onChange={this.handleChange.bind(this, "phone")} placeholder="Enter phone" />
                                            <div className={(this.state.errors['phone'] !== void 0 && this.state.errors['phone'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["phone"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup validation-error-text">
                                            <Label htmlFor="text-input">Password</Label>
                                            <Input type="password" id="password" name="password" value={this.state.fields["password"]} onChange={this.handleChange.bind(this, "password")} placeholder="Enter password" />
                                            <div className={(this.state.errors['password'] !== void 0 && this.state.errors['password'].length) ? "invalid-feedback d-block" : ""}>{this.state.errors["password"]}</div>
                                            <small className="text-muted">Password must contain at least <strong>1</strong> Uppercase characters (A-Z), <strong>1</strong> Digits (0-9) & <strong>1</strong> Special characters ( -!@#_$%^&)</small>
                                        </Col>
                                        <Col sm="6" className="FormGroup validation-error-text">
                                            <Label htmlFor="date-input">User Type</Label>
                                            <Input type="text" id="userType" name="userType" placeholder="User Type" value="Viewer" readOnly />
                                        </Col>
                                    </Row>
                                    
                                    <Button type="submit" size="sm" color="primary" className={'mr-2'}><i className="fa fa-dot-circle-o"></i> Submit</Button>
                                    <Button className="btn btn-danger btn-sm" onClick={() => this.reset()}> Cancel</Button>
                                    <span className="ml-2"><Link to={`${process.env.PUBLIC_URL}/link/login/${this.props.match.params.pid}`} >Sign-in </Link><small>if you already have an account</small></span>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div >
            </>
        );
    }
}

export default Signup;
