import React, { Component } from 'react';
import {
    Col,
    Row,
    Button,
} from 'reactstrap';
import SiteHeader from './SiteHeader';
import { userService } from './../services';
import { toast } from 'react-toastify';
import StorageService from './../storage/StorageService';
import apiUrl from './../constants';

class AccountSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            qrCode: false,
        }
        this.enableAuth = this.enableAuth.bind(this);
        this.disableAuth = this.disableAuth.bind(this);
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = "Account Settings | SetlinkLive";
        this.getUser();
    }
    // Get user details
    getUser = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${StorageService.getLogin().token}`
            },
        };
        return fetch(`${apiUrl}admin/users/${this.props.match.params.id}`, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    StorageService.clearLogin();
                    this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                    return;
                }
            })
            .then(data => {
                this.setState({ qrCode: data.qrCode });
                const storageData = StorageService.getLogin();
                if (storageData !== null) {
                    if (storageData.profile.userType !== data.userType) {
                        storageData.profile.userType = data.userType;
                    }
                    StorageService.setLogin(storageData);
                }
            });
    }

    // Authentication enabled 
    enableAuth = async () => {
        this.setState({ qrCode: true });
        await userService.httpApi('PATCH', `users/genAuth`, JSON.stringify({
            qrCode: true,
        }));
        this.props.history.push(`${process.env.PUBLIC_URL}/tfa`);
    }

    // Authentication disabled 
    disableAuth = async () => {
        this.setState({ qrCode: false });
        await userService.httpApi('PATCH', `disable/auth/${this.props.match.params.id}`, JSON.stringify({
            qrCode: false,
        }));
        toast.success("Two factor authentication disabled successfully.");
    }

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        return (
            <>
                <SiteHeader />
                <div className="container my-4 my-md-5">
                    <Row className="justify-content-md-center two-factor-auth">
                        {this.state.qrCode === true ?
                            <Col xs="12" md="12" lg="10">
                                <h3 className="authentication-title">Disable Two factor authentication</h3>
                                <p className="description">Two factor authentication adds an additional layer of security to your account by requiring more than just
                      a password to login.</p>
                                <Button className="btn-red" onClick={this.disableAuth}>Disable Two-Factor Authentication</Button>
                            </Col> :
                            <Col xs="12" md="12" lg="10">
                                <h3 className="authentication-title">Enable Two factor authentication</h3>
                                <p className="description">Two factor authentication adds an additional layer of security to your account by requiring more than just
                    a password to login.</p>
                                <Button className="btn-green" onClick={this.enableAuth}>Enable Two-Factor Authentication</Button>
                            </Col>}
                    </Row>
                </div>
            </>
        )
    }
}

export default AccountSettings;